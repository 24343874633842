<template>
  <div class="delivery">
    <PageTitle title="Доставка" icon="fas fa-truck" />
    <div>
      Общий вес: <strong>{{ total_weight.toFixed(2) }}</strong> кг.
    </div>
    <!-- <div>
      Тариф: <strong>{{ rate.dlv_price }}</strong> EUR / кг.
    </div>
    <div class="space_below">
      Стоимость доставки: <strong>{{ packing_price }}</strong> EUR
    </div> -->
    <div class="border_above">
      Стоимость доставки: <strong>{{ total_delivery }}</strong> EUR
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/generic/PageTitle";

export default {
  name: "Delivery",
  components: {
    PageTitle
  },
  props: ["packing_price", "rate", "total_weight", "total_delivery"],
  computed: {}
};
</script>

<style lang="sass" scoped>
  .delivery
    font-size: 18px

</style>
