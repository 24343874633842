<template>
  <tr>
    <td>{{ npp }}</td>
    <td>{{ supplier }}</td>
    <td>{{ totals.quan }}</td>
    <td>{{ totals.weight | toCommas }}</td>
    <td>{{ totals.ttl_raw | toCommas }}</td>
    <td>{{ totals.ttl_full | toCommas }}</td>
  </tr>
</template>

<script>

export default {
  name: "SupplierRowShort",
  components: {
  },
  props: ["npp", "supplier", "totals"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
.table-sm td.cell-cont
  padding: 0
</style>
