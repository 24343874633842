<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="60">ID #</th>
        <th>Клиент</th>
        <th width="80">Приход</th>
        <th width="80">Расход</th>
        <th width="80">Баланс</th>
        <th width="80">Ожидают</th>
        <th width="36"></th>
      </tr>
    </thead>
    <tbody>
      <DocumentRow
        v-for="row in balances"
        :key="row.id"
        :row="row"
        />
    </tbody>
  </table>
</template>

<script>
  import DocumentRow from "./DocumentRow";

  export default {
    name: "Documents",
    components: {
      DocumentRow
    },
    props: ["balances"],
    computed: {},
    methods: {}
  };
</script>
