<template>
  <b-col>
    <WidgetTitle :title="title" />
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="dlv_min">От:</label>
      </b-col>
      <b-col sm="2">
        <b-form-input id="dlv_min" type="number" min="0" step=".01" size="sm" v-model="dlv_min" />
        <!-- <small class="text-danger" v-if="shop_name !== '' && !unique">
          Не уникальное название магизина.
        </small> -->
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="dlv_max">До:</label>
      </b-col>
      <b-col sm="2">
        <b-form-input id="dlv_max" type="number" min="0" step=".01" size="sm" v-model="dlv_max" />
        <!-- <small class="text-danger" v-if="shop_name !== '' && !unique">
          Не уникальное название магизина.
        </small> -->
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="dlv_price">Цена:</label>
      </b-col>
      <b-col sm="2">
        <b-form-input id="dlv_price" type="number" min="0" step=".01" size="sm" v-model="dlv_price" />
        <!-- <small class="text-danger" v-if="shop_name !== '' && !unique">
          Не уникальное название магизина.
        </small> -->
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="dlv_note">Описание:</label>
      </b-col>
      <b-col sm="8">
        <b-textarea
          id="dlv_note"
          size="sm"
          rows="4"
          max-rows="6"
          v-model="dlv_note"
        ></b-textarea>
      </b-col>
    </b-row>

    <div class="toolbar right t-row border-top pt-2">
      <b-button
        variant="info"
        @click="newRate"
      >
        Новый тариф
      </b-button>
      <b-button
        :disabled="isDisabled"
        variant="primary"
        @click="createRate"
      >
        Сохранить
      </b-button>
    </div>
  </b-col>
</template>

<script>
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

export default {
  name: "RateForm",
  components: {
    WidgetTitle
  },
  props: ["active_rate", "resetActive", "checkUnique"],
  data: () => ({
    id: null,
    dlv_min: 0.0,
    dlv_max: 0.0,
    dlv_price: 0.0,
    dlv_note: "",
    unique: false
  }),
  computed: {
    isDisabled() {
      return (
        Number(this.dlv_min) >= Number(this.dlv_max) ||
        Number(this.dlv_max) === 0 || Number(this.dlv_price) === 0
      );
    },
    title() {
      return !this.id ? "Новый тариф" : "Редактирование тарифа";
    }
  },
  watch: {
    active_rate(newVal, oldVal) {
      if (!oldVal || newVal.id !== oldVal.id) {
        this.id = newVal.id;
        this.dlv_min = newVal.dlv_min || 0;
        this.dlv_max = newVal.dlv_max || 0;
        this.dlv_price = newVal.dlv_price || 0;
        this.dlv_note = newVal.dlv_note;
      }
    }
  },
  created() {},
  methods: {
    newRate() {
      this.resetActive();
      this.resetForm();
    },
    resetForm() {
      this.id = null;
      this.dlv_min = 0;
      this.dlv_max = 0;
      this.dlv_price = 0;
      this.dlv_note = "";
    },
    createRate() {
      this.$store
        .dispatch("delivery/createRate", {
          id: this.id,
          dlv_min: this.dlv_min,
          dlv_max: this.dlv_max,
          dlv_price: this.dlv_price,
          dlv_note: this.dlv_note
        })
        .then(status => {
          this.resetForm();
        });
    }
  }
};
</script>
