<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-row>
          <b-col>
            <PageTitle title="Корзина" icon="fas fa-shopping-cart icon_green" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <table class="table table-sm table-hover table-bordered table-striped data-grid space_below">
              <thead class="thead-dark">
                <tr>
                  <th width="24"></th>
                  <th width="24">нпп</th>
                  <th width="36">ID#</th>
                  <th></th>
                  <th>Название</th>
                  <th width="40">Вар.</th>
                  <th width="120">Артикул</th>
                  <th width="70">Вес, шт.</th>
                  <th width="90">Кол-во</th>
                  <th width="70">Вес</th>
                  <th width="70">Цена</th>
                  <th width="70">Всего</th>
                  <!-- <th width="70">Налог</th> -->
                  <!-- <th width="70">Сумма налога</th> -->
                  <!-- <th width="70">Всего с НДС</th> -->
                  <th width="36"></th>
                </tr>
              </thead>
              <tbody>
                <CartRow
                  v-for="(prod, idx) in products"
                  :prod="prod"
                  :idx="idx"
                  :key="prod.variant ? prod.variant.ean : prod.mnf_code || prod.id"
                  :npp="products.length - idx"
                  :changeQuan="changeQuan"
                  :delProduct="delProduct"
                />
              </tbody>
              <thead class="thead-light">
                <tr>
                  <th colspan="8"><strong>Всего</strong></th>
                  <th width="70">{{ total_quan }}</th>
                  <th width="70">{{ total_weight.toFixed(2) }}</th>
                  <th width="70"></th>
                  <th width="70">{{ total_sum.toFixed(2) }}</th>
                  <!-- <th width="70"></th> -->
                  <!-- <th width="70"></th> -->
                  <!-- <th width="70">{{ total_sum_with_vat.toFixed(2) }}</th> -->
                  <th></th>
                </tr>
              </thead>
            </table>
          </b-col>
        </b-row>
        <b-row v-if="grand_total">
          <b-col>
            <div class="text-center border_below">
              <h4>Итог: {{ grand_total }}</h4>
              <!-- <h4>С НДС: {{ grand_total_with_vat }}</h4> -->
              <h4>С доставкой: {{ grand_total_with_delivery }}</h4>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <Delivery
          :packing_price="packing_price"
          :rate="rate"
          :total_weight="total_weight"
          :total_delivery="total_delivery"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="!is_valid && is_valid !== null">
      <b-col md="6">
        <b-alert variant="danger" :show="!is_valid && is_valid !== null">
          <strong>Внимание!</strong><br>
          Часть товаров в корзине - недоступна для заказа.<br>
          Удалите или замените недоступные товары и отправьте заказ ещё раз.
        </b-alert>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="show_complete">
      <b-col md="6">
        <b-alert variant="success" :show="show_complete">
          <strong>Спасибо за Ваш заказ!</strong><br>
          Детали заказа и регистрационные данные были отправлены на Вашу электронную почту.<br>
          При создании следующего заказа - войдите в систему используя пароль из письма.
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="products.length > 0">
      <b-col :md="isLoggedIn ? 3 : 6">
        <div class="mt-3">
          <WidgetTitle title="Детали заказа" />
        </div>
        <Registration
          :isLoggedIn="isLoggedIn"
          :isDisabled="isDisabled"
          :sendOrder="sendOrder" />
      </b-col>
      <b-col md="3" v-if="isLoggedIn">
        <AddressList />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";

import CartRow from "./CartRow";
import Delivery from "./Delivery";
import PageTitle from "@/components/generic/PageTitle";
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

import Registration from "./Registration";

import AddressList from "@/components/user/addresses/AddressList";

export default {
  name: "Cart",
  components: {
    AddressList,
    CartRow,
    Delivery,
    PageTitle,
    WidgetTitle,
    Registration
  },
  data: () => ({
    order_disabled: false,
    show_complete: false,
    is_valid: null
  }),
  computed: {
    ...mapGetters({
      selected_address: "addresses/get_selected",
      ids: "cart/get_ids",
      products: "cart/get_cart",
      total_quan: "cart/get_total_quan",
      total_sum: "cart/get_total_sum",
      total_weight: "cart/get_total_weight",
      // total_sum_with_vat: "cart/get_total_sum_with_vat",

      packing_price: "settings/packing_price",
      rates: "delivery/get_rates",

      isLoggedIn: "user/isLoggedIn",
      user_id: "user/user_id"
    }),
    rate() {
      if (this.total_weight <= 0) return { dlv_price: 0 };
      let rate = this.rates.find(
        rate =>
          this.total_weight >= rate.dlv_min && this.total_weight < rate.dlv_max
      );
      if (rate) return rate;
      let max_price = 0;
      this.rates.map( rt => {
        if (rt.dlv_price > max_price) {
          max_price = rt.dlv_price;
          rate = rt
        };
      });
      return rate;
    },
    total_delivery() {
      return this.rate && this.total_weight > 0
        ? (
            this.rate.dlv_price * Math.ceil(this.total_weight) +
            this.packing_price
          ).toFixed(2)
        : 0;
    },
    // grand_total_with_vat() {
    //   return (this.total_sum_with_vat).toFixed(2);
    // },
    // grand_total_with_vat_and_delivery() {
    //   if (this.rate) return (Number(this.total_delivery) + Number(this.grand_total_with_vat)).toFixed(2);
    //   return this.grand_total_with_vat;
    // },
    grand_total_with_delivery() {
      if (this.rate)
        return (Number(this.total_delivery) + Number(this.grand_total)).toFixed(2);
      return this.grand_total;
    },
    grand_total() {
      return this.total_sum.toFixed(2);
    },
    isDisabled() {
      const isEmpty = this.products.length === 0;

      return isEmpty || (!this.isLoggedIn && this.email === "");
    }
  },
  watch: {
    isDisabled(newVal, oldVal) {
      if (newVal === true) {
        this.show_complete = false;
      }
    }
  },
  created() {
    this.show_complete = false;
    this.$store.dispatch("delivery/getRates", {});
  },
  methods: {
    saveCart: function() {
      this.$store.dispatch("cart/saveCart", { user_id: this.user_id });
    },
    delProduct: function(product_id) {
      this.$store
        .dispatch("cart/delProduct", { product_id })
        .then(() => this.saveCart());
    },
    changeQuan: function(product_id, quan) {
      this.$store.dispatch("cart/changeQuan", { product_id, quan })
        .then(() => this.saveCart());
    },
    sendOrder: function(isValid, regData) {
      this.$store.dispatch(
        "cart/validateCart",
        this.ids
      ).then( status => {
        this.is_valid = null;
        if (status !== "error") {
          this.order_disabled = true;
          if (this.isLoggedIn) {
            this.$store
              .dispatch("cart/sendOrder", {
                ...regData,
                address_id: this.selected_address.id,
                dlv_price: this.rate ? this.total_delivery : 0
              })
              .then(({status, order_id}) => {
                if (status === "success") {
                  router.push({ path: `/u/pay_order/${order_id}` });
                }
                this.order_disabled = false;
              });
          } else {
            if (isValid) {
              this.$store
                .dispatch("cart/sendOrder", {
                  ...regData,
                  dlv_price: this.rate ? this.total_delivery : 0
                })
                .then(({status, order_id}) => {
                  if (status === "success") {
                    this.show_complete = true;
                    // router.push({ path: `/u/login` });
                  };
                  this.order_disabled = false;
                });
            } //isValid
          }
        } else {
          this.is_valid = false;
        }
      });
    }
  }
};
</script>
