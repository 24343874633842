<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="60">ID #</th>
        <th width="80">
          Сум, EUR
        </th>
        <th width="60">Заказ</th>
        <th width="180">Клиент</th>
        <th >Описание</th>
        <th width="80">Проверен?</th>
        <th width="130">ДатаПр.</th>
        <th width="140">Создан</th>
        <th width="180">Автор</th>
        <th width="100"></th>
      </tr>
    </thead>
    <tbody>
      <DocumentRow
        v-for="doc in documents"
        :key="doc.id"
        :doc="doc"
        :delPayment="delPayment"
        :savePayment="savePayment"
        :showModal="showModal"
      />
    </tbody>
    <thead class="thead-dark">
      <tr>
        <th></th>
        <th width="80">{{ totals.sum_eur | toCommas }}</th>
        <th colspan="8"></th>
      </tr>
    </thead>
  </table>
</template>

<script>
import DocumentRow from "./DocumentRow";

export default {
  name: "Documents",
  components: {
    DocumentRow
  },
  props: ["documents", "totals", "savePayment", "delPayment", "showModal"],
  computed: {},
  methods: {}
};
</script>
