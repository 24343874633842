import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import createLogger from "vuex/dist/logger";

import addresses from "./modules/addresses";
import balances from "./modules/balances";
import app from "./modules/app";
import cart from "./modules/cart";
import content from "./modules/content";
import delivery from "./modules/delivery";
import groups from "./modules/groups";
import loader from "./modules/loader";
import asm_lists from "./modules/asm_lists";
import orders from "./modules/orders";
import paginator from "./modules/paginator";
import products from "./modules/products";
import products_links from "./modules/products_links";
import services from "./modules/services";
import finances from "./modules/finances";

import shops from "./modules/shops";

import suppliers_stats from "./modules/suppliers_stats";

import settings from "./modules/settings";
import search from "./modules/search";
import user from "./modules/user";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    addresses,
    app,
    balances,
    cart,
    content,
    delivery,
    groups,
    loader,
    asm_lists,
    orders,
    paginator,
    products,
    products_links,
    finances,

    suppliers_stats,

    shops,
    services,
    settings,
    search,
    user
  },
  strict: debug,
  plugins: debug
    ? [createLogger()] //, createPersistedState()
    : [] //createPersistedState()
});
