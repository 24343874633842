import Api from "@/services/general";

const state = {
  updates_log: {}
};

const getters = {
  log: state => mode => state.updates_log[mode] ? state.updates_log[mode] : [],
  logs: state => state.updates_log,
  no_ongoing_updates: state => {
    const rz = Object.keys(state.updates_log)
                .map( k => state.updates_log[k] ? state.updates_log[k].status : true)
                .filter( r => r === false);

    if (rz.length > 0) return false;
    return true;
  }
};

const actions = {
  async getUpdatesLog() {
    const res = await Api.getData("srv/updates_log");
    this.commit("services/setLogData", res.data);
  },
  async updateCatalog(ctx, payload) {
    const res = await Api.setData("srv/updater", null, payload);
    this.commit("services/setLogData", res.data);
  },
};

const mutations = {
  setLogData(state, payload) {
    state.updates_log = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
