<template>
  <li>
    <div class="group_item" :class="{sub_folder: sub}">
      <router-link class="group_row" :to="link" v-if="!isFolder">
        <div :class="{'selected': selected_group && grp.id === selected_group.id}">
          <span :class="{'sub': sub}">{{ grp.group_name }}</span>
        </div>
      </router-link>
      <div v-else :class="{'folder': true, 'root': true}" @click="toggle(grp.id)">
        {{ grp.group_name }}
        <i v-if="isOpen" class="fa fa-fw fa-chevron-down"></i>
        <i v-else class="fa fa-fw fa-chevron-right"></i>
      </div>
    </div>
    <ul :class="{ 'list-unstyled': true, 'opened': isOpen }" v-show="isOpen" v-if="isFolder">
      <GroupItem
        class="item"
        :parent="grp.id"
        v-for="child in grp.sub_groups"
        :key="child.id"
        :grp="child"
        sub=true
        :selected_group="selected_group"
        :toggleParent="toggleParentGroup"
      />
    </ul>
  </li>
</template>

<script>
import router from "@/router";

export default {
  name: "GroupItem",
  components: {},
  data: () => ({
    isOpen: false
  }),
  props: ["grp", "selected_group", "sub", "parent", "toggleParent"],
  computed: {
    isFolder: function () {
      return this.grp && this.grp.sub_groups && this.grp.sub_groups.length;
    },
    link() {
      return `/${this.grp.id}`;
    }
  },
  watch: {
    selected_group(newVal, oldVal) {
      if (this.grp.id === newVal.id && this.parent) {
        if (this.isFolder) {
          this.isOpen = true;
        }
        this.toggleParent(this.parent);
      }
    }
  },
  methods: {
    toggle: function (group_id) {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
        if(this.isOpen) router.push({ path: `/${group_id}` });
      }
    },
    toggleParentGroup: function() {
      if (!this.isOpen) this.isOpen = true;
      if (this.parent) this.toggleParent();
    }
  }
};
</script>

<style lang="sass">

</style>