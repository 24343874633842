<template>
  <tr>
    <td v-if="prod.is_valid === undefined"></td>
    <td v-if="prod.is_valid">
      <span class="text-success">
        <i class="far fa-check-circle"></i>
      </span>
    </td>
    <td v-if="!prod.is_valid && prod.is_valid !== undefined">
      <span class="text-danger">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
    </td>
    <td>{{ npp }}</td>
    <td>{{ prod.id }}</td>
    <td>
      <div class="image">
        <img v-if="thumbnail" :src="thumbnail" :alt="prod.prod_name" />
        <div v-else class="placeholder">нет картинки</div>
      </div>
    </td>
    <td>
      {{ prod.prod_name }}<br />
      <small>{{ prod.group.group_name }}</small>
    </td>
    <td>{{ prod.variant ? prod.variant.value : "-" }}</td>
    <td>
      {{ prod.mnf_code }}
    </td>
    <td>{{ prod.weight || "0.00" }}</td>
    <td>
      <b-form-spinbutton
        size="sm"
        v-model="quan"
        @change="changeValue"
        min="1"
        max="100"
      />
      <!-- <b-form-input
        type="number"
        :value="prod.quan"
        @change="changeValue"
      ></b-form-input> -->
    </td>
    <td>{{ row_weight }}</td>
    <td>{{ price }}</td>
    <!-- <td>{{ total }}</td> -->
    <!-- <td>{{ vat_rate }}</td> -->
    <!-- <td>{{ vat_sum }}</td> -->
    <!-- <td>{{ prod.price_with_vat }}</td> -->
    <td>{{ this.total }}</td>
    <td>
      <button
        class="btn btn-sm sm-override btn-danger"
        @click="delProduct(prod.id)"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import { get_actual_price } from "@/store/modules/cart";
import debounce from "lodash.debounce";

export default {
  name: "CartRow",
  props: ["npp", "prod", "delProduct", "changeQuan"],
  data: () => ({
    quan: 0
  }),
  computed: {
    price() {
      return get_actual_price(this.prod);
    },
    row_weight() {
      return Number(this.prod.quan) > 0 && Number(this.prod.weight) > 0
        ? (Number(this.prod.quan) * Number(this.prod.weight)).toFixed(2)
        : "0.0";
    },
    // vat_rate() {
    //   return this.prod.vat_rate ? `${this.prod.vat_rate}%` : "н/д";
    // },
    // vat_sum() {
    //   return (this.prod.price_with_vat - this.prod.price).toFixed(2);
    // },
    total() {
      return (this.prod.quan * this.price).toFixed(2);
    },
    thumbnail() {
      if (this.prod.images.length === 0) return;
      return this.prod.images[0].thumbURL;
    }
  },
  created() {
    this.quan = this.prod.quan;
  },
  methods: {
    changeValue(val) {
      this.change(val, this);
    },
    change: debounce((val, vm) => {
      vm.changeQuan(vm.prod.id, val);
    }, 350)
  }
};
</script>

<style lang="sass">
.image
  height: 60px
  display: flex
  justify-content: center
  align-items: center
  img
    max-width: 60px
    max-height: 60px

</style>
