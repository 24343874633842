<template>
  <div>
    <b-row>
      <b-col>
        <PageTitle :title="title" icon="fas fa-money icon_green" v-if="return_status"/>
        <div v-else class="text-center">
          <b-spinner variant="primary" :label="title"></b-spinner>
          <h3>{{ title }}</h3>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";

import PageTitle from "@/components/generic/PageTitle";
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

export default {
  name: "AdmOrderPayment",
  components: {
    PageTitle
  },
  data: () => ({
    order_id: null,
    return_status: null,
    mode: null
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn"
    }),
    title() {
      if (this.return_status === 'success')
        return `Спасибо, Ваш заказ #${this.order_id} был оплачен!`;
      if (this.return_status === 'canceled')
        return `Внимание! Вы отменили оплату заказа #${this.order_id}.`;
      return 'Ожидаем результата операции...';
    }
  },
  watch: {
  },
  created() {
    this.mode = this.$route.params.mode;
    this.return_status = this.$route.params.result;
    this.order_id = this.$route.params.id;

    if (this.$route.query.STATUS) {
      const payment_date = new Date(Number(this.$route.query.TIMESTAMP) * 1000);
      this.$store.dispatch("finances/markOrder", {
        order_id: this.$route.query.ORDER_NUMBER,
        status: this.$route.query.STATUS,
        payment_id: this.$route.query.PAYMENT_ID,
        date: payment_date.toISOString(),
        mode: this.$route.params.mode
      }).then(() => {
        router.push({ path: `/${this.$route.params.mode}/order/${this.order_id}` });
      });
    }
  },
  methods: {
  }
};
</script>
