import Api from "@/services/general";

// initial state
const state = {
  product: null,
  products: [],
  search_products: [],
  selected: null,

  loading: false,
  page_range: [1],
  current_page: null
};

const getters = {
  loading: state => state.loading,
  get_product: state => state.product,
  get_products: state => state.products,
  get_search_products: state => state.search_products,
  get_selected: state => state.selected,
  current_page: state => state.current_page,
  page_range: state => state.page_range,
};

const actions = {
  async getProducts(ctx, payload) {
    this.commit("products/setLoading", true);
    this.commit("products/setProducts", []);

    const res = await Api.getData("products", null, payload);
    this.commit("products/setProducts", res.data.list);
    this.commit("products/setPaginator", {
      ...res.data.paginator
    });
  },
  async getProduct(ctx, payload) {
    const res = await Api.getData("product", payload.item_id);
    if (res.data.rsp_status === "ok") this.commit("products/setProduct", res.data);
  },
  async searchProducts(ctx, payload) {
    const { loading, keyword } = payload;
    const res = await Api.getData("products/search", null, { keyword });
    this.commit("products/setSearchProducts", res.data);
    if (loading) loading(false);
  },
  resetSearch() {
    this.commit("products/setSearchProducts", []);
  }
};

const mutations = {
  setPaginator(state, payload) {
    state.page_range = payload.page_range;
    state.current_page = payload.current_page;
  },
  setPage(state, payload) {
    state.current_page = payload.page;
  },
  setLoading(state, flag) {
    state.loading = flag;
  },
  setProduct(state, data) {
    state.product = data;
  },
  setProducts(state, data) {
    state.products = data;
  },
  setSearchProducts(state, data) {
    state.search_products = data;
  },
  setSelected(state, prod_id) {
    state.selected = prod_id;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
