import Api from "@/services/general";

// initial state
const state = {
  balance: null,
  balances: [],
  selected_filter: 0,
  selected_aw_filter: 0,
  page_range: [1],
  current_page: null
};

const getters = {
  current_page: state => state.current_page,
  page_range: state => state.page_range,

  balance: state => state.balance,
  balances: state => state.balances,
  selected_filter: state => state.selected_filter,
  selected_aw_filter: state => state.selected_aw_filter
};

const actions = {
  async getBalances(ctx, payload) {
    const res = await Api.getData("balances", null, payload);
    this.commit("balances/setBalances", res.data.list);
    this.commit("balances/setPaginator", res.data.paginator);
    if (payload) {
      if (payload.mode === "d") {
        this.commit("balances/setFilter", payload.is_debtor);
        this.commit("balances/setAwFilter", 0);
      }
      if (payload.mode === "a") {
        this.commit("balances/setFilter", 0);
        this.commit("balances/setAwFilter", payload.is_awaiting);
      }
    }
  },
  async getBalance(ctx, payload) {
    const res = await Api.getData("balance", null, payload);
    this.commit("balances/setBalance", res.data);
  },
  async saveReturn(ctx, payload) {
    const res = await Api.setData("balance/return", null, payload);
    this.commit("balances/setBalance", res.data);
  }
};

const mutations = {
  setBalances(state, payload) {
    state.balances = payload;
  },
  setBalance(state, payload) {
    state.balance = payload;
  },
  setFilter(state, payload) {
    state.selected_filter = payload;
  },
  setAwFilter(state, payload) {
    state.selected_aw_filter = payload;
  },
  setPaginator(state, payload) {
    state.page_range = payload.page_range;
    state.current_page = payload.current_page;
  },
  setPage(state, payload) {
    state.current_page = payload.page;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
