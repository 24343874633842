<template>
  <div class="search_bar">
    <b-form-input
      size="sm"
      :value="keyword"
      placeholder="Поиск"
      @keyup.enter="searchThis"
      @keyup.esc="clearSearch"
      @input="changeSearch"
    />

    <b-button size="sm" variant="primary" @click="searchThis">Искать</b-button>
    <b-button size="sm" variant="light" @click="clearSearch">Все</b-button>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: ["keyword", "changeSearch", "clearSearch", "searchThis"]
};
</script>

<style lang="sass" module>
.search_bar
  display: flex
  margin-bottom: 10px
  input
    width: 60%

  input, button
    margin-right: 10px
</style>
