<template>
  <div class="filter_row space_below">
    <strong>{{ title }}</strong>
    <ul class="filter_pills">
      <li>
        <button
          class="btn btn-sm"
          :class="{ 'btn-primary': selected_filter === 0, 'btn-light': selected_filter !== 0 }"
          @click="setFilter(0)"
        >
          Все
        </button>
      </li>
      <li>
        <button
          class="btn btn-sm"
          :class="{ 'btn-primary': selected_filter === true, 'btn-light': selected_filter !== true }"
          @click="setFilter(true)"
        >
          Да
        </button>
      </li>
      <li>
        <button
          class="btn btn-sm"
          :class="{ 'btn-primary': selected_filter === false, 'btn-light': selected_filter !== false }"
          @click="setFilter(false)"
        >
          Нет
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TriFilter",
  props: ["title", "selected_filter", "setFilter"]
};
</script>
