<template>
  <div>
    <b-row>
      <b-col>
        <h2>{{ page.page_name }}</h2>
        <p v-html="page.page_text"></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Page",
  components: {},
  computed: {
    ...mapGetters({
      page: "content/page"
    })
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("content/getPage", {page_slug: this.$route.params.page_slug});
  },
  watch: {
    '$route'(to, from) {
      if(to.path.indexOf('/p/') !== -1) {
        this.$store.dispatch("content/getPage", {page_slug: this.$route.params.page_slug});
      }
    }
  },
  methods: {}
};
</script>

<style module lang="sass">
</style>
