<template>
  <div v-if="!is_disabled">
    <b-row>
      <b-col>
        <WidgetTitle
          title="Товары из других магазинов"
          icon="fas fa-cart-plus icon_blue"
          action_tag="toggle"
          :action="toggleForm"
          :is_visible="is_visible"
        />
      </b-col>
    </b-row>
    <b-row v-if="is_visible">
      <b-col>
        <Selector
          label="Магазин"
          :list="shops"
          mandatory="true"
          value_label="shop_name"
          :selected="shop_id"
          :select-entity="selectShop"
        />
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="prod_name">Название<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="prod_name" size="sm" v-model="prod_name" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="prod_link">Ссылка на товар<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-textarea
              id="prod_link"
              size="sm"
              rows="3"
              max-rows="3"
              v-model="prod_link"></b-textarea>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="mnf_code">Артикул:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input id="mnf_code" size="sm" v-model="mnf_code" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="ean">EAN<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input id="ean" size="sm" v-model="ean" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="pl_color">Цвет:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input id="pl_color" size="sm" v-model="pl_color" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="pl_size">Размер:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input id="pl_size" size="sm" v-model="pl_size" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="uploader">
              <div class="preview" v-if="show_preview">
                <img v-show="file_url" :src="file_url" class="img-preview" width="320" />
              </div>
              <image-uploader
                :debug="0"
                :maxWidth="320"
                :quality="0.7"
                :autoRotate="true"
                outputFormat="file"
                :preview="false"
                :className="['fileinput', { 'fileinput--loaded': has_image }]"
                :capture="false"
                accept="image/*"
                doNotResize="['gif', 'svg']"
                @input="setImage"
                @onUpload="onUpload"
                @onComplete="onComplete"
              >
                <label for="fileInput" slot="upload-label">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32">
                      <path
                        class="path1"
                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z">
                      </path>
                    </svg>
                  </figure>
                  <span class="upload-caption">{{
                    has_image ? "Заменить" : "Загрузить"
                  }}</span>
                </label>
              </image-uploader>
              <div v-if="has_image" class="image-buttons">
                <b-button variant="warning" size="sm" @click="delImage">
                  <i class="fas fa-trash-alt"></i> Удалить
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="weight">Вес(кг.):</label>
          </b-col>
          <b-col sm="4">
            <b-form-input
              type="number"
              step=".01"
              id="weight"
              size="sm"
              v-model="weight"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="price_with_vat">Цена+НДС<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input
              type="number"
              step=".01"
              id="price_with_vat"
              size="sm"
              v-model="price_with_vat"
            />
          </b-col>
        </b-row>
        <Selector
          label="% НДС"
          :list="vat_rates"
          flat=true
          :selected="vat_rate"
          :select-entity="selectVatRate"
        />
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="price">Цена:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input id="price" disabled size="sm" v-model="price" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="quan">Кол-во<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input type="number" id="quan" size="sm" v-model="quan" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="note">Комментарий:</label>
          </b-col>
          <b-col sm="8">
            <b-textarea
              id="note"
              size="sm"
              rows="3"
              max-rows="3"
              v-model="note"></b-textarea>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
          </b-col>
          <b-col sm="8">
              <b-button
                :disabled="isDisabled"
                variant="success"
                @click="addProduct">
                Добавить товар
              </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ProductsLinksList
          :products="products"
          :total_quan="total_quan"
          :total_weight="total_weight"
          :total_sum_with_vat="total_sum_with_vat"

          :delProduct="delProduct"
          :changeQuan="changeQuan"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import { mapGetters } from "vuex";

import Selector from "@/components/generic/backoffice/Selector";
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

import ProductsLinksList from "./ProductsLinksList";

import Api from "@/services/general";

export default {
  name: "ProductsLinks",
  components: {
    ProductsLinksList,
    Selector,
    WidgetTitle
  },
  data: () => ({
    is_disabled: false,
    prod_ref: uuid.v4(),
    show_preview: false,
    has_image: false,
    image_id: null,
    file_url: '',
    shop_id: 0,
    shop_name: "",
    prod_name: "",
    prod_link: "",
    note: "",
    pl_color: "",
    pl_size: "",
    mnf_code: "",
    ean: "",
    price: 0.0,
    price_with_vat: 0.0,
    vat_rates: [10, 14, 24],
    vat_rate: 24,
    vat: 0.0,
    weight: 0.0,
    quan: 0,
    is_visible: false
  }),
  computed: {
    ...mapGetters({
      products: "products_links/get_cart",
      total_quan: "products_links/get_total_quan",
      total_sum: "products_links/get_total_sum",
      total_weight: "products_links/get_total_weight",
      total_sum_with_vat: "products_links/get_total_sum_with_vat",
      shops: "shops/cart_shops",
      user_id: "user/user_id"
    }),
    isDisabled() {
      return (this.prod_name === "" &&
          this.prod_link === "" &&
          (isNaN(this.price_with_vat) || this.price_with_vat <= 0) &&
          (isNaN(this.quan) || this.quan <= 0)) ||
        (this.prod_name === "" ||
          this.prod_link === "" ||
          (isNaN(this.price_with_vat) || this.price_with_vat <= 0) ||
          (isNaN(this.quan) || this.quan <= 0) ||
          this.ean === ""
        )
    }
  },
  watch: {
    price_with_vat(newVal, oldVal) {
      const cl = isNaN(newVal) ? newVal.replace(",", ".") : newVal;
      if (!isNaN(cl) && Number(cl) > 0) {
        this.price = (cl - (cl / (100 + this.vat_rate) * this.vat_rate)).toFixed(2);
        this.vat = (cl / (100 + this.vat_rate) * this.vat_rate).toFixed(2);
      } else {
        this.price = 0.0;
      }
      this.price_with_vat = cl;
    }
  },
  created() {
    this.getShops();
  },
  methods: {
    saveCart: function() {
      this.$store.dispatch("products_links/saveLinkCart", {user_id: this.user_id})
    },
    // List methods
    changeQuan: function(product_id, quan) {
      this.$store.dispatch("products_links/changeQuan", { product_id, quan })
        .then(() => this.saveCart());
    },
    delProduct(id, image_id) {
      this.$store
        .dispatch("products_links/delProduct", { id })
        .then(() => this.saveCart());
      this.$store.dispatch("products_links/delImage", image_id);
    },
    // List methods end

    delImage: function() {
      if (this.image_id)
        this.$store
          .dispatch("products_links/delImage", this.image_id)
          .then(status => {
            if (status === "ok") {
              this.image_id = null;
              this.file_url = null;
              this.show_preview = false;
              this.has_image = false;
            }
        });
    },
    onUpload: function() {
      this.show_preview = false;
    },
    onComplete: function() {
      this.show_preview = true;
    },
    setImage: function(output) {
      this.has_image = true;
      this.show_preview = true;

      const formData = new FormData();
      formData.append('file_path', output, output.name);
      formData.append('prod_ref', this.prod_ref);

      Api.uploadFile(formData).then(x => {
        this.image_id = x.id;
        this.file_url = x.file_url;
      });
    },
    addProduct() {
      const shop = this.shop_id !== 0 && this.shop_name !== ""
          ? { shop_id: this.shop_id, shop_name: this.shop_name }
          : {};
      this.$store.dispatch("products_links/addProduct", {
        ...shop,
        prod_id: this.prod_ref,
        image_id: this.image_id,
        file_url: this.file_url,
        prod_name: this.prod_name,
        prod_link: this.prod_link,
        note: this.note,
        pl_color: this.pl_color,
        pl_size: this.pl_size,
        mnf_code: this.mnf_code,
        ean: this.ean,
        price: this.price,
        price_with_vat: this.price_with_vat,
        vat_rate: this.vat_rate,
        vat: this.vat,
        weight: String(this.weight).replace(",", "."),
        quan: this.quan,
        image: this.image
      }).then(() => this.saveCart());

      this.resetForm();
    },
    resetForm() {
      this.prod_ref = uuid.v4();
      this.show_preview = false;
      this.has_image = false;
      this.file_url = "";
      this.image_id = null;
      this.prod_name = "";
      this.prod_link = "";
      this.note = "";
      this.pl_color = "";
      this.pl_size = "";
      this.mnf_code = "";
      this.ean = "";
      this.price = 0.0;
      this.price_with_vat = 0.0;
      this.vat = 0.0;
      this.weight = 0.0;
      this.quan = 0;
    },
    selectShop(shop_id) {
      this.shop_id = shop_id;
      const shop = this.shops.find( s => s.id === Number(shop_id))
      this.shop_name = shop.shop_name;
    },
    selectVatRate(vat_rate) {
      this.vat_rate = vat_rate;
      if(!isNaN(this.price_with_vat) && Number(this.price_with_vat) > 0){
        this.price = (this.price_with_vat - (this.price_with_vat / (100 + Number(vat_rate)) * Number(vat_rate))).toFixed(2);
        this.vat = (this.price_with_vat / (100 + Number(vat_rate)) * Number(vat_rate)).toFixed(2);
      }
    },
    toggleForm() {
      this.is_visible = !this.is_visible;
    },
    getShops() {
      this.$store.dispatch("shops/getShops", {
        origin: "cart",
        mode: "u"
      }).then((shop) => {
        this.shop_id = shop.id;
        this.shop_name = shop.shop_name;
      });
    }
  }
}
</script>

<style lang="sass">
#fileInput
  display: none

.uploader
  width: 100%
  text-align: center
  margin-top: 8px
  margin-bottom: 16px
  padding: 8px
  border: 1px solid #e2e2e2
  border-radius: 4px
  label
    margin-top: 16px

.image-buttons
  padding-top: 8px
  border-top: 1px solid #e2e2e2
</style>