<template>
  <div>
    <b-row>
      <b-col>
        <PageTitle :title="title" icon="fas fa-money icon_green" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="disclaimer">
          <strong>Внимание!</strong> Для оплаты заказа вы будете перенаправлены на сайт платёжной системы.<br />
          После успешной оплаты Вы будете перенаправлены обратно, на наш сайт. Информацию <br />о платеже можно будет
          посмотреть в шапке заказа во время просмотра.
          <br />
          <br />
          Если у Вас возникли какие-либо затруднения или вопросы с оплатой - свяжитесь с нашим оператором.
        </div>
      </b-col>
    </b-row>
    <b-row v-if="pd && !payment_lock">
      <b-col v-if="pd.pt_data">
        <div class="disclaimer">
          <form action="https://payment.paytrail.com/e2" method="post">
            <input name="AUTHCODE" type="hidden" :value="pd.hash_string">
            <input name="MERCHANT_ID" type="hidden" :value="pd.pt_data.MERCHANT_ID">
            <input name="URL_SUCCESS" type="hidden" :value="pd.pt_data.URL_SUCCESS">
            <input name="URL_CANCEL" type="hidden" :value="pd.pt_data.URL_CANCEL">

            <input name="ORDER_NUMBER" type="hidden" :value="pd.pt_data.ORDER_NUMBER">
            <input name="PAYMENT_METHODS" type="hidden" :value="pd.pt_data.PAYMENT_METHODS">

            <input name="CURRENCY" type="hidden" :value="pd.pt_data.CURRENCY">
            <input name="REFERENCE_NUMBER" type="hidden" :value="pd.pt_data.REFERENCE_NUMBER">
            <input name="PAYER_PERSON_LASTNAME" type="hidden" :value="pd.pt_data.PAYER_PERSON_LASTNAME">
            <input name="PAYER_PERSON_FIRSTNAME" type="hidden" :value="pd.pt_data.PAYER_PERSON_FIRSTNAME">
            <input name="PAYER_PERSON_EMAIL" type="hidden" :value="pd.pt_data.PAYER_PERSON_EMAIL">
            <input name="PAYER_PERSON_ADDR_STREET" type="hidden" :value="pd.pt_data.PAYER_PERSON_ADDR_STREET">
            <input name="PAYER_PERSON_ADDR_POSTAL_CODE" type="hidden" :value="pd.pt_data.PAYER_PERSON_ADDR_POSTAL_CODE">
            <input name="PAYER_PERSON_ADDR_TOWN" type="hidden" :value="pd.pt_data.PAYER_PERSON_ADDR_TOWN">
            <!-- <input name="MSG_UI_MERCHANT_PANEL" type="hidden" :value="pd.pt_data.MSG_UI_MERCHANT_PANEL"> -->

            <input name="LOCALE" type="hidden" :value="pd.pt_data.LOCALE">
            <input name="PARAMS_IN" type="hidden" :value="pd.pt_data.PARAMS_IN">
            <input name="PARAMS_OUT" type="hidden" :value="pd.pt_data.PARAMS_OUT">
            <input name="AMOUNT" type="hidden" :value="pd.pt_data.AMOUNT">
            <input class="btn btn-primary btn-small" type="submit" value="Оплатить заказ">
          </form>
        </div>
      </b-col>
      <b-col v-else>
        <div class="disclaimer">
        </div>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="disclaimer">
          <b-alert variant="danger" show>
            <strong>Внимание!</strong> Оплата заказов временно отключена. Вы сможете оплатить заказ позже, используя свой кабинет.
          </b-alert>
        </div>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageTitle from "@/components/generic/PageTitle";
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

import AddressList from "@/components/user/addresses/AddressList";

export default {
  name: "OrderPaySplash",
  components: {
    PageTitle,
    // WidgetTitle
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      order: "orders/order",
      pd: "finances/payment_data",
      payment_lock: "settings/payment_lock",
      isLoggedIn: "user/isLoggedIn"
    }),
    title() {
      if(this.pd && this.pd.pt_data) {
        return `Оплата заказа #${this.pd.pt_data.ORDER_NUMBER}`;
      }
      return `Подготовка заказа #${this.$route.params.id} к оплате.`;
    }
  },
  watch: {
    // '$route'(to, from) {
    //   if(to.path.indexOf('/p/') !== -1) {
    //     this.$store.dispatch("content/getPage", {page_slug: this.$route.params.page_slug});
    //   }
    // }
  },
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("orders/getOrder", {
        id: this.$route.params.id,
        mode: "u"
      });
      this.$store.dispatch("finances/payOrder", {
        order_id: this.$route.params.id,
        return_url: `u/cart_payment/${this.$route.params.id}`
      });
    }
  },
  methods: {
    // payOrder() {
    //   this.$store
    //     .dispatch("finances/payOrder", {
    //       order_id: this.$route.params.id,
    //       return_url: `u/order/${this.$route.params.id}`
    //     })
    //     .then(status => {
    //       // if (status === "success") {}
    //       // this.order_disabled = false;
    //     });
    // }
  }
};
</script>

<style lang="sass" scoped>
.disclaimer
  padding: 10px
  margin-left: 10px
  margin-bottom: 20px
</style>