<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th>Поставщик</th>
        <th width="80">
          Заказов
        </th>
        <th width="80">
          Позиций
        </th>
        <th width="80">Сумма</th>
        <th width="100">Сум. + НДС</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="sup in suppliers" :key="sup.sup_id">
        <td>{{ sup.sup_name }}</td>
        <td>{{ sup.orders_count }}</td>
        <td>{{ sup.pos_count }}</td>
        <td>{{ sup.pos_sum }}</td>
        <td>{{ sup.pos_sum_with_vat }}</td>
      </tr>
    </tbody>
    <thead class="thead-dark">
      <tr>
        <th></th>
        <th width="80">{{ totals.orders | toCommas }}</th>
        <th width="80">{{ totals.quan | toCommas }}</th>
        <th width="80">{{ totals.raw | toCommas }}</th>
        <th width="80">{{ totals.full | toCommas }}</th>

      </tr>
    </thead>
  </table>
</template>

<script>

export default {
  name: "Suppliers",
  components: {
  },
  props: ["suppliers", "totals"],
  computed: {},
  methods: {}
};
</script>
