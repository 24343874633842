<template>
  <div>
    <b-carousel v-if="slides.length > 0 && is_desktop && !this.$route.params.cat_id"
      id="carousel"
      v-model="slide"
      :interval="4000"
      fade
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="320"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <a v-for="slide in slides"
        :key="slide.id"
        :href="slide.sld_link"
      >
        <b-carousel-slide
          :caption="slide.sld_name"
          :text-html="slide.sld_desc"
          :img-src="slide.sld_image.file_url"
      ></b-carousel-slide>
     </a>
    </b-carousel>
    <div v-if="loading">
      <b-row>
        <b-col>
        </b-col>
        <b-col>
          <div class="text-center">
            <b-spinner
              style="width: 3rem; height: 3rem;"
              variant="primary"
              label="Загрузка"/>
          </div>
        </b-col>
        <b-col>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <Paginator
            :page="current_page"
            :pages="page_range"
            :setPage="setPage"
            wide="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="divider"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <GroupTitle :selected_group="selected_group" v-if="!searchMode" />
          <h4 v-else>Результаты поиска: {{ keyword }}</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="mt-3">
            <div class="items">
              <Item
                v-for="prod in products"
                :prod="prod"
                :key="prod.id"
                :toCart="toCart"
                :openCard="openCard"
                :is_desktop="is_desktop"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="divider"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Paginator
            :page="current_page"
            :pages="page_range"
            :setPage="setPage"
            wide="false"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";

import Item from "./Item";
import GroupTitle from "@/components/groups/GroupTitle";
import Paginator from "@/components/generic/Paginator";

export default {
  name: "Items",
  components: {
    Item,
    GroupTitle,
    Paginator
  },
  computed: {
    ...mapGetters({
      keyword: "search/keyword",
      searchGlobal: "search/searchGlobal",
      searchMode: "search/searchMode",

      products: "products/get_products",
      selected_group: "groups/get_selected",
      is_desktop: "app/is_desktop",
      user_id: "user/user_id",

      page_range: "products/page_range",
      current_page: "products/current_page",

      slides: "content/slides",

      loading: "products/loading",
    })
  },
  data: () => ({
    slide: 0,
    sliding: null
  }),
  created() {},
  beforeRouteUpdate(to, from, next) { // Needed for correct Search work
    const { q, global } = to.query;
    if (q) {
      this.$store.commit("search/setKeyword", q);
      this.$store.commit("search/setSearchGlobal", global);
      this.$store.commit("search/setSearchMode", true);

      this.setSelected();
    }
    next();
  },
  methods: {
    openLink(link) {
      router.push({ path: link });
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    setSelected: function() {
      const kw = this.keyword ? { keyword: this.keyword } : {};
      const grp = {};

      this.$store.dispatch("products/getProducts", {
        ...grp,
        ...kw,
        global: this.searchGlobal
      }).then(() => this.$store.commit("products/setLoading", false));
    },
    saveCart: function() {
      this.$store.dispatch("cart/saveCart", {user_id: this.user_id})
    },
    toCart(product) {
      this.$store.dispatch("cart/addProduct", product)
        .then(() => this.saveCart());
    },
    openCard(id) {
      router.push({ path: `/i/${id}` });
    },
    setPage(page) {
      this.$store.commit("products/setPage", { page });

      const kw = this.keyword ? { keyword: this.keyword } : {};
      const grp = this.selected_group
        ? { group_id: this.selected_group.id }
        : {};

      this.$store.dispatch("products/getProducts", {
        ...grp,
        ...kw,
        page,
        global: this.searchGlobal
      }).then(() => this.$store.commit("products/setLoading", false));
    }
  }
};
</script>

<style lang="sass">
#carousel
  margin-bottom: 24px

.carousel-caption
  background-color: rgb(37,37,37,0.4)

.carousel-control-prev, .carousel-control-next
  color: #333

.fade
  position: relative
  height: 3.6em

.items
  display: flex
  flex-wrap: wrap
  .item
    // min-width: 23%
    max-width: 218px
    margin-right: 2%
    margin-bottom: 3%
    border: 1px solid #e2e2e2
    padding: 8px
    border-radius: 4px
    a
      text-decoration: none
      color: #333
      cursor: pointer

    .image
      height: 210px
      display: flex
      justify-content: center
      align-items: center
      img
        max-width: 210px
        max-height: 210px
      .placeholder
        width: 100%
        height: 100%
        background-color: #e2e2e2
        border: 1px solid #d5d5d5
        display: flex
        justify-content: center
        align-items: center
        max-width: 210px
    .title
      margin-top: auto
      width: 200px
      padding: 10px 0
      font-weight: 600
      max-height: 5.2em
      min-height: 5.2em
      text-transform: uppercase
      overflow: hidden
      text-overflow: ellipsis

    .desc
      min-height: 42px
      overflow: hidden
      text-overflow: ellipsis

@media (max-width: 688px)
  .items
    .item
      width: 100%
      max-width: 100%
      font0-size: 1.1rem
      .title
        margin-top: auto
        width: 100%
        max-width: 100%
</style>
