<template>
  <tr>
    <td>{{ row.id }}</td>
    <td>
      <router-link
        :to="doc_url"
      >
        {{ row.full_name }}
      </router-link>
    </td>
    <td>{{ row.pmt_summ | toCommas }}</td>
    <td>{{ row.exp_summ | toCommas }}</td>
    <td>{{ row.balance | toCommas }}</td>
    <td>{{ row.to_pay | toCommas }}</td>
    <td>
      <router-link
        class="btn btn-sm sm-override btn-info"
        :to="doc_url"
      >
        <i class="far fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: "DocumentRow",
  props: ["row"],
  computed: {
    doc_url() {
      return `/a/balance/${this.row.id}`;
    }
  },
  methods: {}
};
</script>