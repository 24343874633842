<template>
  <tr>
    <td>{{ npp }}</td>
    <td>
      <strong>{{ slide.id }}</strong>
    </td>
    <td>
      {{ slide.sld_name }}
    </td>
    <td>
      <span v-html="$options.filters.toYesNo(slide.is_active)"></span>
    </td>

    <td>
      <b-button
        size="sm"
        class="sm-override"
        variant="info"
        @click="setEdit(slide)"
        ><i class="fas fa-pen"></i
      ></b-button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "SlideRow",
  components: {},
  props: ["slide", "npp", "setEdit"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
</style>
