<template>
  <b-row v-if="!loading">
    <b-col id="printMe">
      <PageTitle
        :title="title"
        :back_url="getBackUrl"
        back="true"
        :addon="this.order.ext_doc_number"
        :actions="getActions()"
        :do_action="doAction"
        :title_message="title_message"
        :title_id="title_id"
        mode="a"
      />
      <b-row>
        <b-col md="3">
          <div class="doc_header">
            <ul>
              <li>
                <div class="header_label">Статус:</div>
                <b-badge :variant="order.status.st_color">{{
                  order.status.st_name
                }}</b-badge>
              </li>
              <li>
                <div class="header_label">Сборка:</div><router-link :to="asm_link">{{ order.asm_list_id }}</router-link>
              </li>
              <li>
                <div class="header_label">Клиент:</div>{{ order.user.profile.full_name }}
              </li>
              <li>
                <div class="header_label">Email:</div>{{ order.user.email }}
              </li>
              <li>
                <div class="header_label">Создан:</div>{{ order.created_at }}
              </li>
              <li>
                <div class="header_label">Erply:</div>{{ order.ext_doc_number }}
              </li>
            </ul>
            <div class="sums space_above space_below">
              <ul>
                <li>
                  <div class="header_label">Доставка:</div>
                  <strong>{{ order.dlv_price | toCommas }}</strong>
                </li>
                <li>
                  <div class="header_label">Сумма:</div>
                  <strong>{{ order.raw_sum | toCommas }}</strong>
                </li>
                <li>
                  <div class="header_label">Сумма + доставка:</div>
                  <strong>{{ delivery_raw | toCommas }}</strong>
                </li>
                <li class="border_dark_above">
                  <div class="header_label">Сумма(Ф):</div>
                  <strong>{{ order.raw_sum_fct | toCommas }}</strong>
                </li>
                <li>
                  <div class="header_label">К оплате(Ф):</div>
                  <strong>{{ delivery }}</strong> <small>(Факт + доставка)</small>
                </li>
              </ul>
            </div>

          </div>
        </b-col>
        <b-col md="4">
          <AddressCard
            v-if="order.address"
            :address="order.address"
            title="Доставка:"
          />
          <NoteCard
            title="Комментарий:"
            :note="order.note"
            :saveNote="saveNote" />

          <div class="space_below_more" v-if="order.has_changes && order.dlv_price !== new_total_delivery && new_total_delivery > 0">
            <p class="text-danger">
              <strong>Внимание!</strong> Заказ был изменён. <br>
              Необходимо обновить стоимость доставки.
            </p>
            <div class="toolbar t-row ">
              <div class="t-cell fb text-danger">
                Новая стоимость: <strong>{{ new_total_delivery }}</strong>
              </div>
              <div class="t-cell fb">
                <b-button size="sm" variant="outline-info" @click="updateDelivery">
                  <i class="fas fa-save"></i> Обновить
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="toolbar right t-row space_below">
            <div class="t-cell">
              <div class="card" v-if="order && order.is_paid">
                <div class="card-body">
                  Оплачено: {{ order.expense.exp_summ }} EUR
                  <p class="card-text">{{ order.expense.exp_note }}</p>
                </div>
              </div>
              <div class="card" v-if="order && !order.is_paid && order.payment_note">
                <div class="card-body">
                  <strong>Внимание!</strong> Оплата была отменена<br>
                  <p class="card-text">{{ order.payment_note }}</p>
                </div>
              </div>
            </div>
            <div class="t-cell" v-if="!order.is_paid">
              <b-button variant="outline-success" @click="showPaytrailModal">
                <i class="fas fa-money-check"></i> Paytrail
              </b-button>
            </div>
            <div
              class="t-cell"
              v-if="order && order.status.id > 1 && !order.is_paid">
              <b-button variant="outline-primary" @click="showPaymentModal">
                <i class="fas fa-money-check-alt"></i> Зарегистрировать оплату
              </b-button>
            </div>
            <div class="t-cell" v-if="order.asm_list_id">
              <b-button variant="warning" @click="removeAsm">
                <i class="fas fa-times"></i> Удалить из сборки
              </b-button>
            </div>
          </div>
          <div class="toolbar right t-row">
            <div class="t-cell" v-if="order.status.id === 2">
              <b-button variant="outline-info" @click="clientApprove">
                Согласование
              </b-button>
            </div>
            <div class="t-cell">
              <b-button variant="info" @click="printDoc">
                <i class="fas fa-print"></i> Печать
              </b-button>
            </div>
            <div class="t-cell">
              <b-button v-if="order && order.status.id===3"
                variant="warning"
                @click="unpack">
                  Отменить упаковку
              </b-button>
              <b-button v-if="order && order.status.id===1"
                variant="info"
                @click="setNextStatus">
                  Принять заказ
              </b-button>
              <b-button v-if="order && order.status.id===2"
                variant="primary"
                @click="showPackModal">
                  Упаковать
              </b-button>
              <b-button v-if="order && order.status.id===3"
                variant="success"
                @click="setNextStatus">
                  Отправить
              </b-button>

              <div v-if="order && order.status.id===3 && order.ext_doc_number===''">
                <b-form-group label="Тип заказа:">
                  <b-form-radio v-model="order_type" name="some-radios" value="ORDER">Заказ</b-form-radio>
                  <b-form-radio v-model="order_type" name="some-radios" value="OFFER">Ком. предл</b-form-radio>
                </b-form-group>
              </div>
              <b-button v-if="order && order.status.id===3 && order.ext_doc_number===''"
                variant="warning"
                @click="exportErply"
              >
                Экспорт в Erply
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="add_product_form space_below">
        <b-row>
          <b-col md="10">
            <div class="toolbar t-row" v-if="order.status.id < 3">
              <div class="t-cell fb">
                <strong>Добавление товара</strong>
              </div>
              <div class="t-cell w300">
                <ExtSearch
                  :selected="selected_option"
                  :setSelected="setSelected"
                  :options="options"
                />
              </div>
              <div class="t-cell w100">
                <b-form-input type="number" step="1" size="sm" v-model="quan" />
              </div>
              <div class="t-cell fb">
                <b-button size="sm" variant="primary" @click="addPosition">
                  <i class="fas fa-plus"></i> Добавить
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col >
            <div v-if="order.status.id === 2" class="toolbar right t-row">
              <div class="t-cell">
                <b-button size="sm" variant="outline-primary" @click="fillFact">
                  <i class="fas fa-fill"></i> Заполнить факт
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <table class="table table-sm table-hover table-bordered table-striped data-grid">
            <thead class="thead-dark">
              <tr>
                <th width="40">нпп</th>
                <th width="50">ID #</th>
                <th width="120"></th>
                <th colspan="2">Название</th>
                <th width="40">Вар.</th>
                <th width="160">Поставщик</th>
                <th width="160">Артикул</th>
                <th width="70">Вес, шт.</th>
                <th width="70">Кол-во</th>
                <th width="70">Вес</th>
                <th width="100">КолФ.</th>
                <th width="70">ВесФ.</th>
                <th width="70">Цена</th>
                <th width="80">Всего</th>
                <th width="80">ВсегоФ.</th>
                <th width="100">Цена c НДС</th>
                <th width="100">Всего с НДС</th>
                <th width="40"></th>
              </tr>
              <tr class="thead-light">
                <th colspan="9"></th>
                <th class="want">{{ order.ttl_quan }}</th>
                <th class="want">{{ order.ttl_wgt }}</th>
                <th class="fact">{{ order.ttl_quan_fct }}</th>
                <th class="fact">{{ order.ttl_wgt_fct }}</th>
                <th></th>
                <th class="want">{{ order.raw_sum | toCommas }}</th>
                <th class="fact">{{ order.raw_sum_fct | toCommas }}</th>
                <th></th>
                <th>{{ order.full_sum | toCommas }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ProductRow
                key_name="product"
                v-for="(prod, idx) in products"
                :key="prod.id"
                :idx="idx"
                :is_admin="true"
                :prod="prod"
                :npp="products.length - idx"
                :can_change_fact="can_change_fact"
                :can_delete="can_delete"
                :delPosition="delPosition"
                :updateQuanFct="updateQuanFct"
              />
              <tr>
                <td colspan="18">
                  <strong>Товары из других магазинов</strong>
                </td>
              </tr>
              <ProductRow
                key_name="product_link"
                v-for="(prod, idx) in products_links"
                :key="prod.id"
                :idx="idx"
                :is_admin="true"
                :prod="prod"
                :npp="products_links.length - idx"
                :can_delete="can_delete"
                :delPosition="delPosition"
              />
            </tbody>
            <thead class="thead-light">
              <tr>
                <th colspan="9"></th>
                <th class="want">{{ order.ttl_quan }}</th>
                <th class="want">{{ order.ttl_wgt }}</th>
                <th class="fact">{{ order.ttl_quan_fct }}</th>
                <th class="fact">{{ order.ttl_wgt_fct }}</th>
                <th></th>
                <th class="want">{{ order.raw_sum | toCommas }}</th>
                <th class="fact">{{ order.raw_sum_fct | toCommas }}</th>
                <th></th>
                <th>{{ order.full_sum | toCommas }}</th>
                <th></th>
              </tr>
            </thead>
          </table>
        </b-col>
      </b-row>
      <b-modal ref="payment-modal" centered no-fade title="Регистрация оплаты">
        <div>
          <div class="form-group row">
            <label for="exp_summ" class="col-sm-3 col-form-label">
              Сумма, EUR<span class="text-danger">*</span>:
            </label>
            <div class="col-sm-4">
              <input
                id="exp_summ"
                v-model="exp_summ"
                class="form-control form-control-sm"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="exp_note" class="col-sm-3 col-form-label">
              Комментарий<span class="text-danger">*</span>:
            </label>
            <div class="col-sm-9">
              <input
                id="exp_note"
                v-model="exp_note"
                class="form-control form-control-sm"
              />
            </div>
          </div>

        </div>
        <template #modal-footer>
          <b-button
            size="sm"
            variant="outline-danger"
            @click="closePaymentModal">
              Отмена
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            @click="registerPayment">
              Зарегистрировать
          </b-button>
        </template>
      </b-modal>

      <PaytrailModal
        ref="paytrail_modal"
        title="Оплата через Paytrail"
        mode="a"
        :raw_sum_fct="order.raw_sum_fct"
        :raw_sum="order.raw_sum"
        :delivery="delivery"
        :delivery_raw="delivery_raw"
        :closePaytrailModal="closePaytrailModal"
        :order_id="order.id"
      />

      <b-modal ref="pack-modal" centered no-fade :title="pack_modal_title">
        <div>
          <p v-if="!client_approve">
            Клиенту будет отправлено уведомление с фактическим кол-вом и пересчитаной стоимостью заказа.<br>
            Вы можете написать дополнительный комментарий в поле ниже.
          </p>
          <p v-else>
            Содержимое заказа будет отправлено клиенту для согласования замен.
          </p>
          <div class="form-group row">
            <label for="exp_note" class="col-sm-3 col-form-label">
              Комментарий:
            </label>
            <div class="col-sm-9">
              <input
                id="exp_note"
                v-model="status_note"
                class="form-control form-control-sm"
              />
            </div>
          </div>

        </div>
        <template #modal-footer>
          <b-button
            size="sm"
            variant="outline-danger"
            @click="closePackModal">
              Отмена
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            @click="sendNotification">
              {{ pack_modal_button_text }}
          </b-button>
        </template>
      </b-modal>


    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

// import Datepicker from "vuejs-datepicker";
// import { ru } from "vuejs-datepicker/dist/locale";
// import { ISO_Long_To_Short } from "@/services/date_tools";

import AddressCard from "@/components/user/addresses/AddressCard";
import NoteCard from "../NoteCard";
import ProductRow from "./ProductRow";
import ExtSearch from "@/components/generic/backoffice/ExtSearch";
import PageTitle from "@/components/generic/backoffice/PageTitle";

import PaytrailModal from "@/components/finances/PaytrailModal";

export default {
  name: "AdmOrder",
  components: {
    AddressCard,
    ExtSearch,
    NoteCard,
    PageTitle,
    ProductRow,
    PaytrailModal
  },
  data: () => ({
    exp_summ: 0,
    exp_note: "",
    loading: true,
    order_type: "ORDER",
    selected_option: null,
    quan: 1,
    new_rate: null,
    title_message: "",
    title_id: null,
    status_note: "",
    client_approve: false,
    loading: false
  }),
  computed: {
    ...mapGetters({
      order: "orders/order",
      packing_price: "settings/packing_price",
      rates: "delivery/get_rates"
    }),
    pack_modal_title() {
      return this.client_approve ? 'Согласование заказа' : 'Уведомление о готовности';
    },
    pack_modal_button_text() {
      return this.client_approve ? 'Отправить заказ на согласование' : 'Отправить уведомление и сменить статус';
    },
    delivery() {
      return (
        Number(this.order.dlv_price) + Number(this.order.raw_sum_fct)
      ).toFixed(2);
    },
    delivery_raw() {
      return (
        Number(this.order.dlv_price) + Number(this.order.raw_sum)
      ).toFixed(2);
    },
    new_total_delivery() {
      return this.new_rate && Number(this.order.ttl_wgt_fct) > 0
        ? (
            this.new_rate.dlv_price * Math.ceil(Number(this.order.ttl_wgt_fct)) +
            this.packing_price
          ).toFixed(2)
        : 0;
    },
    total() {
      return Number(this.order.full_sum) + Number(this.order.dlv_price);
    },
    getBackUrl() {
      const q = this.$route.query;
      const query = Object.keys(q)
        .reduce(function(a, k) {
          a.push(k + "=" + encodeURIComponent(q[k]));
          return a;
        }, [])
        .join("&");
      const url = `/a/orders?${query}`;
      return url;
    },
    options() {
      return this.$store.getters["products/get_search_products"];
    },
    asm_link() {
      return `/a/asm_list/${this.order.asm_list_id}`;
    },
    can_delete() {
      if ([1, 2].includes(this.order.status.id)) return true;
      return false;
    },
    can_change_fact() {
      if (this.order.status.id === 2) return true;
      return false;
    },
    title() {
      return `Заказ #${this.$route.params.id}`;
    },
    products() {
      return this.order.products ? this.order.products : [];
    },
    products_links() {
      return this.order.products_links ? this.order.products_links : [];
    }
  },
  watch: {
    '$route'(to, from) {
      if(to.path !== from.path) {
        this.title_message = "";
        this.title_id = null;
      }
      if (this.$route.params.id) {
        this.$store.dispatch("orders/getOrder", {
          id: this.$route.params.id,
          mode: "a"
        });
      }
    },
    exp_summ(newVal) {
      let exp_summ = newVal;
      if (
        (typeof newVal === "string" || newVal instanceof String) &&
        newVal.indexOf(",") >= 0
      ) {
        exp_summ = newVal.replace(",", ".");
      }
      this.exp_summ = exp_summ;
    },
    exp_note(newVal) {
      this.exp_note = newVal;
    }
  },
  created() {
    this.loading = true;
    if (this.$route.params.id) {
      this.$store.dispatch("orders/getOrder", {
        id: this.$route.params.id,
        mode: "a"
      }).then(() => {
        if (!this.order.is_paid) this.exp_summ = this.delivery;
        this.$store.dispatch("delivery/getRates", {}).then(() => {
          if (this.order && this.order.has_changes) this.getDeliveryRate();
        });
        this.loading = false;
      });
    }
  },
  mounted() {},
  methods: {
    saveNote(note) {
      this.$store.dispatch("orders/saveNote", {
        order_id: this.order.id,
        note
      });
    },
    unpack() {
      this.$store.dispatch("orders/unpackOrder", {
        order_id: this.order.id
      });
    },
    getActions() {
      let actions = [{
        id: "copy",
        button_title: "Скопировать заказ",
        button_color: "outline-primary",
        confirmation: false
      }];
      if (this.order.is_paid === false && this.order.status.id === 1) {
        actions.push({
          id: "delete",
          button_title: "Удалить заказ",
          button_color: "outline-danger",
          confirmation: true,
          mode: 'a'
        });
      }
      return actions;
    },
    doAction(id, mode) {
      this.$store.dispatch("orders/doAction", { action: id, order_id: this.order.id, mode })
      .then((data) => {
        if (id === "copy") {
          this.title_message = "Заказ скопирован";
          this.title_id = data.order_id;
        }
      });
    },
    getDeliveryRate() {
      const ttl_wgt_fct = Number(this.order.ttl_wgt_fct);
      if (ttl_wgt_fct <= 0) {
        this.new_rate = { dlv_price: 0 };
        return;
      }

      let rate = this.rates.find(
        rate =>
          ttl_wgt_fct >= rate.dlv_min &&
          ttl_wgt_fct < rate.dlv_max
      );
      if (rate) {
        this.new_rate = rate;
        return;
      }

      let max_price = 0;
      this.rates.map( rt => {
        if (rt.dlv_price > max_price) {
          max_price = rt.dlv_price;
          rate = rt;
        }
      });
      this.new_rate = rate;
    },
    saveExpense: function(payload) {
      this.$store.dispatch("finances/saveExpense", payload);
    },
    clientApprove() {
      this.client_approve = true;
      this.showPackModal();
    },
    showPackModal() {
      this.$refs["pack-modal"].show();
    },
    closePackModal() {
      this.client_approve = false;
      this.status_note = "";
      this.$refs["pack-modal"].hide();
    },
    sendNotification() {
      this.setNextStatus({
        status_note: this.status_note,
        client_approve: this.client_approve
      })
      this.closePackModal();
    },

    // Paytrail
    closePaytrailModal() {
      this.$refs.paytrail_modal.$refs.paytrail_modal.hide();
    },
    showPaytrailModal() {
      this.$refs.paytrail_modal.$refs.paytrail_modal.show();
    },

    registerPayment() {
      const payload = {
        order_id: this.order.id,
        exp_summ: this.exp_summ,
        exp_note: this.exp_note
      };
      this.saveExpense(payload);
      this.closePaymentModal();
    },

    closePaymentModal() {
      this.exp_summ = 0;
      this.exp_desc = "";
      this.$refs["payment-modal"].hide();
    },
    showPaymentModal() {
      this.$refs["payment-modal"].show();
    },
    fillFact() {
      this.$store.dispatch("orders/fillFact", {
        order_id: this.order.id
      });
    },
    updateDelivery() {
      this.$store.dispatch("orders/updateDelivery", {
        order_id: this.order.id,
        dlv_price: this.new_total_delivery
      });
    },
    removeAsm() {
      this.$store.dispatch("orders/removeAsm", {
        order_id: this.order.id
      });
    },
    addPosition() {
      this.$store
        .dispatch("orders/addPosition", {
          order_id: this.order.id,
          product: this.selected_option,
          quan: this.quan,
          origin: "product"
        })
        .then(status => {
          if (status === "success") {
            this.quan = 1;
            this.selected_option = null;
          }
        });
    },
    setSelected(obj) {
      this.selected_option = obj;
    },
    updateQuanFct(id, quan_fct) {
      this.$store.dispatch("orders/updateProd", {
        id: id,
        quan_fct
      });
    },
    printDoc() {
      this.$htmlToPaper("printMe");
    },
    delPosition(id, origin) {
      this.$store.dispatch("orders/delPosition", {
        id: id,
        order_id: this.order.id,
        origin,
        action: "del"
      });
    },
    setNextStatus(payload={}) {
      this.$store.dispatch("orders/setNextStatus", { id: this.order.id, ...payload });
    },
    exportErply() {
      this.$store.dispatch("orders/exportErply", {
        id: this.order.id,
        order_type: this.order_type
      });
    }
  }
};

</script>

<style lang="sass" scoped>
.add_product_form
  padding: 4px
.sums
  padding: 4px 8px
  background-color: #e2e2e2
  border-radius: 4px
  border: 1px solid #d5d5d5
</style>