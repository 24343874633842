<template>
  <div class="full_item" v-if="prod">
    <b-row>
      <b-col md="4">
        <div class="full_image">
          <img v-if="thumbnail" :src="thumbnail" :alt="prod.prod_name" />
          <div v-else class="placeholder">нет картинки</div>
        </div>
      </b-col>
      <b-col>
        <div class="item_title">
          {{ prod.prod_name }}
        </div>
        <div v-if="group">
          Группа: <router-link :to="group_link">{{ group.group_name }}</router-link>
        </div>
        <div class="desc mt-3" v-if="prod.description">
          <p v-html="prod.description"></p>
        </div>
        <div class="desc mt-3" v-if="!prod.description">
          <p v-html="prod.short_description"></p>
        </div>
        <div>Арт.: {{ prod.mnf_code }}</div>
        <div class="variants" v-if="prod.variants">
          <div class="label">Размеры:</div> <div>
            <select v-model="selected">
              <option
                v-for="v in prod.variants"
                :key="v.var_ext_id"
                :value="v"
                :selected="selected && selected.var_ext_id === v.var_ext_id"
              >
                {{ v.value }}
              </option>
            </select>
          </div>
        </div>
        <div v-else>&nbsp;</div>
        <!-- <div>Вес: {{ prod.weight }}</div> -->
        <!-- <div>На складе: {{ stk_free }}</div> -->
        <div class="price_with_vat">
          <small>Цена с налогом({{ prod.vat_rate }}%): {{ prod.price_with_vat }}</small>
        </div>
        <div
          class="price"
          v-if="
            !prod.discount_price ||
              Number(prod.discount_price) === 0 ||
              prod.discount_price === prod.price" >
          <strong>Цена:</strong> <span class="reg_price">{{ prod.price }}</span>
        </div>
        <div class="price" v-else><strong>Цена:</strong> <span class="alert_price">{{ prod.discount_price }}</span> <span class="old_price">{{ prod.price }}</span></div>
        <!-- <div class="price_with_vat">
          <strong>Цена с НДС: {{ prod.price_with_vat }}</strong>
        </div> -->
        <div class="to_cart space_above my-4" v-if="Number(prod.weight) > 0">
          <b-form-spinbutton :size="btn_size" v-model="quan" min="1" max="100" />
          <b-button :size="btn_size" variant="success" @click="toCart()">
            В корзину
          </b-button>
        </div>
        <div class="to_cart no_weight space_above my-4 py-4" v-else>
          <small>Нельзя заказать - нет веса.</small>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ItemCard",
  components: {},
  data: () => ({
    quan: 0,
    selected: null
  }),
  computed: {
    ...mapGetters({
      user_id: "user/user_id"
    }),
    group_link() {
      return `/${this.group.id}`;
    },
    prod() {
      return this.$store.getters["products/get_product"];
    },
    group() {
      return this.prod.group;
    },
    is_desktop() {
      return this.$store.getters["app/is_desktop"];
    },
    btn_size() {
      return this.is_desktop ? 'sm' : '';
    },
    def_selection() {
      if (this.prod.variants && this.prod.variants.length > 0) {
        return this.prod.variants[0];
      }
      return null;
    },
    // variants() {
    //   if (this.prod.variants) {
    //     const sizes = this.prod.variants.map( v => v.value )
    //     return sizes.join(', ');
    //   }
    //   return []
    // },
    stk_free() {
      return this.prod.stk_free > 3 ? "много" : `${this.prod.stk_free} шт.`;
    },
    thumbnail() {
      if (this.prod.images.length === 0) return;
      return this.prod.images[0].largeURL;
    }
  },
  watch: {
    '$route'(to, from) {
      // console.log('Route changed')
      if (this.$route.params.item_id) {
        this.$store.dispatch("products/getProduct", {
          item_id: this.$route.params.item_id
        });
      }
    },
  },
  created() {
    // console.log('Created', this.$route.params.item_id)
    if (this.$route.params.item_id) {
      this.$store.dispatch("products/getProduct", {
        item_id: this.$route.params.item_id
      }).then(() => {
        window.scrollTo(0, 80);
        this.selected = this.prod.variants ? this.prod.variants[0] : null;
      });
    }
  },
  methods: {
    saveCart: function() {
      this.$store.dispatch("cart/saveCart", {user_id: this.user_id})
    },
    toCart() {
      const q = this.quan > 0 ? this.quan : 1;
      this.$store
        .dispatch("cart/addProduct", {
          ...this.prod,
          quan: q,
          variant: this.selected
        })
        .then(() => this.saveCart());
      this.quan = 0;
      this.selected = null;
    },
  }
};
</script>


<style lang="sass">
.fade
  position: relative
  height: 3.6em

.full_item
  // min-width: 23%
  // max-width: 218px
  // margin-right: 2%
  // margin-bottom: 3%
  // border: 1px solid #e2e2e2
  // padding: 8px
  // border-radius: 4px
  .full_image
    padding-top: 10px
    display: flex
    justify-content: center
    align-items: center
    img
      max-width: 80%
      border: 1px solid #e2e2e2
      padding: 4px
      border-radius: 4px

    .placeholder
      width: 100%
      height: 100%
      background-color: #e2e2e2
      border: 1px solid #d5d5d5
      display: flex
      justify-content: center
      align-items: center
      max-width: 480px

  .group_title
    padding: 10px 0
    margin-top: auto

  .item_title
    padding: 10px 0
    font-weight: 500
    font-size: 1.4rem
    text-transform: uppercase
    overflow: hidden
    text-overflow: ellipsis

  .desc
    min-height: 42px
    overflow: hidden
    text-overflow: ellipsis

.variants
  display: flex
  .label
    margin-right: 9px

.to_cart
  display: flex
  align-items: center
  justify-content: flex-start
  .b-form-spinbutton
    width: auto
    margin-right: 8px

  &.no_weight
    display: block
    text-align: center

.price_with_vat
  font-style: italic
.price
  margin-top: 5px
  padding-top: 5px
  border-top: 1px solid #e2e2e2
  display: flex
  align-items: center
  flex-wrap: wrap

.alert_price, .reg_price
  margin: 0 10px
  font-size: 1.3rem
  font-weight: bold

.reg_price
  color: blue

.alert_price
  color: red

.old_price
  font-weight: 400
  position: relative
  &:before
    content: ""
    position: absolute
    top: 50%
    width: 110%
    height: 1px
    display: block
    background: #f91155
    transform: rotate(-10deg)

@media (max-width: 688px)
  .item
    width: 100%
    max-width: 100%
    font0-size: 1.1rem
    .title
      margin-top: auto
      width: 100%
      max-width: 100%
</style>
