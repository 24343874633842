<template>
  <div>
    <div class="wrapper">
      <b-container fluid>
        <Header />
        <b-row v-if="is_admin===false && is_desktop">
          <b-col>
            <b-alert variant="info" class="text-center mb-4" show>
              Доп. информацию по оформлению заказов можно найти
              в нашей группе в <a href="http://vk.com/finshopfi" target="_blank"><i class="fab fa-vk"></i></a>
            </b-alert>
          </b-col>
        </b-row>
        <Message
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :closeMessage="closeMessage"
        />
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </b-container>
    </div>
    <div id="footer">
      <div id="brand">
        <span class="company"><strong>FIN</strong> Shop</span>
        <div>Товары из Финляндии</div>
        <div>
          <strong>+358 44 973 8044</strong> Tel. / Viber / WhatsApp <br />
          <strong>+358 40 158 7462</strong> WhatsApp <br />
          <strong>
            <a href="mailto:sktukku@gmail.com">
              sktukku@gmail.com
            </a>
          </strong>
        </div>
      </div>
      <div id="c3">
        <ul>
          <li v-for="page in pages"
            :key="page.id"
            :page="page">
            <router-link :to="getUrl(page.page_slug)">{{ page.page_name }}</router-link>
          </li>
        </ul>
      </div>

      <div id="c4">
        <a href="http://vk.com/finshopfi" target="_blank"><i class="fab fa-vk"></i></a>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Header from "@/components/generic/Header";
import Message from "@/components/generic/Message";
// import SearchBar from "@/components/generic/SearchBar";

export default {
  name: "App",
  components: {
    Header,
    Message,
    // SearchBar
  },
  data: () => ({
    is_admin: false
  }),
  computed: {
    ...mapGetters({
      messages: "app/messages",
      pages: "content/pages",
      isLoggedIn: "user/isLoggedIn",
      is_desktop: "app/is_desktop"
    }),
  },
  watch: {
    '$route'(to, from) {
      if(to.path.indexOf('/a/') !== -1) {
        this.is_admin = true;
      } else {
        this.is_admin = false;
      }
    }
  },
  methods: {
    closeMessage: function(message_id) {
      this.$store.dispatch("app/closeMessage", message_id);
    },
    getUrl(slug) {
      return `/p/${slug}`;
    }
  },
  created() {
    if(this.$route.path.indexOf('/a/') !== -1) {
      this.is_admin = true;
    } else {
      this.is_admin = false;
    }
  }
};
</script>
