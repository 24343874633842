<template>
  <div class="n-table data-grid space_below">
    <div class="table-row">
      <div class="table-head dark w40">нпп</div>
      <div class="table-head dark w40">ID #</div>
      <div class="table-head dark ">Название</div>
      <div class="table-head dark w50">ERP Акт?</div>
      <div class="table-head dark w50">Акт?</div>
      <div class="table-head dark w40"></div>
      <div class="table-head dark w40">Top?</div>
    </div>
    <AdmGroupRow
      v-for="(group, idx) in groups"
      :key="group.id"
      :idx="idx"
      :group="group"
      :npp="groups.length - idx"
      :setActive="setActive"
      :setTop="setTop"
      :inner="false"
      :edit="edit_id === group.id"
      :setEdit="setEdit"
      :cancelEdit="cancelEdit"
      :saveEdit="saveEdit"
      />
  </div>
</template>

<script>
import AdmGroupRow from "./AdmGroupRow";

export default {
  name: "AdmGroupsList",
  components: {
    AdmGroupRow
  },
  props: ["edit_id", "groups", "setActive", "setTop", "setEdit", "cancelEdit", "saveEdit"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
</style>
