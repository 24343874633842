<template>
  <b-row class="mb-2">
    <b-col sm="4" :class="{ 'text-right': right }">
      <label class="col-form-label">
        {{ label }}<span class="mndt" v-if="mandatory">*</span>
      </label>
    </b-col>
    <b-col sm="8">
      <select
        v-if="!flat"
        id="item_id"
        class="form-control form-control-sm"
        name="item_id"
        @change="updateEntity"
      >
        <option :value="0" :selected="selectedEntity(null)" v-if="all_label">
          {{ all_label }}
        </option>
        <option
          v-for="item in list"
          :key="item.id"
          :value="item.id"
          :selected="selectedEntity(item)"
        >
          {{ getLabel(item) }}
        </option>
      </select>

      <select
        v-else
        id="item_id"
        class="form-control form-control-sm"
        name="item_id"
        @change="updateEntity"
      >
        <option
          v-for="item in list"
          :key="item"
          :value="item"
          :selected="selectedFlatEntity(item)"
        >
          {{ item }}
        </option>
      </select>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Selector",
  props: [
    "all_label",
    "list",
    "label",
    "mandatory",
    "flat",
    "right",
    "selectEntity",
    "selected",
    "value_label"
  ],
  computed: {},
  methods: {
    getLabel(item){
      return item[this.value_label];
    },
    selectedEntity(entity) {
      if (entity) {
        if (this.selected && this.selected === entity.id) return true;
      }
    },
    selectedFlatEntity(entity) {
      if (entity) {
        if (this.selected && this.selected === entity) return true;
      }
    },
    updateEntity(ev) {
      this.selectEntity(ev.target.value);
    }
  }
};
</script>
