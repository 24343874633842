<template>
  <b-row v-if="!loading">
    <b-col>
      <PageTitle title="Все заказы" />
      <b-row>
        <b-col>
          <SearchBar
            :keyword="keyword"
            :search-this="doSearch"
            :clear-search="clearSearch"
            :change-search="changeSearch"
          />
        </b-col>
        <b-col></b-col>
        <b-col>
          <b-row>
            <b-col>
              <Selector
                all_label="Все"
                right="true"
                label="Статус"
                :list="statuses"
                value_label="st_name"
                :selected="status"
                :select-entity="selectStatus"
              />
            </b-col>
            <b-col>
              <TriFilter
                title="Оплачен?"
                :selected_filter="is_paid"
                :set-filter="setPaidFilter"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <TriFilter
                title="Есть товар по ссылке?"
                :selected_filter="has_links"
                :set-filter="setHasLinks"
              />
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col></b-col>
            <b-col>
              <div class="form-group row">
                <label for="start_date" class="dates col-sm-4 col-form-label"
                  >Дата, c:</label
                >
                <div class="col-sm-8">
                  <datepicker
                    v-model="start_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="form-group row">
                <label for="end_date" class="dates col-sm-4 col-form-label"
                  >Дата, по:</label
                >
                <div class="col-sm-8">
                  <datepicker
                    v-model="end_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="row-block" v-if="selected_orders.length > 0">
            <div>Выбран: {{ selected_orders.length }} заказ(ов).</div>
            <div>
              <b-button v-if="status === 2"
                size="sm"
                variant="primary"
                @click="createAsmList"
              >
                Создать сборку
              </b-button>
              <b-button v-if="status === 1"
                size="sm"
                variant="success"
                @click="acceptOrders"
              >
                Принять
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="row-block-center">
            <OrderColors />
          </div>
        </b-col>
        <b-col>
          <Paginator :page="current_page" :pages="page_range" :set-page="setPage" />
        </b-col>
      </b-row>
      <AdmOrdersList
        :orders="orders"
        :meta="meta"
        :totals="totals"
        :status="status"
        :selected_orders="selected_orders"
        :selectOrder="selectOrder"
        :query_string="query_string"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

// import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
// import { ISO_Long_To_Short } from "@/services/date_tools";

import OrderColors from "./OrderColors";
import AdmOrdersList from "./AdmOrdersList";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import Paginator from "@/components/generic/backoffice/Paginator";
import SearchBar from "@/components/generic/backoffice/SearchBar";
import Selector from "@/components/generic/backoffice/Selector";
import TriFilter from "@/components/generic/backoffice/TriFilter";

export default {
  name: "AdmOrders",
  components: {
    // Datepicker,
    AdmOrdersList,
    OrderColors,
    PageTitle,
    Paginator,
    SearchBar,
    Selector,
    TriFilter
  },
  data: () => ({
    keyword: null,
    loading: true,
    ru: ru,
    format: "dd-MM-yyyy",
    start_date: null,
    end_date: null,
    is_paid: 0,
    has_links: 0,
    selected_orders: [],
    loading: true
  }),
  computed: {
    ...mapGetters({
      orders: "orders/orders",
      meta: "orders/meta",
      selected_filter: "orders/selected_filter",
      totals: "orders/totals",

      statuses: "orders/statuses",
      status: "orders/status",

      page_range: "orders/page_range",
      current_page: "orders/current_page"
    }),
    query_string() {
      const query = this.buildPayload({ page: this.current_page });
      return Object.keys(query)
        .reduce(function(a, k) {
          a.push(k + "=" + encodeURIComponent(query[k]));
          return a;
        }, [])
        .join("&");
    }
  },
  watch: {},
  created() {
    this.$store.dispatch("orders/getStatuses");
    const payload = this.$route.query.sts
      ? { status: this.$route.query.sts }
      : null;
    if (payload) this.$store.commit("orders/setStatus", payload);

    if (this.$route.query.is_paid)
      this.is_paid =
        this.$route.query.is_paid === "0"
          ? 0
          : this.$route.query.is_paid === "true";

    if (this.$route.query.has_links)
      this.has_links =
        this.$route.query.has_links === "0"
          ? 0
          : this.$route.query.has_links === "true";

    this.getOrders({ page: 1, ...this.$route.query });
  },
  methods: {
    selectOrder(order_id) {
      if (order_id < 0) {
        this.selected_orders = this.selected_orders.filter(id => id !== (order_id * -1));
      } else {
        this.selected_orders.push(order_id);
      };

    },
    createAsmList() {
      this.$store.dispatch("orders/createAsmList", {
        selected_orders: this.selected_orders,
        note: "",
        executor: "",
        mode: "a"
      });
    },
    acceptOrders() {
      this.$store
        .dispatch("orders/acceptOrders", {
          selected_orders: this.selected_orders
        })
        .then(st => {
          if (st === "info") {
            this.getOrders({ page: 1, sts: 2 });
            this.selected_orders = [];
          }
        });
    },
    setPaidFilter: function(is_paid) {
      this.is_paid = is_paid;
      this.getOrders({ is_paid });
    },
    setHasLinks: function(has_links) {
      this.has_links = has_links;
      this.getOrders({ has_links });
    },
    buildPayload(payload) {
      const kw = this.keyword ? { order_num: this.keyword } : {};
      const sts = payload.sts
        ? payload.sts
        : this.status >= 0
          ? { sts: this.status }
          : {};
      return {
        has_links: this.has_links,
        is_paid: this.is_paid,
        ...sts,
        ...kw,
        ...payload,
        mode: "a"
      };
    },
    getOrders: function(payload) {
      this.loading = true;
      this.$store.dispatch("orders/getOrders", this.buildPayload(payload)).then(() => this.loading = false);
    },
    selectStatus(status_id) {
      this.getOrders({ page: 1, sts: status_id });
    },
    selectClient: function(client_id) {
      this.client_id = client_id;
    },
    // search
    changeSearch(keyword) {
      this.keyword = keyword;
    },
    doSearch() {
      this.getOrders({ sts: 0 });
    },
    clearSearch: function() {
      this.keyword = null;
      this.getOrders({ page: 1 });
    },
    setPage(page) {
      this.$store.commit("orders/setPage", { page });
      this.getOrders({ page });
    }
  }
};
</script>

<style lang="sass">
.dates
  text-align: right
</style>
