<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="40"></th>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th width="50">Сборка</th>
        <th width="140">Клиент</th>
        <th width="100">Статус</th>
        <th width="60">Опл.</th>
        <th width="220">Адрес</th>
        <th>Комментарий</th>
        <th width="140">Магазины</th>
        <th width="50">Поз.</th>
        <th width="50">Кол.</th>
        <th width="50">Вес</th>
        <th width="60">Сумма</th>

        <th width="3"></th>
        <th width="50">ПозФ.</th>
        <th width="50">КолФ.</th>
        <th width="50">ВесФ</th>
        <th width="60">СумФ</th>
        <th width="60">Дост.</th>
        <th width="60">СумФ+Д</th>
        <!-- <th width="60">СФ+НДС</th> -->
        <th width="40"></th>
      </tr>
      <tr class="thead-light">
        <th colspan="10"></th>
        <th class="want">{{ totals.gttl_pos }}</th>
        <th class="want">{{ totals.gttl_quan }}</th>
        <th class="want">{{ totals.gttl_wgt | toCommas }}</th>
        <th class="want">{{ totals.gttl_full | toCommas }}</th>
        <th></th>
        <th class="fact">{{ totals.gttl_pos_fct }}</th>
        <th class="fact">{{ totals.gttl_quan_fct }}</th>
        <th class="fact">{{ totals.gttl_wgt_fct | toCommas }}</th>
        <th class="fact">{{ totals.gttl_raw_fct | toCommas }}</th>
        <th></th>
        <th class="fact">{{ delivery_totals | toCommas }}</th>
        <!-- <th class="fact">{{ totals.gttl_full_fct | toCommas }}</th> -->
        <th></th>
      </tr>
    </thead>
    <tbody>
      <AdmOrderRow
        v-for="(doc, idx) in orders"
        :key="doc.id"
        :idx="idx"
        :doc="doc"
        :npp="orders.length - idx"
        :selected_orders="selected_orders"
        :selectOrder="selectOrder"
        :query_string="query_string"
        :status="status"
      />
    </tbody>
    <thead class="thead-light">
      <tr>
        <th colspan="10"></th>
        <th class="want">{{ totals.gttl_pos }}</th>
        <th class="want">{{ totals.gttl_quan }}</th>
        <th class="want">{{ totals.gttl_wgt | toCommas }}</th>
        <th class="want">{{ totals.gttl_full | toCommas }}</th>
        <th></th>
        <th class="fact">{{ totals.gttl_pos_fct }}</th>
        <th class="fact">{{ totals.gttl_quan_fct }}</th>
        <th class="fact">{{ totals.gttl_wgt_fct | toCommas }}</th>
        <th class="fact">{{ totals.gttl_raw_fct | toCommas }}</th>
        <th></th>
        <th class="fact">{{ delivery_totals | toCommas }}</th>
        <!-- <th class="fact">{{ totals.gttl_full_fct | toCommas }}</th> -->
        <th></th>
      </tr>
    </thead>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

import AdmOrderRow from "./AdmOrderRow";

export default {
  name: "AdmOrdersList",
  components: {
    AdmOrderRow
  },
  computed: {
    ...mapGetters({
      delivery_totals: "orders/delivery_totals"
    })
  },
  props: {
    orders: Array,
    status: Number,
    totals: Object,
    selected_orders: Array,
    selectOrder: Function,
    query_string: String
  }, // "meta",
  methods: {}
};
</script>
