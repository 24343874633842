import Api from "@/services/general";

// initial state
const state = {
  documents: [],
  payments: [],
  totals: {},
  clients: [],
  selected_filter: 0,
  payment_data: null,
  page_range: [1],
  current_page: null,
};

const getters = {
  current_page: state => state.current_page,
  page_range: state => state.page_range,

  clients: state => state.clients,
  payments: state => state.payments,
  totals: state => state.totals,
  selected_filter: state => state.selected_filter,
  payment_data: state => state.payment_data
};

const actions = {
  async getClients() {
    const res = await Api.getData("a/clients");
    this.commit("finances/setClients", res.data);
  },
  async payOrder(ctx, payload) {
    const res = await Api.setData("u/pay_order", null, payload);
    this.commit("finances/setPaymentData", res.data);
  },
  async markOrder(ctx, payload) {
    const res = await Api.setData("u/mark_order", payload.order_id, payload);
  },
  async getPayments(ctx, payload) {
    const res = await Api.getData("a/payments", null, payload);
    this.commit("finances/setPayments", res.data.list);
    this.commit("finances/setTotals", res.data.totals);
    this.commit("finances/setPaginator", {...res.data.paginator});
    this.commit("finances/setFilter", payload.is_checked);
  },
  async saveExpense(ctx, payload) {
    const res = await Api.setData("expense", null, payload);
    this.commit("orders/setData", { key: "order", value: res.data.order });
  },
  async savePayment(ctx, payload) {
    const { id } = payload;
    delete payload.id;
    const res = await Api.setData("a/payment", id ? id : null, payload);
    this.commit("finances/addPayment", res.data.record);
  },
  async delPayment(ctx, payload) {
    await Api.delData("a/payment", payload);
    this.commit("finances/delPayment", payload.doc_id);
  }
};

const mutations = {
  addPayment(state, payload) {
    const payment = state.payments.find(p => p.id === payload.id);
    if (payment) {
      state.payments = state.payments.map(p =>
        p.id === payload.id ? payload : p
      );
    } else {
      state.payments = [payload, ...state.payments];
    }
  },
  delPayment(state, doc_id) {
    const documents = state.payments.filter(doc => doc.id !== doc_id);
    state.payments = documents;
  },
  setClients(state, payload) {
    state.clients = payload;
  },
  setPayments(state, payload) {
    state.payments = payload;
  },
  setTotals(state, payload) {
    state.totals = payload;
  },
  setFilter(state, payload) {
    state.selected_filter = payload;
  },
  setPaymentData(state, payload) {
    state.payment_data = payload;
  },
  setPaginator(state, payload) {
    state.page_range = payload.page_range;
    state.current_page = payload.current_page;
  },
  setPage(state, payload) {
    state.current_page = payload.page;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
