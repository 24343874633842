const state = {
  loading: 0
};

const getters = {
  loading: state => state.loading
};

const mutations = {
  START_LOADING: state => state.loading++,
  FINISH_LOADING: state => state.loading--,
  RESET_LOADING: state => (state.loading = 0)
};

const loader = {
  namespaced: true,
  state,
  getters,
  mutations
};

export default loader;
