import { uuid } from 'vue-uuid';
import Api from "@/services/general";

// initial state
const state = {
  cart: []
};

const getters = {
  get_cart: state => state.cart,
  get_total_quan: state =>
    state.cart.reduce((acc, prod) => acc + Number(prod.quan), 0),
  get_total_weight: state =>
    state.cart.reduce((acc, prod) => {
      const w = prod.weight.replace(",", ".");
      if (Number(prod.quan) > 0 && Number(w)) {
        return acc + Number(prod.quan) * Number(w);
      }
      return acc;
    }, 0),
  get_total_sum: state =>
    state.cart.reduce(
      (acc, prod) => acc + Number(prod.quan) * Number(prod.price),
      0
    ),
  get_total_sum_with_vat: state =>
    state.cart.reduce(
      (acc, prod) => acc + Number(prod.quan) * Number(prod.price_with_vat),
      0
    )
};

const actions = {
  async saveLinkCart(ctx, payload) {
    const { user_id } = payload;
    if (!user_id) {
      localStorage.setItem("cart_link", JSON.stringify(ctx.state.cart));
      return "OK";
    }

    const res = await Api.setData("u/save_cart", null, {
      ...payload,
      dest: 'link',
      products: JSON.stringify(ctx.state.cart)
    });
    return res.data.status;
  },
  async addProduct(ctx, payload) {
    this.commit("products_links/addProduct", payload);
  },
  async delProduct(ctx, {id}) {
    this.commit("products_links/delProduct", id);
  },
  changeQuan(ctx, payload) {
    this.commit("products_links/changeQuan", payload);
  },
  async delImage(ctx, id) {
    const res = await Api.delData("file", { id });
    if (res.data) return res.data.status;
  },
  async sendOrder(ctx, payload) {
    const res = await Api.setData("create_order", null, {
      ...payload,
      products: ctx.state.cart
    });
    if (res.data.message.type === "success") {
      this.commit("cart/setProducts", []);
    }
    return res.data.message.type;
  }
};

const mutations = {
  addProduct(state, payload) {
    state.cart = [{...payload}, ...state.cart];
  },
  delProduct(state, id) {
    state.cart = state.cart.filter(prod => prod.prod_id !== id);
  },
  setProducts(state, payload) {
    state.cart = payload;
  },
  changeQuan(state, { product_id, quan }) {
    state.cart = state.cart.map(prod => {
      if (prod.prod_id === product_id) {
        return {
          ...prod,
          quan,
          total: Number(quan) * Number(prod.price_with_vat)
        };
      }
      return prod;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
