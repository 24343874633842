<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th width="140">Исполнитель</th>
        <th>Комментарий</th>
        <th width="80">Заказов</th>
        <th width="160">Заказы</th>
        <th width="50">Акт?</th>
        <th width="120">Дата</th>
        <th width="120">Автор</th>
        <th width="40"></th>
      </tr>
    </thead>
    <tbody>
      <AsmListRow
        v-for="(doc, idx) in asm_lists"
        :key="doc.id"
        :idx="idx"
        :doc="doc"
        :npp="asm_lists.length - idx"
      />
    </tbody>
  </table>
</template>

<script>
import AsmListRow from "./AsmListRow";

export default {
  name: "AsmList",
  components: {
    AsmListRow
  },
  props: {
    asm_lists: Array
  },
  computed: {},
  methods: {}
};
</script>
