import { DateTime } from "luxon";

export function ISO_Date_To_Date(isoDate) {
  return DateTime.fromISO(isoDate, { locale: "ru" }).toFormat("dd-MM-yyyy");
}

export function ISO_Long_To_Short(isoDate) {
  return DateTime.fromISO(isoDate).toFormat('yyyy-MM-dd');
}

export function splitDate(longDate) {
  const [d, t] = longDate.split(" ");
  return `${d}<br /><small>${t.replace("-", ":")}</small>`;
}
