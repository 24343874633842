<template>
  <div>
    <b-row>
      <b-col>
        <PageTitle
          title="Перегрузка ERPLY"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="loading==='lock'" class="toolbar right t-row space_below">
          <div class="t-cell">
            <b-spinner variant="warning" label="Loading..."></b-spinner>
          </div>
          <div class="t-cell">
            <b-button
              size="sm"
              variant="outline-success"
              @click="checkUpdate()">
                Проверить перегрузку
            </b-button>
          </div>
        </div>
        <table class="table">
          <tr>
            <td>
              Перегрузить <strong>ВСЁ</strong>
            </td>
            <td>
              <b-button v-if="loading!=='lock'"
                size="sm"
                :variant="variant"
                :disabled="!!loading"
                @click="startLoad('all')">
                  Начать
              </b-button>
            </td>
            <td width="180">
              {{ logs && logs['all'] ? logs['all']['created_at'] : '-' }}
            </td>
            <td width="180">
              {{ logs && logs['all'] && logs['all']['status'] ? logs['all']['updated_at'] : '-' }}
            </td>
          </tr>
          <tr>
            <td>
              Перегрузить <strong>Только товары</strong> <br>
              <small>Загружает: группы + товары</small>
            </td>
            <td>
              <b-button v-if="loading!=='lock'"
                size="sm"
                :variant="variant"
                :disabled="!!loading"
                @click="startLoad('goods')">
                  Начать
              </b-button>
            </td>
            <td>
              {{ logs && logs['goods'] ? logs['goods']['created_at'] : '-' }}
            </td>
            <td>
              {{ logs && logs['goods'] && logs['goods']['status'] ? logs['goods']['updated_at'] : '-' }}
            </td>
          </tr>
          <tr>
            <td>
              Перегрузить <strong>Скидки</strong> <br>
              <small>Загружает и применяет только скидки</small>
            </td>
            <td>
              <b-button v-if="loading!=='lock'"
                size="sm"
                :variant="variant"
                :disabled="!!loading"
                @click="startLoad('discount')">
                  Начать
              </b-button>
            </td>
            <td>
              {{ logs && logs['discount'] ? logs['discount']['created_at'] : '-' }}
            </td>
            <td>
              {{ logs && logs['discount'] && logs['discount']['status'] ? logs['discount']['updated_at'] : '-' }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageTitle from "@/components/generic/backoffice/PageTitle";

export default {
  name: "AdmUpdater",
  components: {
    PageTitle
  },
  data() {
    return {
      loading: null,
      variant: "primary"
    };
  },
  computed: {
    ...mapGetters({
      logs: "services/logs",
      no_ongoing_updates: "services/no_ongoing_updates"
    })
  },
  methods: {
    startLoad(mode) {
      this.loading = "lock";
      this.variant = "danger";
      this.$store.dispatch("services/updateCatalog", {mode})
      .then(() => {});
    },
    checkUpdate() {
      this.$store.dispatch("services/getUpdatesLog")
      .then(() => {
        if (this.no_ongoing_updates) {
          this.loading = null;
          this.variant = "primary";
        } else {
          this.loading = "lock";
          this.variant = "danger";
        }
      });
    }

  },
  created() {
    this.checkUpdate();
  }
};
</script>

<style module lang="sass">
</style>