const state = {};

const getters = {
  current_page: state => origin => {
    return state[origin] ? state[origin].current_page : 1;
  },
  page_range: state => origin =>
    state[origin] ? state[origin].page_range : [1]
};

const actions = {};

const mutations = {
  setPaginator(state, payload) {
    state[payload.origin] = {};
    state[payload.origin].page_range = payload.page_range;
    state[payload.origin].current_page = payload.current_page;
  },
  setPage(state, payload) {
    state[payload.origin].current_page = payload.page;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
