<template>
  <b-col>
    <WidgetTitle :title="title" />
    <b-row class="mb-2">
      <b-col sm="2">
        <label for="sld_namr">Название:</label>
      </b-col>
      <b-col sm="10">
        <b-form-input id="sld_name" size="sm" v-model="sld_name" />
        <small class="text-danger" v-if="sld_name !== '' && !uniqueSlide">
          Не уникальное название слайда.
        </small>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="2">
        <label for="sld_link">Ссылка:</label>
      </b-col>
      <b-col sm="10">
        <b-form-input id="sld_link" size="sm" v-model="sld_link" />
        <small class="text-danger" v-if="sld_link !== '' && !uniqueLink">
          Не уникальная ссылка.
        </small>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="2">
        <label for="sld_text">Текст:</label>
      </b-col>
      <b-col sm="10">
        <ckeditor :editor="editor"
          :config="editorConfig"
          id="sld_desc"
          size="sm"
          rows="24"
          max-rows="36"
          v-model="sld_desc">
        </ckeditor>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="2">
        <label for="is_active">Активен?:</label>
      </b-col>
      <b-col sm="10">
        <b-form-checkbox v-model="is_active" name="is_active" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="2">
        <label>Картинка:</label>
      </b-col>
      <b-col>
        <div class="uploader">
          <div class="preview" v-if="show_preview">
            <img v-show="file_url" :src="file_url" class="img-preview" width="320" />
          </div>
          <image-uploader
            :debug="0"
            :maxWidth="320"
            :quality="0.7"
            :autoRotate="true"
            outputFormat="file"
            :preview="false"
            :className="['fileinput', { 'fileinput--loaded': has_image }]"
            :capture="false"
            accept="image/*"
            doNotResize="['gif', 'svg']"
            @input="setImage"
            @onUpload="onUpload"
            @onComplete="onComplete"
          >
            <label for="fileInput" slot="upload-label">
              <div>
                <strong>ВНИМАНИЕ!!!</strong>
                <br>
                <small>Все картинки в слайдере должны быть одинакового размера.<br>
                Рекомендованная высота - 320 пикселей, ширина - 1024 пикселя.</small>
              </div>
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32">
                  <path
                    class="path1"
                    d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z">
                  </path>
                </svg>
              </figure>
              <span class="upload-caption">{{
                has_image ? "Заменить" : "Загрузить"
              }}</span>
            </label>
          </image-uploader>
        </div>
      </b-col>
    </b-row>
    <div class="toolbar right t-row border-top pt-2">
      <b-button
        variant="info"
        @click="newSlide"
      >
        Новый слайд
      </b-button>
      <b-button
        :disabled="isDisabled"
        variant="primary"
        @click="saveSlide"
      >
        Сохранить
      </b-button>
    </div>
  </b-col>
</template>

<script>
import { uuid } from 'vue-uuid';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

import Api from "@/services/general";

export default {
  name: "SlideForm",
  components: {
    WidgetTitle
  },
  props: ["active_slide", "resetActive", "checkUnique"],
  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "|",
        "undo",
        "redo"
      ]
    },
    id: null,
    sld_name: "",
    sld_desc: "",
    sld_link: "",
    sld_image: null,
    is_active: false,
    uniqueSlide: false,
    uniqueLink: false,
    slide_ref: null,
    show_preview: false,
    has_image: false,
    image_id: null,
    file_url: ""
  }),
  computed: {
    isDisabled() {
      return (
        this.sld_name === "" ||
        !this.uniqueSlide ||
        !this.uniqueLink
      );
    },
    title() {
      return !this.id ? "Новый слайд" : "Редактирование слайда";
    }
  },
  watch: {
    sld_name(newVal) {
      this.uniqueSlide = this.checkUnique("sld_name", this.id, newVal);
    },
    sld_link(newVal) {
      this.uniqueLink = this.checkUnique("sld_link", this.id, newVal);
    },
    active_slide(newVal, oldVal) {
      if (!oldVal || newVal.id !== oldVal.id) {
        this.id = newVal.id;
        this.sld_name = newVal.sld_name;
        this.sld_desc = newVal.sld_desc;
        this.sld_link = newVal.sld_link;
        this.is_active = newVal.is_active;
        this.sld_image = newVal.sld_image;
        this.slide_ref = null;
        if (this.sld_image) {
          this.file_url = this.sld_image.file_url;
          this.has_image = true;
          this.show_preview = true;
        }
      }
    }
  },
  created() {},
  methods: {
    newSlide() {
      this.resetActive();
      this.resetForm();
    },
    resetForm() {
      this.id = null;
      this.sld_name = "";
      this.sld_desc = "";
      this.sld_link = "";
      this.is_active = false;
      this.slide_ref = null;
      this.show_preview = false;
      this.has_image = false;
      this.image_id = null;
      this.file_url = "";
      this.sld_image = null;
    },
    saveSlide() {
      const action = this.id ? "updateSlide" : "createSlide";
      this.$store
        .dispatch(`content/${action}`, {
          id: this.id,
          sld_name: this.sld_name,
          sld_desc: this.sld_desc,
          sld_link: this.sld_link,
          slide_ref: this.slide_ref,
          is_active: this.is_active
        })
        .then(status => {
          this.resetForm();
          this.resetActive();
        });
    },

    delImage: function() {
      if (this.image_id)
        this.$store
          .dispatch("content/delImage", this.image_id)
          .then(status => {
            if (status === "ok") {
              this.image_id = null;
              this.file_url = null;
              this.show_preview = false;
              this.has_image = false;
              this.slide_ref = null;
              this.sld_image = null;
            }
        });
    },
    onUpload: function() {
      this.show_preview = false;
    },
    onComplete: function() {
      this.show_preview = true;
    },
    setImage: function(output) {
      this.has_image = true;
      this.show_preview = true;

      const formData = new FormData();
      formData.append("file_path", output, output.name);
      const slide_ref = uuid.v4();
      formData.append("slide_ref", slide_ref);
      this.slide_ref = slide_ref;

      Api.uploadSlide(formData).then(x => {
        this.image_id = x.id;
        this.file_url = x.file_url;
      });
    }
  }
};
</script>


<style lang="sass">
  .ck-content
    height: 300px
</style>