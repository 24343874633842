<template>
  <div ref="scrollToMe">
    <b-row>
      <b-col md="3">
        <div class="catalog_title">
          <PageTitle title="Каталог" />
        </div>
        <b-row>
          <b-col>
            <div class="divider"></div>
          </b-col>
        </b-row>
        <div v-if="is_desktop">
          <b-col>
            <div class="scrollable_panel">
              <GroupsList
                :groups="groups"
                :selected_group="searchMode ? null : selected_group"
                :setSelected="setSelected"
              />
            </div>
          </b-col>
        </div>
        <b-sidebar v-if="!is_desktop" id="sidebar-catalog" title="Каталог" shadow>
          <div class="px-3 py-2">
            <GroupsList
              :groups="groups"
              :selected_group="searchMode ? null : selected_group"
              :setSelected="setSelected"
            />
          </div>
        </b-sidebar>

      </b-col>
      <b-col>
        <router-view></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import GroupsList from "@/components/groups/GroupsList";
import PageTitle from "@/components/generic/PageTitle";

export default {
  name: "Catalog",
  components: {
    // Items,
    GroupsList,
    PageTitle,
  },
  computed: {
    ...mapGetters({
      keyword: "search/keyword",
      searchGlobal: "search/searchGlobal",
      searchMode: "search/searchMode",

      groups: "groups/get_groups",
      selected_group: "groups/get_selected",
      is_desktop: "app/is_desktop",
    }),
  },
  methods: {
    scrollToElement() {
      const el = document.getElementById("header_top");
      // el.scrollTop = el.scrollHeight +120;
      window.scrollTo(0, 0);
      // el.scrollIntoView({behavior: "smooth", block: "start"});
    },
    getGroups: function() {
      this.$store.dispatch("groups/getGroups");
    },
    setSelected: function(group) {
      const kw = this.keyword ? { keyword: this.keyword } : {};
      const grp = group ? { group_id: group.id } : {};

      this.$store.dispatch("products/getProducts", {
        ...grp,
        ...kw,
        global: this.searchGlobal
      }).then(() => {
        this.scrollToElement();
        this.$store.commit("products/setLoading", false);
      });
      group && this.$store.commit("groups/setSelected", group);
    },

    // toCart(product) {
    //   this.$store.commit("cart/addProduct", product);
    // }

  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit("products/setLoading", true);
    const { q, global, group_id } = to.query;
    if (q) {
      // this.searchMode = true;
      // this.searchGlobal = global;

      this.$store.commit("search/setKeyword", q);
      this.$store.commit("search/setSearchGlobal", global);
      this.$store.commit("search/setSearchMode", true);

      this.setSelected(getGroupId(group_id, this.groups)); // group disabled in fact
    } else { // trash
      const grp = to.params.cat_id
        ? to.params.cat_id
        : this.selected_group
        ? this.selected_group.id
        : null;
      // this.keyword = null;
      // this.searchMode = false;
      // this.searchGlobal = true;

      this.$store.commit("search/setKeyword", null);
      this.$store.commit("search/setSearchGlobal", true);
      this.$store.commit("search/setSearchMode", false);

      this.setSelected(getGroupId(grp, this.groups));
    }
    next();
  },
  created() {
    this.$store.commit("products/setLoading", true);
    this.$store.dispatch("content/getSlides");
    this.$store.dispatch("groups/getGroups").then(() => {
      const { q, global, group_id } = this.$route.query;
      let grp = null;
      // this.keyword = null;
      // this.searchGlobal = global;

      this.$store.commit("search/setKeyword", null);
      this.$store.commit("search/setSearchGlobal", global);
      // this.$store.commit("search/setSearchMode", false);

      if (q) {
        // this.keyword = q;
        // this.searchMode = true;
        this.$store.commit("search/setKeyword", q);
        // this.$store.commit("search/setSearchGlobal", true);
        this.$store.commit("search/setSearchMode", true);

        this.setSelected(getGroupId(group_id, this.groups));
      } else {
        // this.searchMode = false;
        // this.searchGlobal = true;
        // this.$store.commit("search/setKeyword", null);
        this.$store.commit("search/setSearchGlobal", true);
        this.$store.commit("search/setSearchMode", false);
        grp = this.$route.params.cat_id
          ? this.$route.params.cat_id
          : this.selected_group
          ? this.selected_group.id
          : null;
        this.setSelected(getGroupId(grp, this.groups));
      }
    });
  }
};

function getGroupId(cat_id, groups) {
  let grp = 0;
  if (cat_id) {
    grp = groups.findIndex(g => g.id === Number(cat_id));
    if (grp === -1) {
      let idx = 0;
      for (let i = 0; i < groups.length; i++) {
        const g = checkSubGroups(Number(cat_id), groups[i].sub_groups, 1);
        if (g) return g;
      }
    }
  }
  return groups[grp];
}

function checkSubGroups(cat_id, groups, lvl) {
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].id === cat_id) return groups[i];
    if (groups[i].sub_groups.length > 0) {
      const g = checkSubGroups(cat_id, groups[i].sub_groups, lvl++);
      if (g) return g;
    }
  }
}
</script>

<style lang="sass">
.toggler
  margin-right: 15px
  margin-bottom: 0.5rem
  min-width: 30px

.catalog_title
  display: flex
  align-items: center

.popular
  margin-top: 4px

.divider
  margin: 8px 0 16px 0
  border-bottom: 1px solid #e2e2e2

.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0

.slide-fade-enter-active
  transition: all .3s ease

.slide-fade-leave-active
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(-20px)
  opacity: 0
</style>
