<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th width="100">Статус</th>
        <th width="30">Опл.</th>
        <th>Адрес</th>
        <th width="120">Дата</th>
        <th width="60">Поз.</th>
        <th width="60">Кол-во</th>
        <th width="60">Вес</th>
        <th width="60">Доставка</th>
        <th width="80">Сумма</th>
        <th width="80">Сум. + Д.</th>
        <th width="100">Сум. + НДС</th>
        <th width="100"></th>
      </tr>
    </thead>
    <tbody>
      <OrderRow
        v-for="(doc, idx) in orders"
        :key="doc.id"
        :idx="idx"
        :doc="doc"
        :npp="orders.length - idx"
        />
    </tbody>
    <thead class="thead-light">
      <tr>
        <th colspan="6"></th>
        <th>{{ totals.gttl_pos }}</th>
        <th>{{ totals.gttl_quan }}</th>
        <th>{{ totals.gttl_wgt | toCommas }}</th>
        <th></th>
        <th>{{ totals.gttl_raw | toCommas }}</th>
        <th></th>
        <th>{{ totals.gttl_full | toCommas }}</th>
        <th></th>
      </tr>
    </thead>
  </table>
</template>

<script>
import OrderRow from "./OrderRow";

export default {
  name: "OrdersList",
  components: {
    OrderRow
  },
  props: ["orders", "totals"], // "meta",
  computed: {},
  methods: {}
};
</script>
