<template>
  <b-row v-if="!loading">
    <b-col>
      <PageTitle title="Платежи клиентов" />
      <b-row>
        <b-col md="5">
          <div class="buttons_row space_below">
            <b-button size="sm" variant="primary" @click="showModal(false)">Новый платёж</b-button>
          </div>
          <div class="space_below">
            <SearchBar
              :keyword="keyword"
              :search-this="doSearch"
              :clear-search="clearSearch"
              :change-search="changeSearch"
            />
          </div>
        </b-col>
        <b-col>
          <b-row>
            <b-col md="4">
              <div class="form-group row">
                <label for="start_date" class="dates col-sm-4 col-form-label">Дата, c:</label>
                <div class="col-sm-8">
                  <datepicker
                    v-model="start_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div class="form-group row">
                <label for="end_date" class="dates col-sm-4 col-form-label">Дата, по:</label>
                <div class="col-sm-8">
                  <datepicker
                    v-model="end_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
            <b-col>
              <TriFilter
                title="Провереные?"
                :selected_filter="selected_filter"
                :set-filter="setFilter"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <Paginator
        :page="current_page"
        :pages="page_range"
        :set-page="setPage"
      />
      <Documents
        :documents="payments"
        :totals="totals"
        :del-payment="delPayment"
        :save-payment="savePayment"
        :show-modal="showModal"
      />
      <Paginator
        :page="current_page"
        :pages="page_range"
        :set-page="setPage"
      />
    </b-col>
    <b-modal id="paymentModal" ref="paymentModal" centered no-fade :title="modal_title">
      <div class="my-2">
        <div
          v-if="selected_payment && selected_payment.id === 0"
          class="form-group row"
        >
          <label for="client" class="col-sm-3 col-form-label">
            Клиент:
          </label>
          <div class="col-sm-9">
            <ClientSelector
              :clients="clients"
              :selected_client="client_id"
              :select-client="selectClient"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="pmt_summ" class="col-sm-3 col-form-label">
            Сумма, EUR<span class="text-danger">*</span>:
          </label>
          <div class="col-sm-4">
            <input
              id="pmt_summ"
              v-model="pmt_summ"
              class="form-control form-control-sm"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="pmt_desc" class="col-sm-3 col-form-label">
            Комментарий<span class="text-danger">*</span>:
          </label>
          <div class="col-sm-9">
            <input
              id="pmt_desc"
              v-model="pmt_desc"
              class="form-control form-control-sm"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="pmt_desc" class="col-sm-3 col-form-label">
            Проверен?:
          </label>
          <div class="col-sm-4">
            <b-form-checkbox v-model="is_checked" name="is_checked" />
          </div>
        </div>
      </div>
      <div v-if="error" class="text-center text-danger">
        {{ message }}
      </div>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="danger" @click="closeModal">
          Отменить
        </b-button>
        <b-button size="sm" variant="success" @click="savePaymentModal">
          Сохранить
        </b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import { ISO_Long_To_Short } from "@/services/date_tools";

import ClientSelector from "../custom/ClientSelector";
import Documents from "./Documents";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import Paginator from "@/components/generic/backoffice/Paginator";
import SearchBar from "@/components/generic/backoffice/SearchBar";
import TriFilter from "@/components/generic/backoffice/TriFilter";

export default {
  name: "AdmPayments",
  components: {
    ClientSelector,
    Datepicker,
    Documents,
    PageTitle,
    Paginator,
    SearchBar,
    TriFilter
  },
  data: () => ({
    keyword: null,
    loading: true,
    selected_payment: null,
    pmt_summ: 0,
    pmt_summ_rub: 0,
    pmt_desc: "",
    is_checked: false,
    error: false,
    message: "",
    client_id: null,
    ru: ru,
    format: "dd-MM-yyyy",
    start_date: null,
    end_date: null
  }),
  computed: {
    clients() {
      return this.$store.getters["finances/clients"];
    },
    payments() {
      return this.$store.getters["finances/payments"];
    },
    totals() {
      return this.$store.getters["finances/totals"];
    },
    modal_title() {
      return this.selected_payment ? "Редактирование платежа" : "Новый платёж";
    },
    current_page() {
      return this.$store.getters["finances/current_page"];
    },
    page_range() {
      return this.$store.getters["finances/page_range"];
    },
    selected_filter() {
      return this.$store.getters["finances/selected_filter"];
    }
  },
  watch: {
    pmt_summ(newVal) {
      let pmt_summ = newVal;
      if (
        (typeof newVal === "string" || newVal instanceof String) &&
        newVal.indexOf(",") >= 0
      ) {
        pmt_summ = newVal.replace(",", ".");
      }
      this.pmt_summ = pmt_summ;
    },
    pmt_desc(newVal) {
      this.pmt_desc = newVal;
    }
  },
  created() {
    this.loading = true;
    this.$store.dispatch("finances/getClients");
    this.$store
      .dispatch("finances/getPayments", {
        is_checked: 0
      })
      .then(() => {
        this.loading = false;
        this.resetFormData();
      });
  },
  methods: {
    setFilter: function(is_checked) {
      this.getPayments({is_checked});
    },
    getPayments: function({is_checked, page}) {
      const kw = this.keyword ? { keyword: this.keyword } : {};
      const start_date = this.start_date ? { start_date: ISO_Long_To_Short(new Date(this.start_date).toISOString()) } : {};
      const end_date = this.end_date ? { end_date: ISO_Long_To_Short(new Date(this.end_date).toISOString()) } : {};
      this.loading = true;
      this.$store.dispatch("finances/getPayments", {
        ...kw,
        ...start_date,
        ...end_date,
        page,
        is_checked: is_checked !== undefined ? is_checked : this.selected_filter
      }).then(() => this.loading = false);
    },
    selectClient: function(client_id) {
      this.client_id = client_id;
    },
    delPayment: function(doc_id) {
      this.$store.dispatch("finances/delPayment", { doc_id });
    },
    savePayment: function(payload) {
      this.$store.dispatch("finances/savePayment", payload);
    },
    savePaymentModal: function() {
      this.error = true;
      if (this.selected_payment.id === 0 && this.client_id === null) {
        this.message = "Не выбран клиент.";
      } else if (isNaN(this.pmt_summ) || this.pmt_summ <= 0) {
        this.message = "Неправильная сумма.";
      } else if (this.pmt_desc === "") {
        this.message = "Нет комментария";
      } else {
        if (this.selected_payment) {
          let payload = {
            id: this.selected_payment.id,
            pmt_summ: this.pmt_summ,
            pmt_desc: this.pmt_desc,
            is_checked: this.is_checked
          };
          if (this.selected_payment.id === 0) {
            payload.client_id = this.client_id;
          }
          this.$store.dispatch("finances/savePayment", payload);
          this.closeModal();
        }
      }
    },
    resetFormData() {
      this.selected_payment = null;
      this.pmt_summ = 0;
      this.pmt_summ_rub = 0;
      this.pmt_desc = "";
      this.is_checked = false;
      this.error = false;
      this.message = "";
    },
    closeModal() {
      this.resetFormData();
      this.$refs.paymentModal.hide();
    },
    showModal(is_edit, record) {
      this.resetFormData();
      if (is_edit) {
        this.selected_payment = record;
        this.pmt_summ = record.pmt_summ;
        this.pmt_summ_rub = record.pmt_summ_rub;
        this.pmt_desc = record.pmt_desc;
        this.is_checked = record.is_checked;
      } else {
        this.selected_payment = { id: 0 };
      }
      this.$refs.paymentModal.show();
    },
    // search
    changeSearch(keyword) {
      this.keyword = keyword;
    },
    doSearch() {
      this.getPayments(0);
    },
    clearSearch: function() {
      this.keyword = null;
      this.getPayments(0);
    },
    setPage(page) {
      this.$store.commit("finances/setPage", { page });
      this.getPayments({ is_checked: this.selected_filter, page });
    }
  }
};
</script>

<style lang="sass">
.dates
  text-align: right
</style>