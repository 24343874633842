<template>
  <tr>
    <td>
      <b-form-checkbox
        v-if="!doc.asm_list_id && status === 2"
        @change="selectOrder"
        :value="doc.id"
        :unchecked-value="uc_val"
      />
      <b-form-checkbox
        v-if="status === 1"
        @change="selectOrder"
        :value="doc.id"
        :unchecked-value="uc_val"
      />
    </td>
    <td>{{ npp }}</td>
    <td>
      <strong>
        <router-link :to="document_link">{{ doc.id }}</router-link>
      </strong><br/>
      <small>{{ doc.ext_doc_number }}</small>
    </td>
    <td>
      <router-link :to="asm_link">{{ doc.asm_list_id }}</router-link>
    </td>
    <td>
      {{ doc.user.profile.full_name }} <br />
      <small>{{ doc.user.email }}</small>
    </td>
    <td>
      <div v-if="doc.status">
        <b-badge :variant="doc.status.st_color">{{ doc.status.st_name }}</b-badge>
      </div>
      <small>{{ doc.created_at }}</small>
    </td>
    <td>
      <span v-html="$options.filters.toYesNo(doc.is_paid)"></span>
      <div v-if="doc.is_paid">
        <small>{{ doc.expense.exp_summ }} EUR</small>
      </div>
      <div v-if="!doc.is_paid && doc.payment_note">
        <small>Отмен.</small>
      </div>
    </td>
    <td>
      <AddressCard :address="doc.address" v-if="doc.address" />
    </td>
    <td>{{ doc.note }}</td>
    <td>{{ doc.shops }}</td>
    <td class="want">{{ doc.ttl_pos }}</td>
    <td class="want">{{ doc.ttl_quan }}</td>
    <td class="want">{{ doc.ttl_wgt | toCommas }}</td>
    <td class="want">{{ doc.raw_sum | toCommas }}</td>

    <td :class="order_fulfillment"></td>
    <td class="fact">{{ doc.ttl_pos_fct }}</td>
    <td class="fact">{{ doc.ttl_quan_fct }}</td>
    <td class="fact">{{ doc.ttl_wgt_fct | toCommas }}</td>
    <td class="fact">{{ doc.raw_sum_fct | toCommas }}</td>
    <td>{{ doc.dlv_price | toCommas }}</td>
    <td class="fact"><strong>{{ delivery | toCommas }}</strong></td>
    <!-- <td class="fact">{{ doc.full_sum_fct | toCommas }}</td> -->

    <td>
      <router-link class="btn btn-sm sm-override btn-info" :to="document_link">
        <i class="far fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import AddressCard from "@/components/user/addresses/AddressCard";


export default {
  name: "AdmOrderRow",
  components: { AddressCard },
  props: ["doc", "npp", "selectOrder", "query_string", "status"], //, "meta"
  computed: {
    delivery() {
      return Number(this.doc.dlv_price) + Number(this.doc.raw_sum_fct);
    },
    uc_val() {
      return `-${this.doc.id}`;
    },
    order_fulfillment() {
      if (this.doc.ttl_quan_fct > 0 && this.doc.ttl_quan_fct === this.doc.ttl_quan) return "filled";
      if (this.doc.ttl_quan_fct > 0 && this.doc.ttl_quan_fct !== this.doc.ttl_quan) return "partial";
      if (this.doc.ttl_quan_fct === 0) return "empty";
      return "";
    },
    document_link() {
      return `/a/order/${this.doc.id}?${this.query_string}`;
    },
    asm_link() {
      return `/a/asm_list/${this.doc.asm_list_id}`;
    }
  },
  methods: {}
};
</script>

<style lang="sass">
</style>
