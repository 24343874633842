<template>
  <b-row v-if="!loading">
    <b-col md="7">
      <PageTitle title="Балансы клиентов" />
      <b-row>
        <b-col>
          <SearchBar
            :keyword="keyword"
            :search-this="doSearch"
            :clear-search="clearSearch"
            :change-search="changeSearch"
          />
        </b-col>
        <b-col>
        </b-col>
        <b-col>
          <TriFilter
            title="Должники?"
            :selected_filter="selected_filter"
            :set-filter="getFilteredBalances"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        </b-col>
        <b-col>
        </b-col>
        <b-col>
          <TriFilter
            title="Ожидают?"
            :selected_filter="selected_aw_filter"
            :set-filter="getFilteredAwBalances"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Paginator
            :page="current_page"
            :pages="page_range"
            :set-page="setPage"
            wide="true"
          />
        </b-col>
      </b-row>
      <Documents
        :balances="balances"
      />
      <b-row>
        <b-col>
          <Paginator
            :page="current_page"
            :pages="page_range"
            :set-page="setPage"
            wide="true"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Documents from "./Documents";

import PageTitle from "@/components/generic/backoffice/PageTitle";
import Paginator from "@/components/generic/backoffice/Paginator";
import SearchBar from "@/components/generic/backoffice/SearchBar";
import TriFilter from "@/components/generic/backoffice/TriFilter";

export default {
  name: "Balances",
  components: {
    Documents,
    PageTitle,
    Paginator,
    SearchBar,
    TriFilter
  },
  data: () => ({
    keyword: null,
    loading: true,
  }),
  computed: {
    balances() {
      return this.$store.getters["balances/balances"];
    },
    current_page() {
      return this.$store.getters["balances/current_page"];
    },
    page_range() {
      return this.$store.getters["balances/page_range"];
    },
    selected_filter() {
      return this.$store.getters["balances/selected_filter"];
    },
    selected_aw_filter() {
      return this.$store.getters["balances/selected_aw_filter"];
    }
  },
  created() {
    this.$store
      .dispatch("balances/getBalances", { is_debtor: 0 })
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
    getFilteredBalances(is_debtor) {
      this.getBalances({ is_debtor, mode: 'd' });
    },
    getFilteredAwBalances(is_awaiting) {
      this.getBalances({ is_awaiting, mode: 'a' });
    },
    getBalances: function(payload) {
      const kw = this.keyword ? { keyword: this.keyword } : {};
      this.$store.dispatch("balances/getBalances", {
        ...payload,
        ...kw
      });
    },
    // search
    changeSearch(keyword) {
      this.keyword = keyword;
    },
    doSearch() {
      this.getBalances();
    },
    clearSearch: function() {
      this.keyword = null;
      this.getBalances();
    },
    setPage(page) {
      this.$store.commit("balances/setPage", page);
      this.getBalances({ page });
    }
  }
};
</script>
