<template>
  <div class="address_wrapper">
    <div class="npp">{{ npp }}.</div>
    <div class="address_card" @click="!address.is_default ? setDefault(address.id) : skipClick()">
      <div class="row1">
        <div>
          <div>{{ address.city }}</div>
          <div>{{ address.postal_code }}, {{ address.address }}</div>
        </div>
        <div class="is_default" v-if="address.is_default">
          <i class="fas fa-check"></i>
        </div>
      </div>
    </div>
    <div class="controls">
      <b-button size="sm" class="sm-override" variant="primary"><i class="fas fa-pen"></i></b-button>
      <b-button v-if="!address.is_default" size="sm" class="sm-override" variant="danger" @click="delAddress(address.id)"><i class="fas fa-trash-alt"></i></b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Address",
  props: ["address", "npp", "setDefault", "delAddress"],
  methods: {
    skipClick() {}
  }
};
</script>

<style lang="sass" scoped>
  .address_wrapper
    padding: 5px
    margin-bottom: 10px
    display: flex
    align-items: center
    .controls
      display: flex
      flex-direction: column
      justify-content: space-between
      button
        margin-bottom: 4px
        margin-left: 10px
  .address_card
    width: 100%
    padding: 5px
    border-bottom: 1px solid #e2e2e2
    &:hover
      cursor: pointer
      background-color: #d5d5d5
      border-bottom: 1px solid #a1a1a1
    .row1
      display: flex
      justify-content: space-between
      align-items: center

  .npp
    margin-right: 10px
    font-weight: 600
    font-size: 1.1em
</style>
