<template>
  <div class="address_wrapper">
    <div class="row1">
      <div v-if="title"><strong>{{ title }}</strong></div>
      <div>{{ address.city }}</div>
      <div>{{ address.postal_code }}, {{ address.address }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressCard",
  props: ["address", "title"],
  methods: {
    skipClick() {}
  }
};
</script>

<style lang="sass" scoped>
.address_wrapper
  padding: 5px
  display: flex
  align-items: center
</style>
