<template>
  <div class="scrollable_panel mt-3">
    <div class="title">
      <h4>Адреса доставки</h4>
      <b-button size="sm" variant="success"><i class="fas fa-plus"></i></b-button>
    </div>
    <div class="list">
      <Address
        v-for="(address, idx) in addresses"
        :address="address"
        :npp="addresses.length - idx"
        :key="address.id"
        :setDefault="setDefault"
        :delAddress="delAddress"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Address from "./Address";

export default {
  name: "AddressList",
  components: {
    Address
  },
  computed: {
    ...mapGetters({
      addresses: "addresses/get_addresses"
    })
  },
  methods: {
    setDefault(addr_id) {
      this.$store.dispatch("addresses/updateAddress", {
        addr_id,
        is_default: true
      });
    },
    delAddress(addr_id) {
      this.$store.dispatch("addresses/delAddress", {
        addr_id
      });
    }
  }
};
</script>

<style lang="sass">
.title
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid #e2e2e2
  padding-bottom: 5px
  margin-bottom: 10px

  button
    margin-right: 3px
</style>
