<template>
  <tr>
    <td>{{ npp }}</td>
    <td>
      <img v-show="prod.file_url" :src="prod.file_url" class="img-preview" width="140" />
    </td>
    <td>{{ prod.shop_name }}</td>
    <td>{{ prod.prod_name }} <a :href="prod.prod_link"><i class="fas fa-external-link-alt"></i></a><br />
      <small>Артикул: {{ prod.mnf_code }}</small>
    </td>
    <td>{{ prod.note }}</td>
    <td>{{ prod.weight }}</td>
    <td>
      <b-form-spinbutton
        v-model="quan"
        @change="changeValue"
        min="1"
        max="100"
      />
    </td>
    <td>{{ row_weight }}</td>
    <td>{{ prod.price }}</td>
    <!-- <td>{{ total }}</td> -->
    <td>{{ vat_rate }}</td>
    <!-- <td>{{ total_vat }}</td> -->
    <td>{{ prod.price_with_vat}}</td>
    <td>{{ total_with_vat }}</td>
    <td>
      <button
        class="btn btn-sm sm-override btn-danger"
        @click="delProduct(prod.prod_id, prod.image_id)"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "ProductLinkRow",
  props: ["npp", "prod", "delProduct", "changeQuan"],
  data: () => ({
    quan: 0
  }),
  computed: {
    row_weight() {
      const w = this.prod.weight.replace(",", ".");
      return Number(this.prod.quan) > 0 && Number(w) > 0
        ? (Number(this.prod.quan) * Number(w)).toFixed(2)
        : "0.0";
    },
    vat_rate() {
      return this.prod.vat_rate ? `${this.prod.vat_rate}%` : "н/д";
    },
    total_vat() {
      return (this.prod.quan * this.prod.vat).toFixed(2);
    },
    total() {
      return (this.prod.quan * this.prod.price).toFixed(2);
    },
    total_with_vat() {
      return (this.prod.quan * this.prod.price_with_vat).toFixed(2);
    }
  },
  created() {
    this.quan = Number(this.prod.quan);
  },
  methods: {
    changeValue(val) {
      this.change(val, this);
    },
    change: debounce((val, vm) => {
      vm.changeQuan(vm.prod.prod_id, val);
    }, 350)
  }
};
</script>

<style lang="sass">

</style>
