<template>
  <b-row>
    <b-col md="6" v-if="!isLoggedIn">
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="first_name">Имя<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="first_name" :state="checkValid('first_name')" size="sm" v-model="first_name" />
          <b-form-invalid-feedback id="first_name">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="last_name">Фамилия<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="last_name" :state="checkValid('last_name')" size="sm" v-model="last_name" />
          <b-form-invalid-feedback id="last_name">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="phone">Телефон<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="phone" :state="checkValid('phone')" size="sm" v-model="phone" />
          <b-form-invalid-feedback id="phone">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="city">Город<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="city" :state="checkValid('city')" size="sm" v-model="city" />
          <b-form-invalid-feedback id="city">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="postal_code">Индекс<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="postal_code" :state="checkValid('postal_code')" size="sm" v-model="postal_code" />
          <b-form-invalid-feedback id="postal_code">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="address">Адрес<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="address" :state="checkValid('address')" size="sm" v-model="address" />
          <b-form-invalid-feedback id="address">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="email">Эл. почта<span class="mndt">*</span>:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="email" :state="checkValid('email')" size="sm" v-model="email" />
          <b-form-invalid-feedback id="email">
            Поле не может быть пустым
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </b-col>
    <b-col>
      <b-row class="mb-2">
        <b-col sm="4">
          <label for="note">Комментарий:</label>
        </b-col>
        <b-col sm="8">
          <b-textarea
            id="note"
            size="sm"
            rows="4"
            max-rows="6"
            v-model="note"
          ></b-textarea>
        </b-col>
      </b-row>
      <div class="text-right">
        <small>
          Оставьте свои контактные данные и мы свяжемся с Вами.
        </small>
        <br />
        <b-button
          :disabled="isDisabled"
          variant="primary"
          @click="confirm"
        >
          Отправить заказ
        </b-button>
      </div>
    </b-col>
  </b-row>

</template>

<script>
export default {
  name: "Registration",
  components: {},
  props: ["isLoggedIn", "isDisabled", "sendOrder"],
  data: () => ({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    city: "",
    postal_code: "",
    address: "",
    note: "",
    validation_errors: []
  }),
  computed: {},
  watch: {
    email(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("email");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "email");
      }
    },
    first_name(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("first_name");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "first_name");
      }
    },
    last_name(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("last_name");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "last_name");
      }
    },
    phone(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("phone");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "phone");
      }
    },
    city(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("city");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "city");
      }
    },
    postal_code(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("postal_code");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "postal_code");
      }
    },
    address(newVal, oldVal) {
      if (newVal === "") {
        this.validation_errors.push("address");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "address");
      }
    },
  },
  created() {},
  methods: {
    checkValid(key) {
      if (this.validation_errors.length === 0) return null;
      if (this.validation_errors.includes(key)) return false;
      if (!(this.validation_errors.includes(key))) return true;
    },
    validate(payload) {
      let not_empty = true;
      let errors = [];
      const keys = Object.keys(payload).filter(k => k !== "note");
      keys.map(k => {
        if (payload[k] === "") {
          errors.push(k);
          not_empty = false;
        }
      });
      this.validation_errors = [...errors];
      return not_empty;
    },
    confirm() {
      if (this.isLoggedIn) {
        this.sendOrder(true, { note: this.note });
      } else {
        const payload = {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          city: this.city,
          postal_code: this.postal_code,
          address: this.address,
          note: this.note
        };

        const isValid = this.validate(payload);
        if (isValid) {
          this.sendOrder(true, payload);
        }
      }


    }
  }
};
</script>
