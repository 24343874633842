import { uuid } from 'vue-uuid';
import Api from "@/services/general";

// initial state
const state = {
  cart: []
};

const getters = {
  get_ids: state => state.cart.map(p => p.id),
  get_cart: state => state.cart,
  get_total_quan: state =>
    state.cart.reduce((acc, prod) => acc + Number(prod.quan), 0),
  get_total_weight: state =>
    state.cart.reduce((acc, prod) => {
      if (Number(prod.quan) > 0 && Number(prod.weight)) {
        return acc + Number(prod.quan) * Number(prod.weight);
      }
      return acc;
    }, 0),
  get_total_sum: state =>
    state.cart.reduce(
      (acc, prod) => acc + Number(prod.quan) * Number(get_actual_price(prod)),
      0
    )
  // get_total_sum_with_vat: state =>
  //   state.cart.reduce(
  //     (acc, prod) => acc + Number(prod.quan) * Number(prod.price),
  //     0
  //   )
};

const actions = {
  async validateCart(ctx, ids) {
    const res = await Api.setData("u/validate_cart", null, {
      ids
    });
    this.commit("cart/setValidProducts", res.data.ids);
    return res.data.message ? res.data.message.type : "";
  },
  async saveCart(ctx, payload) {
    const { user_id } = payload;
    if (!user_id) {
      localStorage.setItem("cart_prod", JSON.stringify(ctx.state.cart));
      // localStorage.setItem("cart_link");
      return "OK";
    }

    const res = await Api.setData("u/save_cart", null, {
      ...payload,
      dest: 'prod',
      products: JSON.stringify(ctx.state.cart)
    });
    return res.data.status;
  },
  async sendOrder(ctx, payload) {
    const res = await Api.setData("create_order", null, {
      ...payload,
      products: ctx.state.cart,
      products_links: []
    });
    if (res.data.message.type === "success") {
      this.commit("cart/setProducts", []);
    }
    return { status: res.data.message.type, order_id: res.data.order_id };
  },
  async sendLinkOrder(ctx, payload) {
    const res = await Api.setData("create_order", null, {
      ...payload,
      products: [],
      products_links: ctx.rootState.products_links.cart
    });
    if (res.data.message.type === "success") {
      this.commit("products_links/setProducts", []);
    }
    return { status: res.data.message.type, order_id: res.data.order_id };
  },
  changeQuan(ctx, payload) {
    this.commit("cart/changeQuan", payload);
  },
  delProduct(ctx, {product_id}) {
    this.commit("cart/delProduct", product_id);
  },
  addProduct(ctx, payload) {
    this.commit("cart/addProduct", payload);
  }
};

const mutations = {
  setValidProducts(state, data) {
    state.cart = state.cart.map(p => ({
      ...p,
      is_valid: data.includes(p.id)
    }));
  },
  setProducts(state, data) {
    state.cart = data;
  },
  addProduct(state, data) {
    const cart = state.cart; //.filter(prod => prod.id !== data.id);
    state.cart = [{ ...data, total: get_actual_price(data) }, ...cart];
    this.commit("app/setMessage", {
      text: `Товар был добавлен в корзину, ${data.quan} шт.`,
      type: "info"
    });
  },
  changeQuan(state, { product_id, quan }) {
    state.cart = state.cart.map(prod => {
      if (prod.id === product_id) {
        return {
          ...prod,
          quan,
          total: (Number(quan) * Number(get_actual_price(prod))).toFixed(2)
        };
      }
      return prod;
    });
  },
  delProduct(state, product_id) {
    state.cart = state.cart.filter(prod => prod.id !== product_id);
  }
};

export function get_actual_price(prod) {
  const price =
    prod.discount_price &&
    Number(prod.discount_price) > 0 &&
    prod.discount_price !== prod.price
      ? prod.discount_price
      : prod.price;
  return price;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
