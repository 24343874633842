<template>
  <table class="table table-sm table-hover table-bordered table-striped data-grid">
    <thead class="thead-dark">
      <tr>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th>Название</th>
        <th width="30">Акт?</th>

        <th width="36"></th>
      </tr>
    </thead>
    <tbody>
      <SlideRow
        v-for="(slide, idx) in slides"
        :key="slide.id"
        :idx="idx"
        :slide="slide"
        :npp="slides.length - idx"
        :setEdit="setEdit"
        />
    </tbody>
  </table>
</template>

<script>
import SlideRow from "./SlideRow";

export default {
  name: "SlidesList",
  components: {
    SlideRow
  },
  props: ["slides", "setEdit"],
  computed: {},
  methods: {}
};
</script>
