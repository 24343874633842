<template>
  <b-row>
    <b-col>
      <PageTitle title="Доставка, тарифы" />
      <b-row>
        <b-col>
          <AdmRatesList
            :rates="rates"
            :delRate="delRate"
            :setEdit="setEdit"
            />
        </b-col>
        <b-col>
          <RateForm
            :active_rate="active_rate"
            :resetActive="resetActive"
            />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import AdmRatesList from "./AdmRatesList";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import RateForm from "./RateForm";

export default {
  name: "AdmDelivery",
  components: {
    AdmRatesList,
    PageTitle,
    RateForm
  },
  data: () => ({
    active_rate: {}
  }),
  computed: {
    ...mapGetters({
      rates: "delivery/get_rates"
    })
  },
  watch: {},
  created() {
    this.getRates();
  },
  methods: {
    delRate(id) {
    this.$store.dispatch("delivery/delRate", {id});
    },
    resetActive() {
      this.active_rate = {};
    },
    setEdit(rate) {
      this.active_rate = rate;
    },
    getRates: function(payload) {
      this.$store.dispatch("delivery/getRates", {});
    }
  }
};
</script>

<style lang="sass">

</style>
