<template>
  <tr>
    <td>{{ npp }}</td>
    <td>
      <strong><router-link :to="document_link">{{ doc.id }}</router-link></strong>
    </td>
    <td>
      <div v-if="doc.status">
        <b-badge :variant="doc.status.st_color">{{ doc.status.st_name }}</b-badge>
      </div>
    </td>
    <td>
      <span v-html="$options.filters.toYesNo(doc.is_paid)"></span>
    </td>
    <td>
      <AddressCard :address="doc.address" v-if="doc.address" />
    </td>
    <td>{{ doc.created_at }}</td>
    <td>{{ doc.ttl_pos }}</td>
    <td>{{ doc.ttl_quan }}</td>
    <td>{{ doc.ttl_wgt | toCommas }}</td>
    <td>{{ doc.dlv_price | toCommas }}</td>
    <td>{{ doc.raw_sum | toCommas }}</td>
    <td>{{ total }}</td>
    <td>{{ doc.full_sum | toCommas }}</td>

    <td>
      <router-link class="btn btn-sm sm-override btn-info" :to="document_link">
        <i class="far fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import AddressCard from "@/components/user/addresses/AddressCard";


export default {
  name: "OrderRow",
  components: { AddressCard },
  props: ["doc", "npp"], //, "meta"
  computed: {
    total() {
      return (Number(this.doc.raw_sum) + Number(this.doc.dlv_price)).toFixed(2)
    },
    document_link() {
      return `/u/order/${this.doc.id}`;
    }
  },
  methods: {}
};
</script>

<style lang="sass">
</style>
