<template>
  <b-modal ref="paytrail_modal" centered no-fade :title="title">
    <div>
      <div class="text-center">
        <div v-if="pd && pd.is_dupe">
          <b-alert variant="danger" :show="pd && pd.is_dupe">
            <h3>Внимание!</h3><br />
            Заказ уже был оплачен. Обновите страницу!
          </b-alert>
        </div>

        Сумма к оплате
        <h2 v-if="raw_sum_fct > 0">{{ delivery }} EUR</h2>
        <h2 v-else>{{ delivery_raw }} EUR</h2>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        size="sm"
        variant="outline-danger"
        @click="closePaytrailModal">
          Отмена
      </b-button>
      <div v-if="pd && pd.pt_data">
        <form action="https://payment.paytrail.com/e2" method="post">
          <input name="AUTHCODE" type="hidden" :value="pd.hash_string">
          <input name="MERCHANT_ID" type="hidden" :value="pd.pt_data.MERCHANT_ID">
          <input name="URL_SUCCESS" type="hidden" :value="pd.pt_data.URL_SUCCESS">
          <input name="URL_CANCEL" type="hidden" :value="pd.pt_data.URL_CANCEL">

          <input name="ORDER_NUMBER" type="hidden" :value="pd.pt_data.ORDER_NUMBER">
          <input name="PAYMENT_METHODS" type="hidden" :value="pd.pt_data.PAYMENT_METHODS">

          <input name="CURRENCY" type="hidden" :value="pd.pt_data.CURRENCY">
          <input name="REFERENCE_NUMBER" type="hidden" :value="pd.pt_data.REFERENCE_NUMBER">
          <input name="PAYER_PERSON_LASTNAME" type="hidden" :value="pd.pt_data.PAYER_PERSON_LASTNAME">
          <input name="PAYER_PERSON_FIRSTNAME" type="hidden" :value="pd.pt_data.PAYER_PERSON_FIRSTNAME">
          <input name="PAYER_PERSON_EMAIL" type="hidden" :value="pd.pt_data.PAYER_PERSON_EMAIL">
          <input name="PAYER_PERSON_ADDR_STREET" type="hidden" :value="pd.pt_data.PAYER_PERSON_ADDR_STREET">
          <input name="PAYER_PERSON_ADDR_POSTAL_CODE" type="hidden" :value="pd.pt_data.PAYER_PERSON_ADDR_POSTAL_CODE">
          <input name="PAYER_PERSON_ADDR_TOWN" type="hidden" :value="pd.pt_data.PAYER_PERSON_ADDR_TOWN">
          <!-- <input name="MSG_UI_MERCHANT_PANEL" type="hidden" :value="pd.pt_data.MSG_UI_MERCHANT_PANEL"> -->

          <input name="LOCALE" type="hidden" :value="pd.pt_data.LOCALE">
          <input name="PARAMS_IN" type="hidden" :value="pd.pt_data.PARAMS_IN">
          <input name="PARAMS_OUT" type="hidden" :value="pd.pt_data.PARAMS_OUT">
          <input name="AMOUNT" type="hidden" :value="pd.pt_data.AMOUNT">
          <b-button variant="primary" :disabled="!is_data_loaded" v-if="!is_data_loaded">
            <b-spinner small type="grow"></b-spinner>
            Оплатить заказ
          </b-button>
          <input v-else class="btn btn-primary btn-small" type="submit" value="Оплатить заказ">
        </form>
      </div>
    </template>
  </b-modal>

</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PaytrailModal",
  props: [
    "title",
    "delivery",
    "delivery_raw",
    "closePaytrailModal",
    "mode",
    "order_id",
    "raw_sum",
    "raw_sum_fct"
  ],
  data: () => ({
    is_data_loaded: false
  }),
  components: {},
  computed: {
    ...mapGetters({
      pd: "finances/payment_data"
    })
  },
  watch: {
    order_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrderData();
      }
    },
    raw_sum_fct(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrderData();
      }
    },
    raw_sum(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrderData();
      }
    }
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.getOrderData();
    });
  },
  methods: {
    getOrderData() {
      this.is_data_loaded = false;
      this.$store.dispatch("finances/payOrder", {
        order_id: this.order_id,
        return_url: `${this.mode}/order/${this.order_id}`
      }).then(() => {
        this.is_data_loaded = true;
      });
    }
  }
};
</script>
