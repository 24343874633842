import Api from "@/services/general";

const state = {
  adm: [],
  cart: []
};

const getters = {
  adm_shops: state => state.adm,
  cart_shops: state => state.cart
};

const actions = {
  async createShop(ctx, payload) {
    const pl = { ...payload };
    delete pl.origin;
    let res = null;
    if (payload.id !== null) {
      delete pl.id;
      res = await Api.setData("a/shop", payload.id, pl);
    } else {
      res = await Api.setData("a/shop", null, pl);
    }
    this.commit("shops/updateShops", {
      data: res.data.shop,
      origin: payload.origin
    });
  },
  async getShops(ctx, payload) {
    const url = `${payload.mode}/shops`;
    const res = await Api.getData(url);
    this.commit("shops/setShops", { data: res.data, origin: payload.origin });
    return res.data.find(s => s.is_default);
  }
};

const mutations = {
  setShops(state, payload) {
    state[payload.origin] = payload.data;
  },
  updateShops(state, payload) {
    const shops = state[payload.origin].filter(
      shop => shop.id !== payload.data.id
    );
    state[payload.origin] = [payload.data, ...shops];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
