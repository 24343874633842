<template>
  <div class="remains_selector">
    <v-select
      :value="selected"
      label="prod_name"
      :filterable="false"
      :options="options"
      @input="setSelected"
      @search="onSearch"
    >
      <template v-slot:option="option">
        <div class="selector_option">
          {{ option.prod_name }}<br />
          <small v-if="option.group">{{ option.group.group_name }}</small>
          &nbsp;<small v-if="option.ean">{{ option.ean }}</small>
        </div>
      </template>
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          Ничего не нашли для <em>{{ search }}</em>.
        </template>
        <em style="opacity: 0.5;" v-else>Ищем с 4х символов. Название/Артикул/EAN</em>
      </template>

    </v-select>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "ExtSearch",
  components: {},
  props: ["selected", "setSelected", "options"],
  data: () => ({
    opts: []
  }),
  methods: {
    onSearch(keyword, loading) {
      if (keyword.length >= 4) {
        loading(true);
        this.search(loading, { keyword }, this);
      }
    },
    search: debounce((loading, search, vm) => {
      vm.$store.dispatch("products/searchProducts", {
        keyword: escape(search.keyword),
        loading
      })
    }, 350)
  },
  watch: {}
};
</script>
