import Api from '@/services/general';

// initial state
const state = {
  groups: [],
  top_groups: [],
  all_groups: [],
  selected: null
};

const getters = {
  get_top_groups: state => state.top_groups,
  get_groups: state => state.groups,
  get_all_groups: state => state.all_groups,
  get_selected: state => state.selected
};

const actions = {
  async saveGroup(ctx, payload) {
    const res = await Api.setData("a/group", payload.id, payload);
    this.commit("groups/updateGroup", res.data);
  },
  async getTopGroups() {
    const res = await Api.getData("top_groups", null);
    this.commit("groups/setTopGroups", res.data);
  },
  async getGroups() {
    const res = await Api.getData("groups", null);
    this.commit("groups/setGroups", res.data);
  },
  async getAllGroups(ctx, payload) {
    const res = await Api.getData("a/groups", null, payload);
    this.commit("groups/setAllGroups", res.data);
  }
};

const mutations = {
  updateGroup(state, data) {
    const groups = state.all_groups.map(group =>
      group.id === data.id ? data : group
    );
    state.all_groups = [...groups];
  },
  setGroups(state, data) {
    state.groups = data;
  },
  setTopGroups(state, data) {
    state.top_groups = data;
  },
  setAllGroups(state, data) {
    const groups = data.reduce((acc, val) => {
      acc.push(val);
      if (val.sub_groups.length > 0) {
        val.sub_groups.map(g => acc.push({...g, is_sub: true}));
      }
      return acc;
    }, []);
    state.all_groups = groups;
  },
  setSelected(state, group) {
    state.selected = group;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
