<template>
  <div>
    <b-toast
      visible
      solid
      auto-hide="3000"
      :variant="variant.variant"
      @hidden="closeMessage(message.id)"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">{{ variant.title }}</strong>
      </div>
      <span v-html="message.text" />
    </b-toast>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: ["message", "closeMessage"],
  computed: {
    variant() {
      switch (this.message.type) {
        case "success": {
          return { title: "Ура!", variant: "success" };
        }
        case "info": {
          return { title: "Иформация", variant: "info" };
        }
        case "warning": {
          return { title: "Внимание!", variant: "warning" };
        }
        case "error": {
          return {
            title: this.message.title || "Ну что ж такое, ошибка!",
            variant: "danger"
          };
        }
        default: {
          return {};
          break;
        }
      }
    }
  }
};
</script>
