<template>
  <div class="n-table data-grid space_below">
    <div class="table-row">
      <div class="table-head w40">нпп</div>
      <div class="table-head w50">От</div>
      <div class="table-head w50">До</div>
      <div class="table-head w50">Цена</div>
      <div class="table-head">Комментарий</div>
      <div class="table-head w60"></div>
    </div>
    <AdmRateRow
      v-for="(rate, idx) in rates"
      :key="rate.id"
      :idx="idx"
      :rate="rate"
      :npp="rates.length - idx"
      :delRate="delRate"
      :setEdit="setEdit"
      />
  </div>
</template>

<script>
import AdmRateRow from "./AdmRateRow";

export default {
  name: "AdmRatesList",
  components: {
    AdmRateRow
  },
  props: ["rates", "delRate", "setEdit"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
</style>
