<template>
  <tr>
    <td class="cell-cont" colspan="15">
      <table class="table table-sm table-hover table-bordered table-striped data-grid">
        <tr>
          <td colspan="17"><h5>{{ npp }}. {{ shop }}</h5></td>
        </tr>
        <ProductRow
          key_name="product_link"
          v-for="(prod, idx) in positions"
          :key="prod.id"
          :idx="idx"
          :prod="prod"
          :npp="positions.length - idx"
        />
        <tr class="group_total">
          <td colspan="8"><strong>Всего по магазину:</strong></td>
          <td><strong>{{ totals.quan }}</strong></td>
          <td></td>
          <td></td>
          <td><strong>{{ totals.weight | toCommas }}</strong></td>
          <td></td>
          <td><strong>{{ totals.ttl_raw | toCommas }}</strong></td>
          <td></td>
          <td><strong>{{ totals.ttl_full | toCommas }}</strong></td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import ProductRow from "./ProductRow";

export default {
  name: "ShopRow",
  components: {
    ProductRow
  },
  props: ["npp", "shop", "totals", "positions"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
.table-sm td.cell-cont
  padding: 0
td
  h5
    margin: 0
</style>
