<template>
  <div class="table-row">
    <div class="table-cell">{{ npp }}</div>
    <div class="table-cell">
      {{ rate.dlv_min }}
    </div>
    <div class="table-cell">
      {{ rate.dlv_max }}
    </div>
    <div class="table-cell">
      {{ rate.dlv_price }}
    </div>
    <div class="table-cell">
      {{ rate.dlv_note }}
    </div>
    <div class="table-cell">
      <div class="buttons">
        <button
          class="btn btn-sm sm-override btn-info"
          @click="setEdit(rate)"
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          class="btn btn-sm sm-override btn-danger"
          @click="delRate(rate.id)"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdmGroupRow",
  props: ["rate", "npp", "delRate", "setEdit"],
  data: () => ({

  }),
  computed: {

  },
  methods: {

  }
};
</script>

<style lang="sass">
  .desc_box
    display: flex
    justify-content: space-between
    div:nth-child(2)
      padding: 0 8px
      width: 100%
    // .buttons
    //   display: flex
    //   flex-direction: column
    //   button
    //     margin-bottom: 8px

  .buttons
    display: flex
    justify-content: space-between

</style>
