<template>
  <b-row v-if="!loading">
    <b-col>
      <PageTitle title="Мои заказы" />
      <b-row>
        <b-col>
          <SearchBar
            :keyword="keyword"
            :search-this="doSearch"
            :clear-search="clearSearch"
            :change-search="changeSearch"
          />
        </b-col>
        <b-col></b-col>
        <b-col>
          <b-row>
            <b-col>
              <TriFilter
                title="Оплачен?"
                :selected_filter="is_paid"
                :set-filter="setPaidFilter"
              />
            </b-col>
            <b-col>
              <Selector
                all_label="Все"
                label="Статус"
                :list="statuses"
                value_label="st_name"
                :selected="status"
                :select-entity="selectStatus"
              />
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col></b-col>
            <b-col>
              <div class="form-group row">
                <label for="start_date" class="dates col-sm-4 col-form-label"
                  >Дата, c:</label
                >
                <div class="col-sm-8">
                  <datepicker
                    v-model="start_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="form-group row">
                <label for="end_date" class="dates col-sm-4 col-form-label"
                  >Дата, по:</label
                >
                <div class="col-sm-8">
                  <datepicker
                    v-model="end_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
      <Paginator
        :page="current_page"
        :pages="page_range"
        :set-page="setPage"
      />
      <OrdersList :orders="orders" :meta="meta" :totals="totals" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

// import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
// import { ISO_Long_To_Short } from "@/services/date_tools";

import OrdersList from "./OrdersList";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import Paginator from "@/components/generic/Paginator";
import SearchBar from "@/components/generic/backoffice/SearchBar";
import Selector from "@/components/generic/backoffice/Selector";
import TriFilter from "@/components/generic/backoffice/TriFilter";

export default {
  name: "Orders",
  components: {
    // Datepicker,
    OrdersList,
    PageTitle,
    Paginator,
    SearchBar,
    Selector,
    TriFilter
  },
  data: () => ({
    keyword: null,
    loading: true,
    ru: ru,
    format: "dd-MM-yyyy",
    start_date: null,
    end_date: null,
    is_paid: 0,
    loading: true
  }),
  computed: {
    ...mapGetters({
      orders: "orders/orders",
      meta: "orders/meta",
      selected_filter: "orders/selected_filter",
      totals: "orders/totals",

      statuses: "orders/statuses",
      status: "orders/status",

      page_range: "orders/page_range",
      current_page: "orders/current_page"
    })
  },
  watch: {},
  created() {
    this.$store.dispatch("orders/getStatuses");
    this.getOrders({ page: 1 });
  },
  methods: {
    setPaidFilter: function(is_paid) {
      this.is_paid = is_paid;
      this.getOrders({ is_paid });
    },
    getOrders: function(payload) {
      this.loading = true;
      const kw = this.keyword ? { order_num: this.keyword } : {};
      let sts = payload.sts === undefined && this.status >= 0 ? { sts: this.status } : {};
      // const start_date = this.start_date
      //   ? {
      //       start_date: ISO_Long_To_Short(
      //         new Date(this.start_date).toISOString()
      //       )
      //     }
      //   : {};
      // const end_date = this.end_date
      //   ? { end_date: ISO_Long_To_Short(new Date(this.end_date).toISOString()) }
      //   : {};
      this.$store.dispatch("orders/getOrders", {
        ...sts,
        ...kw,
        // ...start_date,
        // ...end_date,
        ...payload,
        mode: 'u'
      }).then(() => this.loading = false);
    },
    selectStatus(status_id) {
      this.getOrders({ page: 1, sts: status_id });
    },
    selectClient: function(client_id) {
      this.client_id = client_id;
    },
    // search
    changeSearch(keyword) {
      this.keyword = keyword;
    },
    doSearch() {
      this.getOrders();
    },
    clearSearch: function() {
      this.keyword = null;
      this.getOrders();
    },
    setPage(page) {
      this.$store.commit("orders/setPage", { page });
      this.getOrders({ page });
    }
  }
};
</script>

<style lang="sass">
.dates
  text-align: right
</style>
