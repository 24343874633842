<template>
  <tr>
    <td class="cell-cont" colspan="15">
      <table class="table table-sm table-hover table-bordered table-striped data-grid">
        <tr>
          <td colspan="16"><h5>{{ npp }}. {{ supplier }}</h5></td>
        </tr>
        <GroupRow
          v-for="(group, idx) in Object.keys(positionsByGroups)"
          :key="group"
          :idx="idx"
          :group_name="groups[group]"
          :positions="positionsByGroups[group]"
          :npp="idx + 1"
        />
        <tr class="group_total">
          <td colspan="7"><strong>Всего по поставщику:</strong></td>
          <td><strong>{{ totals.quan }}</strong></td>
          <td></td>
          <td></td>
          <td><strong>{{ totals.weight | toCommas }}</strong></td>
          <td></td>
          <td><strong>{{ totals.ttl_raw | toCommas }}</strong></td>
          <td></td>
          <td><strong>{{ totals.ttl_full | toCommas }}</strong></td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import GroupRow from "./GroupRow";

export default {
  name: "SupplierRow",
  components: {
    GroupRow
  },
  props: ["npp", "supplier", "groups", "totals", "positionsByGroups"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
.table-sm td.cell-cont
  padding: 0
td
  h5
    margin: 0
</style>
