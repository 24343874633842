<template>
  <b-col>
    <WidgetTitle :title="title" />
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="shop_name">Название:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="page_name" size="sm" v-model="page_name" />
        <small class="text-danger" v-if="page_name !== '' && !uniquePage">
          Не уникальное название страницы.
        </small>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="shop_name">URL:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="page_slug" size="sm" v-model="page_slug" />
        <small class="text-danger" v-if="page_slug !== '' && !uniqueSlug">
          Не уникальный URL.
        </small>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="page_text">Текст:</label>
      </b-col>
      <b-col sm="8">
        <ckeditor :editor="editor"
          :config="editorConfig"
          id="page_text"
          size="sm"
          rows="24"
          max-rows="36"
          v-model="page_text">
        </ckeditor>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="is_active">Активна?:</label>
      </b-col>
      <b-col sm="8">
        <b-form-checkbox v-model="is_active" name="is_active" />
      </b-col>
    </b-row>
    <div class="toolbar right t-row border-top pt-2">
      <b-button
        variant="info"
        @click="newPage"
      >
        Новая страница
      </b-button>
      <b-button
        :disabled="isDisabled"
        variant="primary"
        @click="createPage"
      >
        Сохранить
      </b-button>
    </div>
  </b-col>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

export default {
  name: "PageForm",
  components: {
    WidgetTitle
  },
  props: ["active_page", "resetActive", "checkUnique"],
  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "subscript",
        "superscript",
        "|",
        "link",
        "|",
        "undo",
        "redo",
        "outdent",
        "indent",
        "|",
        "numberedList",
        "bulletedList",
        "|",
        "insertTable"
      ]
    },
    id: null,
    page_name: "",
    page_slug: "",
    page_text: "",
    is_active: false,
    uniquePage: false,
    uniqueSlug: false
  }),
  computed: {
    isDisabled() {
      return (
        this.page_name === "" ||
        this.page_slug === "" ||
        !this.uniquePage ||
        !this.uniqueSlug
      );
    },
    title() {
      return !this.id ? "Новая страница" : "Редактирование страницы";
    }
  },
  watch: {
    page_name(newVal) {
      this.uniquePage = this.checkUnique("page_name", this.id, newVal);
    },
    page_slug(newVal) {
      this.uniqueSlug = this.checkUnique("page_slug", this.id, newVal);
    },
    active_page(newVal, oldVal) {
      if (!oldVal || newVal.id !== oldVal.id) {
        this.id = newVal.id;
        this.page_name = newVal.page_name;
        this.page_slug = newVal.page_slug;
        this.page_text = newVal.page_text;
        this.is_active = newVal.is_active;
      }
    }
  },
  created() {},
  methods: {
    newPage() {
      this.resetActive();
      this.resetForm();
    },
    resetForm() {
      this.id = null;
      this.page_name = "";
      this.page_slug = "";
      this.page_text = "";
      this.is_active = false;
    },
    createPage() {
      this.$store
        .dispatch("content/createPage", {
          id: this.id,
          page_name: this.page_name,
          page_slug: this.page_slug,
          page_text: this.page_text,
          is_active: this.is_active
        })
        .then(status => {
          this.resetForm();
          this.resetActive();
        });
    }
  }
};
</script>


<style lang="sass">
  .ck-content
    height: 300px
</style>