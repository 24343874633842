<template>
  <div>
    <select
      id="client_id"
      class="form-control form-control-sm"
      name="client_id"
      @change="updateClient"
    >
      <option :value="0" :selected="selectedClient(null)">
        ---
      </option>
      <option
        v-for="cta in clients"
        :key="cta.id"
        :value="cta.id"
        :selected="selectedClient(cta)"
      >
        {{ cta.email }} ({{ cta.full_name }})
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "ClientSelector.",
  props: ["clients", "selectClient", "selected_client"],
  computed: {},
  methods: {
    selectedClient(client) {
      if (client) {
        if (this.selected_client && this.selected_client === client.id)
          return true;
      }
    },
    updateClient(ev) {
      this.selectClient(Number(ev.target.value));
    }
  }
};
</script>
