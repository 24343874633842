<template>

  <div class="item">
    <router-link :to="item_card">
      <div class="image">
        <img v-if="thumbnail" :src="thumbnail" :alt="prod.prod_name" />
        <div v-else class="placeholder">нет картинки</div>
      </div>
      <div class="title">
        {{ prod.prod_name }}
      </div>
    </router-link>
      <!-- <div class="desc">
        {{ prod.description || ''}}
      </div> -->
    <div>Арт.: {{ prod.mnf_code }}</div>

    <div class="variants" v-if="prod.variants.length>0">
      <div class="label">Размеры:</div>
      <div>
        <select v-model="selected">
          <option
            v-for="v in prod.variants"
            :key="v.var_ext_id"
            :value="v"
            :selected="selected && selected.var_ext_id === v.var_ext_id"
          >
            {{ v.value }}
          </option>
        </select>
      </div>

    </div>
    <div v-else>&nbsp;</div>
    <!-- <div>Вес: {{ prod.weight }}</div> -->
    <!-- <div>На складе: {{ stk_free }}</div> -->
    <div class="price_with_vat">
      <small>Цена с налогом({{ prod.vat_rate }}%): {{ prod.price_with_vat }}</small>
    </div>
    <div
      class="price"
      v-if="
        !prod.discount_price ||
          Number(prod.discount_price) === 0 ||
          prod.discount_price === prod.price" >
      <strong>Цена:</strong> <span class="reg_price">{{ prod.price }}</span>
    </div>
    <div class="price" v-else><strong>Цена:</strong> <span class="alert_price">{{ prod.discount_price }}</span> <span class="old_price">{{ prod.price }}</span></div>
    <!-- <div class="price_with_vat">
      <strong>Цена с НДС: {{ prod.price_with_vat }}</strong>
    </div> -->
    <div class="to_cart space_above" v-if="Number(prod.weight) > 0">
      <b-form-spinbutton :size="btn_size" v-model="quan" min="1" max="100" />
      <b-button :size="btn_size" :disabled="prod.variants.length>0 && selected === null" variant="success" @click="sendToCart()">
        <i class="fas fa-cart-arrow-down"></i>
      </b-button>
    </div>
    <div class="to_cart no_weight space_above" v-else>
      <small>Нельзя заказать - нет веса.</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: ["prod", "is_desktop", "toCart", "openCard"],
  data: () => ({
    quan: 0,
    selected: null
  }),
  computed: {
    item_card() {
      return `/i/${this.prod.id}`;
    },
    btn_size() {
      return this.is_desktop ? 'sm' : '';
    },
    def_selection() {
      if (this.prod.variants && this.prod.variants.length > 0) {
        return this.prod.variants[0];
      }
      return null;
    },
    // variants() {
    //   if (this.prod.variants) {
    //     const sizes = this.prod.variants.map( v => v.value )
    //     return sizes.join(', ');
    //   }
    //   return []
    // },
    stk_free() {
      return this.prod.stk_free > 3 ? "много" : `${this.prod.stk_free} шт.`;
    },
    thumbnail() {
      if (this.prod.images.length === 0) return;
      return this.prod.images[0].smallURL;
    }
  },
  created() {
    this.selected = this.prod.variants ? this.prod.variants[0] : null;
  },
  methods: {
    sendToCart() {
      const q = this.quan > 0 ? this.quan : 1;
      this.toCart({ ...this.prod, quan: q, variant: this.selected });
      this.quan = 0;
      this.selected = null;
    }
  }
};
</script>

<style lang="sass" scoped>
.variants
  display: flex
  .label
    margin-right: 9px

.to_cart
  display: flex
  align-items: center
  justify-content: flex-start
  .b-form-spinbutton
    width: auto

  &.no_weight
    display: block
    text-align: center

.price_with_vat
  font-style: italic
.price
  margin-top: 5px
  padding-top: 5px
  border-top: 1px solid #e2e2e2
  display: flex
  align-items: center
  flex-wrap: wrap

.alert_price, .reg_price
  margin: 0 10px
  font-size: 1.3rem
  font-weight: bold

.reg_price
  color: blue

.alert_price
  color: red

.old_price
  font-weight: 400
  position: relative
  &:before
    content: ""
    position: absolute
    top: 50%
    width: 110%
    height: 1px
    display: block
    background: #f91155
    transform: rotate(-10deg)
</style>
