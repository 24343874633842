import axios from "axios";
import store from "@/store";

const LOGIN_FAILED_MESSAGE = {
  type: "error",
  title: "Ошибка входа",
  text: "Проверьте логин и пароль."
}

export default () => {
  const ajax = axios.create({
    baseURL:
      process.env.VUE_APP_ROOT_API !== undefined
        ? process.env.VUE_APP_ROOT_API
        : "//localhost:8000"
  });

  ajax.defaults.xsrfCookieName = "csrftoken";
  ajax.defaults.xsrfHeaderName = "X-CSRFToken";
  ajax.defaults.withCredentials = true;

  ajax.interceptors.request.use(
    config => {
      let token = localStorage.getItem("token");
      if (token) config.headers["Authorization"] = `Bearer ${token}`;
      store.commit("loader/START_LOADING");

      // config.headers['xsrfCookieName'] = "csrftoken";
      // config.headers['xsrfHeaderName'] = "X-CSRFToken";

      return config;
    },
    error => {
      if (error.response.status === 401) {
        store.commit("app/setMessage", LOGIN_FAILED_MESSAGE);
      }
      store.commit("loader/FINISH_LOADING");
      return Promise.reject(error);
    }
  );

  ajax.interceptors.response.use(
    rsp => {
      if (rsp.data.message) store.commit("app/setMessage", rsp.data.message);
      store.commit("loader/FINISH_LOADING");
      return rsp;
    },
    error => {
      if (error.response.status === 401) {
        store.commit("app/setMessage", LOGIN_FAILED_MESSAGE);
      }
      store.commit("loader/FINISH_LOADING");
      return Promise.reject(error);
    }
  );

  return ajax;
}