<template>
  <b-row>
    <b-col>
      <PageTitle title="Все страницы" />
      <b-row>
        <b-col md="4">
          <PagesList :pages="pages" :setEdit="setEdit" />
        </b-col>
        <PageForm
          :active_page="active_page"
          :resetActive="resetActive"
          :checkUnique="checkUnique"
        />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import PageForm from "./PageForm";
import PagesList from "./PagesList";
import PageTitle from "@/components/generic/backoffice/PageTitle";

export default {
  name: "AdmPages",
  components: {
    PageForm,
    PagesList,
    PageTitle
  },
  data: () => ({
    active_page: {}
  }),
  computed: {
    pages() {
      return this.$store.getters["content/all_pages"];
    }
  },
  watch: {},
  created() {
    this.getPages();
  },
  methods: {
    checkUnique(entity, id, text) {
      const pages = this.pages.filter(page => page[entity] === text);
      let fl = false;
      if (id) {
        if (pages.length === 1 && id === pages[0].id) fl = true;
        if (pages.length === 0) fl = true;
      } else {
        if (pages.length === 0) fl = true;
      }

      return fl;
    },
    resetActive() {
      this.active_page = {};
    },
    setEdit(page) {
      this.active_page = page;
    },
    getPages() {
      this.$store.dispatch("content/getAllPages", {});
    }
  }
};
</script>

<style lang="sass">

</style>
