<template>
  <tr>
    <td>{{ npp }}</td>
    <td>
      <strong>{{ shop.id }}</strong>
    </td>
    <td>
      {{ shop.shop_name }} <br /> <small>{{ shop.shop_desc }}</small>
    </td>
    <td>
      <a :href="shop.shop_link" target="_blank" v-if="shop.shop_link !== ''">{{
        shop.shop_link
      }}</a>
    </td>
    <td>
      <span v-html="$options.filters.toYesNo(shop.is_active)"></span>
    </td>

    <td>
      <b-button
        size="sm"
        class="sm-override"
        variant="info"
        @click="setEdit(shop)"
        ><i class="fas fa-pen"></i
      ></b-button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ShopRow",
  components: {},
  props: ["shop", "npp", "setEdit"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
</style>
