const state = {
  keyword: null,
  searchGlobal: true,
  searchMode: false
};

const getters = {
  keyword: state => state.keyword,
  searchGlobal: state => state.searchGlobal,
  searchMode: state => state.searchMode
};

const actions = {};

const mutations = {
  setKeyword(state, keyword) {
    state.keyword = keyword;
  },
  setSearchGlobal(state, searchGlobal) {
    state.searchGlobal = searchGlobal;
  },
  setSearchMode(state, searchMode) {
    state.searchMode = searchMode;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
