<template>
  <b-row v-if="!loading">
    <b-col>
      <PageTitle title="Все сборки" />
      <b-row>
        <b-col>
          <SearchBar
            :keyword="keyword"
            :search-this="doSearch"
            :clear-search="clearSearch"
            :change-search="changeSearch"
          />
        </b-col>
        <b-col></b-col>
        <b-col>
          <b-row>
            <b-col></b-col>
            <b-col>
              <TriFilter
                title="Закрыта?"
                :selected_filter="is_active"
                :set-filter="setActiveFilter"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Paginator
            :page="current_page"
            :pages="page_range"
            :set-page="setPage"
          />
        </b-col>
      </b-row>
      <AsmList :asm_lists="asm_lists" />
      <b-row>
        <b-col>
          <Paginator
            :page="current_page"
            :pages="page_range"
            :set-page="setPage"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import AsmList from "./AsmList";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import Paginator from "@/components/generic/Paginator";
import SearchBar from "@/components/generic/backoffice/SearchBar";
import TriFilter from "@/components/generic/backoffice/TriFilter";

export default {
  name: "AsmLists",
  components: {
    AsmList,
    PageTitle,
    Paginator,
    SearchBar,
    TriFilter
  },
  data: () => ({
    keyword: null,
    loading: true,
    is_active: 0
  }),
  computed: {
    ...mapGetters({
      asm_lists: "asm_lists/asm_lists",
      selected_filter: "asm_lists/selected_filter",
      page_range: "asm_lists/page_range",
      current_page: "asm_lists/current_page"
    })
  },
  watch: {},
  created() {
    this.getAsmLists({ page: 1 });
  },
  methods: {
    setActiveFilter: function(is_active) {
      this.is_active = is_active;
      this.getAsmLists({ is_active });
    },
    getAsmLists: function(payload) {
      this.loading = true;
      const kw = this.keyword ? { asm_id: this.keyword } : {};
      this.$store.dispatch("asm_lists/getAsmLists", {
        is_active: this.is_active,
        ...kw,
        ...payload,
        mode: "a"
      }).then(() => this.loading = false);
    },
    // search
    changeSearch(keyword) {
      this.keyword = keyword;
    },
    doSearch() {
      this.getAsmLists();
    },
    clearSearch: function() {
      this.keyword = null;
      this.getAsmLists();
    },
    setPage(page) {
      this.$store.commit("asm_lists/setPage", { page });
      this.getAsmLists({ page });
    }
  }
};
</script>

