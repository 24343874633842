<template>
  <tr>
    <td>{{ doc.id }}</td>
    <td>{{ doc.pmt_summ | toCommas }}</td>
    <td>
      <router-link :to="order_link" target="_blank">
        {{ doc.pmt_order }}
      </router-link>
    </td>
    <td>
      {{ doc.pmt_client.full_name }}
      <br>
      <small>{{ doc.pmt_client.email }}</small>
    </td>
    <td>{{ doc.pmt_desc }}</td>
    <td>
      <b-badge :variant="variant">{{ is_checked }}</b-badge>
    </td>
    <td>{{ doc.pmt_chk_date }}</td>
    <td>{{ doc.created_at }}</td>
    <td>{{ author.full_name }}</td>
    <td>
      <div v-if="!doc.is_checked" class="spread">
        <b-button
          size="sm"
          variant="success"
          class="sm-override"
          @click="checkPayment()"
        >
          <i class="fas fa-check-square"></i>
        </b-button>
        <b-button
          size="sm"
          variant="info"
          class="sm-override"
          @click="showModal(true, { ...doc })"
        >
          <i class="fas fa-pen"></i>
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="sm-override"
          @click="delPayment(doc.id)"
        >
          <i class="fas fa-trash"></i>
        </b-button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "DocumentRow",
  props: ["doc", "delPayment", "savePayment", "showModal"],
  computed: {
    order_link() {
      return `/a/order/${this.doc.pmt_order}`;
    },
    author() {
      return this.doc.created_by;
    },
    is_checked() {
      return this.doc.is_checked ? "Да" : "Нет";
    },
    variant() {
      return this.doc.is_checked ? "success" : "warning";
    }
  },
  methods: {
    checkPayment: function() {
      this.savePayment({ id: this.doc.id, is_checked: true });
    }
  }
};
</script>

<style lang="sass">
.main_col
  padding: 5px

  border: 1px solid darken(#e2e2e2, 10%)
  border-radius: 3px
  margin-right: 10px
</style>
