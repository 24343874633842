<template>
  <tr>
    <td width="40">{{ idx + 1 }}</td>
    <td width="50">
      <router-link
        target="_blank"
        :to="`/a/order/${order.order_id}`"
      >
        {{ order.order_id }}
      </router-link>
    </td>
    <td width="180">{{ order.email }}</td>
    <td width="75" class="double_height">&nbsp;<br>&nbsp;</td>
    <td width="75"></td>
    <td width="75"></td>
    <td width="75"></td>
    <td width="75"></td>
    <td width="75"></td>
    <td></td>
  </tr>
</template>

<script>

export default {
  name: "OrdersShort",
  props: ["idx", "order"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
.table-sm td.cell-cont
  padding: 0
</style>
