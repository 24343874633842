import router from "@/router";
import Api from "@/services/general";

// initial state
const state = {
  orders: [],
  order: null,
  meta: {},
  selected_filter: 0,
  totals: {},

  status: 0,
  statuses: [],

  page_range: [1],
  current_page: null
};

const getters = {
  order: state => state.order,
  orders: state => state.orders,
  current_page: state => state.current_page,
  page_range: state => state.page_range,
  meta: state => state.meta,
  selected_filter: state => state.selected_filter,
  totals: state => state.totals,
  delivery_totals: state => state.orders.reduce((acc, o) => {
    acc = acc + Number(o.raw_sum_fct) + Number(o.dlv_price);
    return acc;
  }, 0),
  status: state => Number(state.status),
  statuses: state => state.statuses
};

const actions = {
  async saveNote(ctx, { order_id, note }) {
    const res = await Api.setData("a/order/save_note", order_id, { note });
    if (res.data.message.type === 'info') this.commit("orders/setNote", note);
  },
  async unpackOrder(ctx, { order_id }) {
    const res = await Api.setData("a/order/unpack", order_id);
    this.commit("orders/setData", { key: "order", value: res.data });
  },
  async getStatuses() {
    const res = await Api.getData("orders/statuses");
    this.commit("orders/setData", { key: "statuses", value: res.data });
  },
  async fillFact(ctx, payload) {
    const { order_id } = payload;
    const res = await Api.setData("a/order/fill_fact", order_id);
    if (res.data.message.type === "info") {
      this.commit("orders/setData", { key: "order", value: res.data });
    }
  },
  async doAction(ctx, payload) {
    const { order_id } = payload;
    delete payload.order_id;
    const res = await Api.setData("order/action", order_id, payload);
    if (res.data.message.type === "info") {
      return res.data;
    }
    if (res.data.message.type === "warning") {
      router.push({
        name: payload.mode === "u" ? "orders" : "adm_orders"
      });
    }
  },
  async updateDelivery(ctx, payload) {
    const { order_id } = payload;
    delete payload.order_id;
    const res = await Api.setData("a/order/update_delivery", order_id, payload);
    if (res.data.message.type === "info") {
      this.commit("orders/setData", { key: "order", value: res.data });
    }
  },
  async removeAsm(ctx, payload) {
    const res = await Api.setData("a/order/dis", payload.order_id);
    if (res.data.message.type === "success") {
      this.commit("orders/setData", { key: "order", value: res.data });
    }
  },
  async createAsmList(ctx, payload) {
    const res = await Api.setData("orders/creat_asm_list", null, payload);
    this.commit("orders/updateAsmInfo", {
      ...payload,
      asm_id: res.data.asm_id
    });
  },
  async acceptOrders(ctx, payload) {
    const res = await Api.setData("orders/accept_orders", null, payload);
    return res.data.message.type;
  },
  async getOrders(ctx, payload) {
    const res = await Api.getData("orders", null, payload);
    this.commit("orders/setData", { key: "orders", value: res.data.list });
    this.commit("orders/setData", { key: "totals", value: res.data.totals });
    this.commit("orders/setData", { key: "status", value: payload.sts });
    this.commit("orders/setPaginator", {
      ...res.data.paginator
    });
  },
  async getOrder(ctx, payload) {
    const { id, mode } = payload;
    const res = await Api.getData(`${mode}/order`, id);
    if (res.data.is_deleted) {
      router.push({
        name: payload.mode === "u" ? "orders" : "adm_orders"
      });
    } else {
      this.commit("orders/setData", { key: "order", value: res.data });
    }
  },
  async addPosition(ctx, payload) {
    const res = await Api.setData("order/position", null, payload);
    if (res.data.message.type === "success") {
      this.commit("orders/updateOrder", {
        ...res.data.order
      });
    }
    return res.data.message.type;
  },
  async updateProd(ctx, payload) {
    const res = await Api.setData("order/position", payload.id, payload);
    if (res.data.message.type === "info") {
      this.commit("orders/updateOrder", {
        ...res.data.order
      });
    }
    return res.data.message.type;
  },
  async delPosition(ctx, payload) {
    const res = await Api.setData("order/position", null, payload);
    if (res.data.message.type === "success") {
      this.commit("orders/updateOrder", {
        ...res.data.order
      });
    }
    // this.commit("orders/setData", { key: "order", value: res.data });
    // this.commit("orders/updateOrders", res.data);
  },
  async setNextStatus(ctx, payload) {
    const res = await Api.setData("order/status", null, payload);
    this.commit("orders/setData", { key: "order", value: res.data });
    this.commit("orders/updateOrders", res.data);
  },
  async exportErply(ctx, payload) {
    const res = await Api.setData("order/export_erply", null, payload);
    this.commit("orders/setData", { key: "order", value: res.data });
    this.commit("orders/updateOrders", res.data);
  }
};

const mutations = {
  setPaginator(state, payload) {
    state.page_range = payload.page_range;
    state.current_page = payload.current_page;
  },
  setPage(state, payload) {
    state.current_page = payload.page;
  },
  setNote(state, note) {
    state.order.note = note;
  },
  setStatus(state, payload) {
    state.status = payload.status;
  },
  // delPosition(state, payload) {
  //   const k = payload.origin === "product" ? "products" : "products_links";
  //   const pos = state.order[k].filter(p => p.id !== payload.id);
  //   state.order[k] = [...pos];
  // },
  setData(state, payload) {
    state[payload.key] = payload.value;
  },
  updateOrder(state, payload) {
    state.order = payload;
  },
  updateOrders(state, payload) {
    const orders = state.orders.filter( ord => ord.id !== payload.id);
    state.orders = [payload, ...orders];
  },
  updateAsmInfo(state, payload) {
    const orders = state.orders.map( ord => {
      if (payload.selected_orders.includes(ord.id))
        return { ...ord, asm_list_id: payload.asm_id };
      return ord;
    });
    state.orders = [...orders];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
