import router from "@/router";
import Api from "@/services/general";

// initial state
const state = {
  asm_lists: [],
  asm_list: null,
  selected_filter: 0,

  page_range: [1],
  current_page: null
};

const getters = {
  asm_list: state => state.asm_list,
  asm_lists: state => state.asm_lists,
  selected_filter: state => state.selected_filter,
  current_page: state => state.current_page,
  page_range: state => state.page_range,
};

const actions = {
  async removeAsm(ctx, payload) {
    const res = await Api.setData("asm_list/dis", payload.asm_id);
    if (res.data.message.type === "success") {
      this.commit("asm_lists/setData", { key: "asm_list", value: null });
      router.push({ path: "/a/asm_lists" });
    }
  },
  async getAsmLists(ctx, payload) {
    const res = await Api.getData("asm_lists", null, payload);
    this.commit("asm_lists/setData", {
      key: "asm_lists",
      value: res.data.list
    });
    this.commit("asm_lists/setPaginator", {
      ...res.data.paginator
    });
  },
  async getAsmList(ctx, id) {
    const res = await Api.getData("asm_list", id);
    this.commit("asm_lists/setData", { key: "asm_list", value: res.data });
  }
};

const mutations = {
  setPaginator(state, payload) {
    state.page_range = payload.page_range;
    state.current_page = payload.current_page;
  },
  setPage(state, payload) {
    state.current_page = payload.page;
  },
  setData(state, payload) {
    state[payload.key] = payload.value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
