<template>
  <div class="page_title space_below border_below">
    <b-row>
      <b-col>
        <h3>
          {{ title }} <span v-if="addon">/ <small>{{ addon }}</small></span>
          <small v-if="back && !back_url">
            <button class="btn btn-link btn-sm" @click="$router.go(-1)"><i class="fas fa-arrow-left"></i> назад</button>
          </small>
          <small  v-if="back && back_url">
            <a class="btn btn-link btn-sm" :href="back_url"><i class="fas fa-arrow-left"></i> назад</a>
          </small>
        </h3>
      </b-col>
      <b-col>
        <div v-if="actions" class="toolbar right t-row border_bottom">
          <div class="t-cell" v-if="title_message && title_id">
            {{ title_message }} - <router-link :to="document_link">{{ title_id }}</router-link>
          </div>
          <div class="t-cell">
            <b-button
              v-for="(action) in actions"
              :key="action.id"
              :action="action"
              size="sm"
              :variant="action.button_color" @click="confirm_action(action)">
              {{ action.button_title }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  data() {
    return {
      confirmation: ""
    }
  },
  props: ["actions", "addon", "do_action", "title", "title_message", "title_id", "back", "back_url", "mode"],
  computed: {
    document_link() {
      return `/${this.mode}/order/${this.title_id}`;
    }
  },
  methods: {
    showConfirmation(action) {
      this.boxTwo = ''
      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить заказ?', {
        title: 'Подтверждение',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, хочу',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        noFade: true,
        centered: true
      }).then(value => {
        if (value) {
          this.do_action(action.id, action.mode);
        }
      })
    },
    confirm_action(action) {
      if (action.confirmation) {
        this.showConfirmation(action);
      } else {
        this.do_action(action.id, action.mode);
      }
    }
  }
};
</script>
