<template>
  <table class="table table-sm table-hover table-bordered table-striped data-grid">
    <thead class="thead-dark">
      <tr>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th>Название</th>
        <th width="100">URL</th>
        <th width="30">Акт?</th>

        <th width="36"></th>
      </tr>
    </thead>
    <tbody>
      <PageRow
        v-for="(page, idx) in pages"
        :key="page.id"
        :idx="idx"
        :page="page"
        :npp="pages.length - idx"
        :setEdit="setEdit"
        />
    </tbody>
  </table>
</template>

<script>
import PageRow from "./PageRow";

export default {
  name: "PagesList",
  components: {
    PageRow
  },
  props: ["pages", "setEdit"],
  computed: {},
  methods: {}
};
</script>
