<template>
  <div>
    <b-row>
      <b-col md="9">
        <ProductsLinks />
        <b-row v-if="grand_total_with_vat">
          <b-col>
            <div class="text-center border_below">
              <h4>Итог: {{ grand_total }}</h4>
              <!-- <h4>С НДС: {{ grand_total_with_vat }}</h4> -->
              <h4>С доставкой: {{ grand_total_with_delivery }}</h4>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <Delivery
          :packing_price="packing_price"
          :rate="rate"
          :total_weight="pl_total_weight"
          :total_delivery="total_delivery"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="show_complete">
      <b-col md="6">
        <b-alert variant="success" :show="show_complete">
          <strong>Спасибо за Ваш заказ!</strong><br>
          Детали заказа и регистрационные данные были отправлены на Вашу электронную почту.<br>
          При создании следующего заказа - войдите в систему используя пароль из письма.
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="products_links.length > 0">
      <b-col :md="isLoggedIn ? 3 : 6">
        <div class="mt-3">
          <WidgetTitle title="Детали заказа" />
        </div>
        <Registration
          :isLoggedIn="isLoggedIn"
          :isDisabled="isDisabled"
          :sendOrder="sendOrder" />
      </b-col>
      <b-col md="3" v-if="isLoggedIn">
        <AddressList />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";

import CartRow from "./CartRow";
import Delivery from "./Delivery";
import PageTitle from "@/components/generic/PageTitle";
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

import ProductsLinks from "./ProductsLinks";
import Registration from "./Registration";

import AddressList from "@/components/user/addresses/AddressList";

export default {
  name: "Cart",
  components: {
    AddressList,
    Delivery,
    ProductsLinks,
    WidgetTitle,
    Registration
  },
  data: () => ({
    order_disabled: false,
    show_complete: false
  }),
  computed: {
    ...mapGetters({
      selected_address: "addresses/get_selected",
      products_links: "products_links/get_cart",

      pl_total_weight: "products_links/get_total_weight",
      pl_total_sum: "products_links/get_total_sum",
      pl_total_sum_with_vat: "products_links/get_total_sum_with_vat",

      packing_price: "settings/packing_price",
      rates: "delivery/get_rates",

      isLoggedIn: "user/isLoggedIn"
    }),
    rate() {
      if (this.pl_total_weight <= 0) return {dlv_price: 0};
      let rate = this.rates.find( rate => this.pl_total_weight >= rate.dlv_min && this.pl_total_weight < rate.dlv_max );
      if (rate) return rate;
      let max_price = 0;
      this.rates.map( rt => {
        if (rt.dlv_price > max_price) {
          max_price = rt.dlv_price;
          rate = rt
        };
      });
      return rate;
    },
    total_delivery() {
      return this.rate && this.pl_total_weight > 0
        ? (
            this.rate.dlv_price * Math.ceil(this.pl_total_weight) +
            this.packing_price
          ).toFixed(2)
        : 0;
    },
    grand_total_with_vat() {
      return (this.pl_total_sum_with_vat).toFixed(2);
    },
    grand_total_with_vat_and_delivery() {
      if (this.rate) return (Number(this.total_delivery) + Number(this.grand_total_with_vat)).toFixed(2);
      return this.grand_total_with_vat;
    },
    grand_total_with_delivery() {
      if (this.rate) return (Number(this.total_delivery) + Number(this.grand_total)).toFixed(2);
      return this.grand_total;
    },
    grand_total() {
      return (this.pl_total_sum).toFixed(2);
    },
    isDisabled() {
      const isEmpty = this.products_links.length === 0;

      return isEmpty || (!this.isLoggedIn && this.email === "");
    }
  },
  watch: {
    isDisabled(newVal, oldVal) {
      if (newVal === true) {
        this.show_complete = false;
      }
    }
  },
  created() {
    this.show_complete = false;
  },
  methods: {
    sendOrder: function(isValid, regData) {
      this.order_disabled = true;
      if (this.isLoggedIn) {
        this.$store
          .dispatch("cart/sendLinkOrder", { // DNT!!!
            ...regData,
            address_id: this.selected_address.id,
            dlv_price: this.rate ? this.total_delivery : 0
          })
          .then(({status, order_id}) => {
            if (status === "success") {
              router.push({ path: `/` });
            }
            this.order_disabled = false;
          });
      } else {
        if (isValid) {
          this.$store
            .dispatch("cart/sendLinkOrder", {
              ...regData,
              dlv_price: this.rate ? this.total_delivery : 0
            })
            .then(({status, order_id}) => {
              if (status === "success") {
                this.show_complete = true;
                localStorage.removeItem("cart_link");
                // router.push({ path: `/` });
              };
            this.order_disabled = false;
          });
        } //isValid
      }
    }
  }
};
</script>
