import Api from "./api";

export default {
  obtainToken(url, payload) {
    return Api().post(url, payload);
  },
  refreshToken(url, payload) {
    return Api().post(url, payload);
  }
};
