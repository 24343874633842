import Api from "@/services/general";

// initial state
const state = {
  addresses: [],
  selected: null
};

const getters = {
  get_addresses: state => state.addresses,
  get_selected: state => state.addresses.find( addr => addr.is_default )
};

const actions = {
  async delAddress(ctx, payload) {
    const res = await Api.delData("address", payload);
    if (res.data.message.type === "warning") {
      this.commit("addresses/delPosition", payload);
    }
  },

  async updateAddress(ctx, payload) {
    const { addr_id } = payload;
    delete payload.addr_id;
    const res = await Api.setData("address", addr_id, payload);
    if (res.data.result === "success") {
      this.commit("addresses/setDefault", res.data.address);
    }
  }
};

const mutations = {
  setAddresses(state, payload) {
    state.addresses = payload;
  },
  delAddress(state, payload) {
    state.addresses = state.addresses.filter(
      addr => addr.id !== payload.addr_id
    );
  },
  setDefault(state, payload) {
    const addresses = state.addresses
      .filter(addr => addr.id !== payload.id)
      .map(addr => ({
        ...addr,
        is_default: false
      }));
    state.addresses = [payload, ...addresses];
  },
  setSelected(state, prod_id) {
    state.selected = prod_id;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
