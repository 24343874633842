<template>
  <table class="table table-sm table-hover table-bordered table-striped data-grid space_below">
    <thead class="thead-dark">
      <tr>
        <th width="24">нпп</th>
        <th width="160">Картинка</th>
        <th width="160">Магазин</th>
        <th width="250">Название</th>
        <th>Комментарий</th>
        <th width="70">Вес, шт.</th>
        <th width="90">Кол-во</th>
        <th width="70">Вес</th>
        <th width="70">Цена</th>
        <!-- <th width="70">Всего</th> -->
        <th width="70">Налог</th>
        <!-- <th width="70">Сумма налога</th> -->
        <th width="70">Цена с НДС</th>
        <th width="70">Всего с НДС</th>
        <th width="36"></th>
      </tr>
    </thead>
    <tbody>
      <ProductLinkRow
        v-for="(prod, idx) in products"
        :prod="prod"
        :idx="idx"
        :key="prod.id"
        :npp="products.length - idx"
        :delProduct="delProduct"
        :changeQuan="changeQuan"
      />
    </tbody>
    <thead class="thead-light">
      <tr>
        <th colspan="6"><strong>Всего</strong></th>
        <th width="90">{{ total_quan }}</th>
        <th width="70">{{ total_weight.toFixed(2) }}</th>
        <th width="70"></th>
        <!-- <th width="70">{{ total_sum.toFixed(2) }}</th> -->
        <th width="70"></th>
        <!-- <th width="70"></th> -->
        <th width="70"></th>
        <th width="70">{{ total_sum_with_vat.toFixed(2) }}</th>
        <th></th>
      </tr>
    </thead>
  </table>
</template>

<script>
import ProductLinkRow from "./ProductLinkRow";

export default {
  name: "ProductsLinksList",
  components: {
    ProductLinkRow,
  },
  props: ["products", "total_quan", "total_weight", "total_sum_with_vat", "changeQuan", "delProduct"],
  data: () => ({ }),
  computed: {},
  methods: {}
}
</script>
