<template>
  <b-row v-if="!loading">
    <b-col>
      <PageTitle title="Статы по поставщикам" />
      <b-row>
        <b-col md="5">
        </b-col>
        <b-col>
          <b-row>
            <b-col md="2"></b-col>
            <b-col md="4">
              <div class="form-group row">
                <label for="start_date" class="dates col-sm-4 col-form-label">Дата, c:</label>
                <div class="col-sm-8">
                  <datepicker
                    v-model="start_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div class="form-group row">
                <label for="end_date" class="dates col-sm-4 col-form-label">Дата, по:</label>
                <div class="col-sm-8">
                  <datepicker
                    v-model="end_date"
                    :bootstrap-styling="true"
                    input-class="form-control-sm"
                    :monday-first="true"
                    :clear-button="true"
                    :language="ru"
                    :format="format"
                  />
                </div>
              </div>
            </b-col>
            <b-col md="2" class="text-right">
              <button class="btn btn-sm btn-primary" @click="getStats">Отобрать</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <Suppliers :suppliers="suppliers" :totals="gttl" />
    </b-col>
  </b-row>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import { ISO_Long_To_Short } from "@/services/date_tools";

import Suppliers from "./Suppliers";
import PageTitle from "@/components/generic/backoffice/PageTitle";
export default {
  name: "AdmSupStats",
  components: {
    Datepicker,
    Suppliers,
    PageTitle
  },
  data: () => ({
    loading: true,
    ru: ru,
    format: "dd-MM-yyyy",
    start_date: null,
    end_date: null
  }),
  computed: {
    suppliers() {
      return this.$store.getters["suppliers_stats/suppliers"];
    },
    gttl() {
      return this.$store.getters["suppliers_stats/gttl"];
    }
  },
  created() {
    this.loading = true;
    this.$store.dispatch("suppliers_stats/getStats").then(() => {
      this.loading = false;
    });
  },
  methods: {
    getStats: function() {
      const start_date = this.start_date ? { start_date: ISO_Long_To_Short(new Date(this.start_date).toISOString()) } : {};
      const end_date = this.end_date ? { end_date: ISO_Long_To_Short(new Date(this.end_date).toISOString()) } : {};
      this.loading = true;
      this.$store.dispatch("suppliers_stats/getStats", {
        ...start_date,
        ...end_date
      }).then(() => this.loading = false);
    }
  }
};
</script>

<style lang="sass">
.dates
  text-align: right
.input-group-text
  padding: 0.18rem 0.75rem !important
</style>