import Api from "@/services/general";

// initial state
const state = {
  suppliers: [],
  gttl: {}
};

const getters = {
  suppliers: state => state.suppliers,
  gttl: state => state.gttl
};

const actions = {
  async getStats(ctx, payload) {
    const res = await Api.getData("a/suppliers_stats", null, payload);
    this.commit("suppliers_stats/setSuppliers", res.data.suppliers);
    this.commit("suppliers_stats/setGttl", res.data.gttl);
  }
};

const mutations = {
  setSuppliers(state, payload) {
    state.suppliers = payload;
  },
  setGttl(state, payload) {
    state.gttl = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
