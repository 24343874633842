<template>
  <tr>
    <td>{{ npp }}</td>
    <td>
      <strong>
        <router-link :to="document_link">{{ doc.id }}</router-link>
      </strong>
    </td>
    <td>{{ doc.executor }}</td>
    <td>{{ doc.note }}</td>
    <td>{{ doc.orders_count }}</td>
    <td>{{ orders }}</td>
    <td><span v-html="$options.filters.toYesNo(doc.is_active)"></span></td>
    <td>
      {{ doc.created_at }}
    </td>
    <td>
      {{ doc.created_by.profile.full_name }}
    </td>
    <td>
      <router-link class="btn btn-sm sm-override btn-info" :to="document_link">
        <i class="far fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: "AsmListRow",
  components: {},
  props: ["doc", "npp"],
  computed: {
    document_link() {
      return `/a/asm_list/${this.doc.id}`;
    },
    orders() {
      if (this.doc && this.doc.orders) return this.doc.orders.map(o => o.order_id).join(", ");
      return "";
    }
  },
  methods: {}
};
</script>
