<template>
  <tr>
    <td class="cell-cont" colspan="15">
      <table class="table table-sm table-hover table-bordered table-striped data-grid">
        <tr>
          <td colspan="15"><div class="sub_header"><span class="accent">Группа:</span> {{ group_name }}</div></td>
        </tr>
        <ProductRow
          key_name="product"
          v-for="(prod, idx) in positions"
          :key="prod.id"
          :idx="idx"
          :prod="prod"
          :npp="idx + 1"
        />
      </table>
    </td>
  </tr>
</template>

<script>
import ProductRow from "./ProductRow";

export default {
  name: "GroupRow",
  components: {
    ProductRow
  },
  props: ["npp", "group_name", "positions"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
.table-sm td.cell-cont
  padding: 0
td
  h5
    margin: 0
</style>
