<template>
  <div class="table-row" :class="{'group': !group.is_sub}">
    <div class="table-cell">{{ npp }}</div>
    <div class="table-cell">{{ group.id }}</div>
    <div class="table-cell">
      <strong v-if="!group.is_sub">{{ group.group_name }}</strong>
      <span v-else>{{ group.group_name }}</span>
      <div class="desc_box">
        <div>Описание:</div>
        <div v-if="!edit">{{ group.group_desc }}</div>
        <div v-else>
          <b-textarea
            size="sm"
            cols="60"
            rows="3"
            max-rows="3"
            v-model="group_desc"></b-textarea>
        </div>
        <div>
          <b-button v-if="!edit" size="sm" variant="light" @click="setEditMode">
            <i class="fas fa-pen"></i>
          </b-button>
          <div v-else class="buttons">
            <b-button size="sm" variant="primary" @click="saveGroupEdit">
              <i class="fas fa-save"></i>
            </b-button>
            <b-button size="sm" variant="warning" @click="cancelEditMode">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-cell">
      <span v-html="$options.filters.toYesNo(group.is_active)"></span>
    </div>
    <div class="table-cell">
      <span v-html="$options.filters.toYesNo(!group.is_hidden)"></span>
    </div>
    <div class="table-cell">
      <button
        class="btn btn-sm sm-override" :class="button_style"
        @click="setActive(group.id, !group.is_hidden)"
      >
        {{ button_title }}
      </button>
    </div>
    <div class="table-cell">
      <button
        class="btn btn-sm sm-override" :class="top_button_style"
        @click="setTop(group.id, !group.is_top)"
      >
        {{ top_button_title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdmGroupRow",
  props: ["edit", "group", "npp", "setActive", "setTop", "setEdit", "cancelEdit", "saveEdit"], //, "meta"
  data: () => ({
    group_desc: ""
  }),
  computed: {
    top_button_title() {
      return this.group.is_top ? "Выкл" : "Вкл";
    },
    top_button_style() {
      return this.group.is_top ? "btn-danger" : "btn-success";
    },
    button_title() {
      return this.group.is_hidden ? "Вкл" : "Выкл";
    },
    button_style() {
      return this.group.is_hidden ? "btn-success" : "btn-danger";
    }
  },
  methods: {
    saveGroupEdit() {
      this.saveEdit({id: this.group.id, group_desc: this.group_desc});
      this.group_desc = "";
    },
    setEditMode() {
      this.group_desc = this.group.group_desc;
      this.setEdit(this.group.id);
    },
    cancelEditMode() {
      this.group_desc = "";
      this.cancelEdit();
    }
  }
};
</script>

<style lang="sass">
  .desc_box
    display: flex
    justify-content: space-between
    div:nth-child(2)
      padding: 0 8px
      width: 100%
    .buttons
      display: flex
      flex-direction: column
      button
        margin-bottom: 8px
</style>
