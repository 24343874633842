<template>
  <b-row>
    <b-col>
      <PageTitle title="Все группы" />
      <b-row>
        <b-col></b-col>
        <b-col></b-col>
        <b-col>
          <b-row>
            <b-col>
              <TriFilter
                title="Активна?"
                :selected_filter="is_hidden"
                :set-filter="setHiddenFilter"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <AdmGroupsList
            :groups="groups"
            :edit_id="edit_id"
            :setActive="setActive"
            :setTop="setTop"
            :setEdit="setEdit"
            :saveEdit="saveEdit"
            :cancelEdit="cancelEdit"
          />
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import AdmGroupsList from "./AdmGroupsList";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import TriFilter from "@/components/generic/backoffice/TriFilter";

export default {
  name: "AdmGroups",
  components: {
    // Datepicker,
    AdmGroupsList,
    PageTitle,
    TriFilter
  },
  data: () => ({
    is_hidden: 0,
    edit_id: null
  }),
  computed: {
    ...mapGetters({
      groups: "groups/get_all_groups"
    })
  },
  watch: {},
  created() {
    this.getAllGroups();
  },
  methods: {
    saveEdit(payload) {
      this.$store.dispatch("groups/saveGroup", payload);
      this.edit_id = null;
    },
    setEdit(id) {
      this.edit_id = id;
    },
    cancelEdit() {
      this.edit_id = null;
    },
    setActive: function(id, is_hidden) {
      this.$store.dispatch("groups/saveGroup", {
        id,
        is_hidden
      });
    },
    setTop: function(id, is_top) {
      this.$store.dispatch("groups/saveGroup", {
        id,
        is_top
      });
    },
    setHiddenFilter: function(is_hidden) {
      this.is_hidden = is_hidden;
      this.getAllGroups({ is_hidden: is_hidden === 0 ? 0 : !is_hidden });
    },
    getAllGroups: function(payload) {
      this.$store.dispatch("groups/getAllGroups", {
        ...payload,
        mode: 'a'
      });
    }
  }
};
</script>

<style lang="sass">
.dates
  text-align: right
</style>
