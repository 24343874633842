import Api from "@/services/general";

const state = {
  settings: []
};

const getters = {
  settings: state => state.settings,
  packing_price: state => {
    const st = state.settings.find(s => s.set_key === "packing_price");
    if (st) return Number(st.set_val);
    return 0;
  },
  payment_lock: state => {
    const st = state.settings.find(s => s.set_key === "payment_lock");
    if (st) return !!Number(st.set_val);
    return false;
  }
};

const actions = {
  async getSettings() {
    const res = await Api.getData("app_settings");
    this.commit("settings/setSettings", res.data);
  },
};

const mutations = {
  setSettings(state, payload) {
    state.settings = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
