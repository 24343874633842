<template>
  <ul class="list-unstyled">
    <GroupItem
      v-for="grp in groups"
      :grp="grp"
      :key="grp.id"
      :selected_group="selected_group"
      :setSelected="setSelected"
    />
  </ul>
</template>

<script>
import GroupItem from "./GroupItem";

export default {
  name: "GroupsList",
  components: {
    GroupItem
  },
  props: ["groups", "selected_group", "setSelected"]
};
</script>
