<template>
  <b-row v-if="!loading">
    <b-col id="printMe">
      <PageTitle :title="title" back="true" />
      <b-row>
        <b-col md="3">
          <div class="doc_header">
            <ul>
              <li>
                <div class="header_label">Исполнитель:</div>{{ asm_list.executor }}
              </li>
              <li>
                <div class="header_label">Автор:</div>{{ asm_list.created_by.profile.full_name }}
              </li>
              <li>
                <div class="header_label">Создана:</div>{{ asm_list.created_at }}
              </li>
              <li>
                <div class="header_label">Заказов:</div>{{ asm_list.orders.length }}
              </li>
              <li>
                <div class="header_label">Поставщиков:</div>{{ Object.keys(asm_list.suppliers).length }}
              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="4">
          <NoteCard title="Комментарий:" :note="asm_list.note" />
        </b-col>
        <b-col>
          <div class="toolbar right t-row">
            <div class="t-cell">
              <b-button variant="warning" @click="removeAsm">
                <i class="fas fa-times"></i> Расформировать
              </b-button>
            </div>
            <div class="t-cell">
              <b-button variant="info" @click="printDoc">
                <i class="fas fa-print"></i> Печать
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5>Заказы</h5>
          <table class="table table-sm table-hover table-bordered table-striped mb-2">
            <OrdersShort v-for="(o, idx) in asm_list.orders"
                :key="o.order_id"
                :idx="idx"
                :order="o" />
          </table>
        </b-col>
      </b-row>
      <div class="print-devider"></div>
      <b-row>
        <b-col>
          <h5>Поставщики</h5>
          <table class="table table-sm table-hover table-bordered table-striped data-grid mb-2">
            <thead class="thead-dark">
              <tr>
                <td>нпп</td>
                <td>Название</td>
                <td>Кол-во</td>
                <td>Вес</td>
                <td>Всего</td>
                <td>В.+НДС</td>
              </tr>
            </thead>
            <SupplierRowShort
              v-for="(key, idx) in Object.keys(asm_list.suppliers)"
              :key="key"
              :idx="idx"
              :supplier="asm_list.suppliers[key]"
              :npp="idx + 1"
              :totals="asm_list.prods['totals'][key]"
            />
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="table table-sm data-grid">
            <thead class="thead-dark">
              <tr>
                <th width="40">нпп</th>
                <th width="50">ID #</th>
                <th width="80">Заказы</th>
                <th width="120">Картинка</th>
                <th>Название</th>
                <th width="160">Артикул</th>
                <th width="70">Вес, шт.</th>
                <th width="70">Кол-во</th>
                <th width="70">Склад</th>
                <th width="70">Факт</th>
                <th width="70">Вес</th>
                <th width="70">Цена</th>
                <th width="80">Всего</th>
                <th width="100">Цена c НДС</th>
                <th width="100">Всего с НДС</th>
              </tr>
            </thead>
            <tbody>
              <tr class="grand_total">
                <td colspan="7"><strong>Всего по документу:</strong></td>
                <td><strong>{{ asm_list.gttl.quan }}</strong></td>
                <td></td>
                <td></td>
                <td><strong>{{ asm_list.gttl.weight | toCommas }}</strong></td>
                <td></td>
                <td><strong>{{ asm_list.gttl.raw | toCommas }}</strong></td>
                <td></td>
                <td><strong>{{ asm_list.gttl.full | toCommas }}</strong></td>
              </tr>
              <SupplierRow
                v-for="(key, idx) in Object.keys(asm_list.suppliers)"
                :key="key"
                :idx="idx"
                :groups="asm_list.groups"
                :supplier="asm_list.suppliers[key]"
                :npp="idx + 1"
                :positionsByGroups="asm_list.prods[key]"
                :totals="asm_list.prods['totals'][key]"
              />
              <tr>
                <td colspan="15" class="text-center table-warning" style="padding-top: 16px">
                  <h4>Магазины</h4>
                </td>
              </tr>
              <ShopRow
                v-for="(key, idx) in Object.keys(asm_list.shops)"
                :key="key"
                :idx="idx"
                :shop="asm_list.shops[key]"
                :npp="idx + 1"
                :positions="asm_list.prod_links[key]"
                :totals="asm_list.prod_links['totals'][key]"
              />
            <tr class="grand_total">
              <td colspan="7"><strong>Всего по документу:</strong></td>
              <td><strong>{{ asm_list.gttl.quan }}</strong></td>
              <td></td>
              <td></td>
              <td><strong>{{ asm_list.gttl.weight | toCommas }}</strong></td>
              <td></td>
              <td><strong>{{ asm_list.gttl.raw | toCommas }}</strong></td>
              <td></td>
              <td><strong>{{ asm_list.gttl.full | toCommas }}</strong></td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import NoteCard from "../../NoteCard";
import PageTitle from "@/components/generic/backoffice/PageTitle";
import OrdersShort from "./OrdersShort";
import ShopRow from "./ShopRow";
import SupplierRow from "./SupplierRow";
import SupplierRowShort from "./SupplierRowShort";

export default {
  name: "AsmListView",
  components: {
    NoteCard,
    PageTitle,
    OrdersShort,
    ShopRow,
    SupplierRow,
    SupplierRowShort
  },
  data: () => ({
    loading: false
  }),
  methods: {
    removeAsm() {
      this.$store.dispatch("asm_lists/removeAsm", {
        asm_id: this.$route.params.id
      });
    },
    printDoc() {
      this.$htmlToPaper("printMe");
    }
  },
  computed: {
    ...mapGetters({
      asm_list: "asm_lists/asm_list"
    }),
    title() {
      return `Сборка #${this.$route.params.id}`;
    }
  },
  watch: {},
  created() {
    this.loading = true;
    if (this.$route.params.id) {
      this.$store.dispatch("asm_lists/getAsmList", this.$route.params.id)
        .then(() => this.loading = false);
    }
  }
};
</script>

<style lang="sass">
h5
  margin: 0
.orders
  li
    padding: 4px 0
    border-bottom: 1px solid #e2e2e2
</style>
