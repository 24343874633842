import Api from '@/services/general';

// initial state
const state = {
  rates: []
};

const getters = {
  get_rates: state => state.rates
};

const actions = {
  async delRate(ctx, payload) {
    const res = await Api.delData("a/delivery/rate", payload);
    if (res.data.message.type === "warning") {
      this.commit("delivery/delRate", payload);
    }
  },
  async createRate(ctx, payload) {
    const pl = { ...payload };
    let res = null;
    if (payload.id !== null) {
      delete pl.id;
      res = await Api.setData("a/delivery/rate", payload.id, pl);
      this.commit("delivery/updateRate", res.data.rate);
    } else {
      res = await Api.setData("a/delivery/rate", null, pl);
      this.commit("delivery/addRate", res.data.rate);
    }
  },
  async getRates() {
    const res = await Api.getData("delivery/rates", null);
    this.commit("delivery/setRates", res.data);
  }
};

const mutations = {
  setRates(state, payload) {
    state.rates = payload;
  },
  addRate(state, payload) {
    const rates = sortRates([payload, ...state.rates]);
    state.rates = [...rates];
  },
  delRate(state, payload) {
    const rates = sortRates(state.rates.filter(rate => rate.id !== payload.id));
    state.rates = [...rates];
  },
  updateRate(state, payload) {
    const rates = state.rates.map(rate => (rate.id === payload.id ? payload : rate));
    state.rates = sortRates([...rates]);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

function sortRates(rates) {
  return rates.sort((a, b) => a.dlv_min > b.dlv_min);
}