<template>
  <div>
    <b-row>
      <b-col md="8" offset="2">
        <div class="text-center">
          <h4>Внимание!</h4>
          Учётная запись для входа в личный кабинет и управления заказами будет создана в момент отправки первого заказа.<br />
          <strong>Логин и пароль придут на электронную почту указанную при отправке заказа.</strong>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4" offset="4">
        <div :class="$style.login_form">
          <form class="" @submit.prevent="login">
            <div class="form-group row">
              <label for="">Эл. почта:</label>
              <input
                v-model="model.email"
                type="text"
                required
                class="form-control"
                placeholder=""
              />
            </div>

            <div class="form-group row">
              <label for="">Пароль:</label>
              <input
                v-model="model.password"
                type="password"
                required
                class="form-control"
                placeholder="***"
              />
            </div>

            <div class="form-group text-right">
              <button class="btn btn-success btn-primary btn-large">
                Войти
              </button>
            </div>
            <div>
              {{ loading }}
              {{ status }}
            </div>
          </form>
        </div>
      </b-col>
    </b-row>
</div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      model: {
        email: "",
        password: ""
      },
      loading: "",
      status: ""
    };
  },
  methods: {
    login() {
      const formData = new FormData();
      formData.append("email", this.model.email.toLowerCase());
      formData.append("password", this.model.password);
      this.$store.dispatch("user/obtainToken", formData);
    }
  }
};
</script>

<style module lang="sass">
.login_form
  margin-top: 100px
  display: flex
  justify-content: center
  align-items: center

</style>
