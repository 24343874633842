<template>
  <div>
    <h4>{{ selected_group && selected_group.group_name }}</h4>
    <p>{{ selected_group && selected_group.group_desc }}</p>
  </div>
</template>

<script>
  export default {
    name: 'GroupsTitle',
    props: ['selected_group'],
  };

</script>


