import router from "@/router";
import Api from "@/services/user";
import ApiG from "@/services/general";

// initial state
const state = {
  profile: null,
  role: undefined,
  user_id: undefined,
  username: undefined,
  token: localStorage.getItem("token") || undefined,
  endpoints: {
    obtainJWT: "api/token/",
    refreshJWT: "api/token/refresh/"
  }
};

const build_url = path => `${process.env.VUE_APP_ROOT_API}/${path}`;

// getters
const getters = {
  user_id: state => state.user_id,
  username: state => state.username,
  isLoggedIn: state => state.token !== undefined,
  role: state => state.role,
  // isAbleToMove: state => ["admin", "operator"].includes(state.role),
  // isAbleToPlaceOrder: state => ["user"].includes(state.role)
};

// actions
const actions = {
  logout() {
    this.commit("user/clearState");
    this.commit("user/removeToken");
    this.dispatch("settings/getSettings");

    router.push({ path: "/" });
  },
  async checkUser(ctx) {
    if ((ctx.state.profile === null || ctx.state.profile?.id) && ctx.state.token) {
      // ctx.dispatch("refreshToken");
      ctx.dispatch("getUserData");
    } else {
      ctx.dispatch("restoreCart");
    }
  },
  async restoreCart() {
    const prod = localStorage.getItem("cart_prod");
    const link = localStorage.getItem("cart_link");

    if(prod) {
      this.commit("cart/setProducts", JSON.parse(prod));
    } else {
      this.commit("cart/setProducts", []);
    }

    if(link) {
      this.commit("products_links/setProducts", JSON.parse(link));
    } else {
      this.commit("products_links/setProducts", []);
    }
  },
  async getUserData() {
    const res = await ApiG.getData("profile");
    this.commit("user/updateProfile", res.data.user_data.profile);
    this.commit("user/updateUser", {role: res.data.user_data.role.role_name.toLowerCase(), name: res.data.user_data.full_name, user_id: res.data.user_data.profile.id});
    if(res.data.user_data.cart_prod) {
      this.commit("cart/setProducts", JSON.parse(res.data.user_data.cart_prod));
    } else {
      this.commit("cart/setProducts", []);
    }

    if(res.data.user_data.cart_link) {
      this.commit("products_links/setProducts", JSON.parse(res.data.user_data.cart_link));
    } else {
      this.commit("products_links/setProducts", []);
    }

    this.commit("addresses/setAddresses", res.data.user_data.addresses);

    this.dispatch("settings/getSettings");
  },
  async obtainToken(ctx, payload) {
    const res = await Api.obtainToken(
      build_url(ctx.state.endpoints.obtainJWT),
      payload
    );

    localStorage.removeItem("cart_prod");
    localStorage.removeItem("cart_link");

    const user = JSON.parse(atob(res.data.access.split(".")[1]));
    this.commit("user/updateToken", res.data.access);
    this.commit("user/updateUser", user);
    ctx.dispatch("getUserData");
    router.push({ path: "/" });
  },
  async refreshToken(ctx) {
    const payload = {
      token: this.state.token
    };

    const res = await Api.refreshToken(
      build_url(ctx.state.endpoints.refreshJWT),
      payload
    );
    this.commit("user/updateToken", res.data.token);
  },
  recreateToken(ctx) {
    let token = ctx.state.token;
    if (!token) {
      token = localStorage.getItem("token");
    }
    if (token) {
      const user = JSON.parse(atob(token.split(".")[1]));
      this.commit("user/updateUser", user);
      return user.role;
    }
  }
};

// mutations
const mutations = {
  clearState(state) {
    state.role = undefined;
    state.user_id = undefined;
    state.username = undefined;
    state.token = undefined;
  },
  updateToken(state, newToken) {
    localStorage.removeItem("vuex");
    localStorage.setItem("token", newToken);
    state.token = newToken;
  },
  updateUser(state, payload) {
    state.role = payload.role;
    state.username = payload.name;
    state.user_id = payload.user_id;
  },
  removeToken(state) {
    localStorage.removeItem("token");
    localStorage.removeItem("vuex");
    state.token = undefined;
  },
  updateProfile(state, payload) {
    state.profile = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
