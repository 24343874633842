<template>
  <div v-if="!loading">
    <b-row>
      <b-col>
        <PageTitle title="Финансы по клиенту" />
      </b-col>
    </b-row>
    <b-row v-if="balance">
      <b-col>
        <ul class="list-unstyled">
          <li>Имя: {{ balance.full_name }}</li>
          <li>E-mail: {{ balance.email }}</li>
        </ul>
      </b-col>
      <b-col>
        <div class="balance text-center">
          <h1>
            <b-badge :variant="variant">{{ balance.balance }}</b-badge>
          </h1>
        </div>
      </b-col>
      <b-col>
        <b-btn
          v-if="balance.balance > 0"
          variant="warning"
          @click="showReturnModal">
          Вернуть средства
        </b-btn>
      </b-col>
    </b-row>
    <b-row v-if="balance">
      <b-col>
        <div class="d-flex justify-content-between align-items-center border px-2">
          <h4>Приходы</h4>
          <strong><span class="text-success ">{{ balance.pmt_summ }}</span></strong>
        </div>
        <table class="table table-sm table-hover table-bordered table-striped data-grid my-2">
          <thead class="thead-dark">
            <tr>
              <th width="120">Дата</th>
              <th>Сум., EUR</th>
              <th>Пров.</th>
              <th width="120">ДатаП</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pmt in balance.payments"
              :key="pmt.id"
            >
              <td>{{ pmt.created_at }}</td>
              <td>{{ pmt.pmt_summ }}</td>
              <td>{{ pmt.is_checked ? "Да" : "Нет" }}</td>
              <td>{{ pmt.pmt_chk_date }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-between align-items-center border px-2">
          <h4>Расходы</h4>
          <strong><span class="text-danger">{{ balance.exp_summ }}</span></strong>
        </div>
        <table class="table table-sm table-hover table-bordered table-striped data-grid my-2">
          <thead class="thead-dark">
            <tr>
              <th width="220">Заказ</th>
              <th>Сумма</th>
              <th width="120">Дата</th>
              <th>Автор</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="exp in balance.expences"
              :key="exp.id"
            >
              <td>
                <router-link
                  :to="`/a/order/${exp.order.id}`"
                >
                  {{ exp.order.id }}
                </router-link>
                <small>{{ exp.order.ord_date }} | {{ exp.order.status ? exp.order.status.st_name : "-" }}</small>
              </td>
              <td>{{ exp.exp_summ }}</td>
              <td>{{ exp.created_at }}</td>
              <td>{{ exp.created_by }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-between align-items-center border px-2">
          <h4>Ожидают оплаты</h4>
          <strong><span class="text-info">{{ total_to_pay }}</span></strong>
        </div>
        <table class="table table-sm table-hover table-bordered table-striped data-grid my-2">
          <thead class="thead-dark">
            <tr>
              <th width="220">Заказ</th>
              <th width="120">Дата</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ord in balance.orders" :key="ord.id">
              <td>
                <router-link
                  :to="`/a/order/${ord.id}`"
                >
                  {{ ord.id }}
                </router-link>
                <small>| {{ ord.status ? ord.status.st_name : "-" }}</small>
              </td>
              <td>{{ ord.created_at }}</td>
              <td>{{ ord.full_sum_fct }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <b-modal id="returnModal" ref="returnModal" centered title="Возврат средств">
      <div class="my-2">
        <div class="form-group row">
          <label for="pmt_summ" class="col-sm-3 col-form-label">
            Сумма, EUR<span class="text-danger">*</span>:
          </label>
          <div class="col-sm-4">
            <input
              id="pmt_summ"
              v-model="pmt_summ"
              class="form-control form-control-sm"
            />
          </div>
          <div class="col-sm-1">
            <b-btn
              variant="info"
              size="sm"
              @click="resetReturn">
              <i class="fas fa-retweet"></i>
            </b-btn>
          </div>
        </div>
        <div class="form-group row">
          <label for="pmt_desc" class="col-sm-3 col-form-label">
            Комментарий<span class="text-danger">*</span>:
          </label>
          <div class="col-sm-9">
            <input
              id="pmt_desc"
              v-model="pmt_desc"
              class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
      <div v-if="error" class="text-center text-danger">
        {{ message }}
      </div>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="danger" @click="closeModal">
          Отменить
        </b-button>
        <b-button size="sm" variant="success" @click="saveReturnModal">
          Сохранить
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import PageTitle from "@/components/generic/backoffice/PageTitle";

  export default {
    name: "UserBalance",
    components: {
      PageTitle
    },
  data: () => ({
    pmt_summ: 0,
    pmt_desc: "",
    client_id: null,
    error: false,
    message: "",
    loading: true
  }),
  computed: {
    total_to_pay() {
      let sum = 0;
      if (this.balance && this.balance.orders) {
        for (const o of this.balance.orders) {
          sum = sum + Number(o.full_sum_fct);
        }
      }
      return sum.toFixed(2);
    },
    variant() {
      return this.balance && this.balance.balance > 0 ? "success" : "danger";
    },
    balance() {
      return this.$store.getters["balances/balance"];
    },
  },
  created() {
    if (this.$route.params.user_id) {
      this.$store
        .dispatch("balances/getBalance", {
          user_id: this.$route.params.user_id
        })
      .then(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    pmt_summ(newVal) {
      let pmt_summ = newVal;
      if (
        (typeof newVal === "string" || newVal instanceof String) &&
        newVal.indexOf(",") >= 0
      ) {
        pmt_summ = newVal.replace(",", ".");
      }
      this.pmt_summ = pmt_summ;
    },
    pmt_desc(newVal) {
      this.pmt_desc = newVal;
    }
  },
  methods: {
    resetReturn() {
      this.pmt_summ = this.balance.balance;
    },
    resetFormData() {
      this.pmt_summ = 0;
      this.pmt_desc = "";
      this.error = false;
      this.message = "";
    },
    closeModal() {
      this.resetFormData();
      this.$refs.returnModal.hide();
    },
    showReturnModal() {
      this.resetFormData();
      this.pmt_summ = this.balance.balance;
      this.pmt_desc = "Возврат средств";
      this.$refs.returnModal.show();
    },
    saveReturnModal: function() {
      this.error = true;
      if (isNaN(this.pmt_summ) || this.pmt_summ <= 0 || Number(this.pmt_summ) > Number(this.balance.balance)) {
        this.message = "Неправильная сумма.";
      } else if (this.pmt_desc === "") {
        this.message = "Нет комментария";
      } else {

        let payload = {
          pmt_summ: -1 * (Number(this.pmt_summ)),
          pmt_desc: this.pmt_desc,
          client_id: this.balance.id,
          is_checked: true
        };

        this.$store.dispatch("balances/saveReturn", payload);
        this.closeModal();
      }
    }
  }
};
</script>

<style lang="sass">
</style>