<template>
  <tr>
    <td width="40">{{ npp }}</td>
    <td width="50">{{ prod.id }}</td>
    <td width="80">
      <router-link
        v-for="o in prod.orders_ids.split(';')"
        target="_blank"
        :key="o"
        :to="`/a/order/${o}`"
      >
        <span class="badge badge-light mr-1">{{ o }}</span>
      </router-link>
    </td>
    <td width="120">
      <div v-if="prod.thumbnail" class="image">
        <img :src="prod.thumbnail" :alt="prod.prod_name" />
      </div>
      <div v-else class="placeholder">нет картинки</div>
    </td>
    <td>
      {{ prod.prod_name }}
      <a :href="prod.prod_link" target="_blank" v-if="key_name === 'product_link'">
        <i class="fas fa-external-link-alt"></i>
      </a>

    </td>
    <td width="160" v-if="key_name === 'product_link'">
      {{ prod.note }}
    </td>
    <td width="160">{{ prod.mnf_code }}</td>
    <td width="70">{{ prod.weight || "0.00" }}</td>
    <td width="70">{{ prod.quan }}</td>
    <td width="70" class="table-secondary bolder" v-if="key_name === 'product'">В: <strong>{{ prod.stk_total }}</strong> <br> С: {{ prod.stk_free }}</td>
    <td width="70" v-else></td>
    <td width="70"></td>
    <td width="70">{{ prod.ttl_weight | toCommas }}</td>
    <td width="70">{{ prod.price | toCommas}}</td>
    <td width="80">{{ prod.ttl_raw | toCommas}}</td>
    <td width="100">{{ prod.price_with_vat | toCommas}}</td>
    <td width="100">{{ prod.ttl_full | toCommas }}</td>
  </tr>
</template>

<script>
export default {
  name: "ProductRow",
  props: ["npp", "key_name", "prod"],
  computed: {
    orders_ids() {
      let links = []
      const orders = this.prod.orders_ids.split(";");
      orders.map(o => {
        links.push(`<a href="/a/order/${o}">${o}</a>`);
        return o;
      });
      return links.join(", ");
    }
    // row_weight() {
    //   return Number(this.prod.quan) > 0 && Number(this.prod.weight) > 0
    //     ? (Number(this.prod.quan) * Number(this.prod.weight)).toFixed(2)
    //     : "0.0";
    // },
    // total() {
    //   return (this.prod.quan * this.prod.price).toFixed(2);
    // },
    // total_with_vat() {
    //   return (this.prod.quan * this.prod.price_with_vat).toFixed(2);
    // },
    // vat_rate() {
    //   return this.prod.vat_rate ? `${this.prod.vat_rate}%` : "н/д";
    // },
    // vat_sum() {
    //   return (this.prod.price_with_vat - this.prod.price).toFixed(2);
    // }
  },
  methods: {}
};
</script>

<style lang="sass">
.image
  height: 60px
  display: flex
  justify-content: center
  align-items: center
  img
    max-width: 60px
    max-height: 60px

.bolder
  font-size: 1.1em

</style>
